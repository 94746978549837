import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { v4 } from 'uuid';
import { callPostAPI, callPutAPI } from '../../../helpers/apiCall';
import { findActiveOrder, uploadNotesProfile } from './utils';

export const getMemoriesThunk = createAsyncThunk('getMemories/fetch', async (d, { getState }) => {
  let state = getState();
  console.log(state);
  let childMemberId = state.member?.activeChild?.memberId;
  console.log('childMemberId', childMemberId);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let reqObj = {
    memberId: childMemberId,
    token: token,
  };
  let result = await callPostAPI(reqObj, '/get-memories');
  console.log('result', result);
  let list = [];
  for (const memories of result.data) {
    list.push(memories);
  }
  console.log('list', list);
  return { data: list };
});

export const deleteMemoriesThunk = createAsyncThunk(
  'deleteMemories/fetch',
  async (d, { getState }) => {
    let state = getState();
    let childMemberId = state.member?.activeChild?.memberId;
    let rosterId = state.member?.activeChild?.rosterId;
    let order = findActiveOrder(state.member);
    let reqObj = {
      orderId: order.id,
      productId: order.productId,
      rosterId: rosterId,
      memberId: childMemberId,
      memoriesId: d.id,
    };
    let auth = getAuth();
    let token = await auth.currentUser.getIdToken();
    console.log('reqObj', reqObj);
    await callPostAPI({ ...reqObj, token }, '/delete-memories');
    // console.log(result);
    // return { photoId };
    return { id: d.id };
  },
);

export const saveMemoriesThunk = createAsyncThunk('saveMemories/fetch', async (d, { getState }) => {
  // console.log(memory, chips);
  let state = getState();
  console.log('state.member', state.member);
  let childMemberId = state.member?.activeChild?.memberId;
  let rosterId = state.member?.activeChild?.rosterId;
  let schoolId = state.member?.activeChild?.schoolId;
  let groupId = state.member?.activeChild?.groupId;
  let order = findActiveOrder(state.member);
  console.log('orderId', order);
  let photoId = v4();
  let reqObj = {
    data: {
      ...d.data,
    },
    memberId: childMemberId,
    memoriesId: d.id,
    orderId: order.id,
    productId: order.productId,
    rosterId: rosterId,
    schoolId,
    groupId,
  };
  if (d.data.mType === 'note') {
    reqObj['data'] = {
      ...d.data,
      info: {
        ...d.data.info,
        profile: d.data.info.profile.isUploaded ? `notes/${photoId}.jpg` : d.data.info.profile,
      },
    };
    await uploadNotesProfile(d.data.info.profile, photoId);
  }
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPostAPI({ ...reqObj, token }, '/memories');

  console.log(result);
  return result;
});

export const editMemoriesThunk = createAsyncThunk('editMemories/fetch', async (d, { getState }) => {
  let state = getState();
  let childMemberId = state.member?.activeChild?.memberId;
  let memory = d.memory;
  let chips = d.selectedChips;
  let reqObj = {
    toUpdate: {
      age: memory.age,
      title: memory.title,
      personalize: chips.filter((c) => c.isSelected === true),
    },
    memberId: childMemberId,
    memoriesId: d.id,
  };
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPutAPI({ ...reqObj, token }, '/memories');
  console.log(result);
  // return { photoId };
  return result;
});
