import { createSlice } from '@reduxjs/toolkit';

import {
  deletePhotosThunk,
  getPhotosThunk,
  updatePhotosThunk,
  uploadPhotosThunk,
  deleteMemoriesThunk,
  editMemoriesThunk,
  getMemoriesThunk,
  saveMemoriesThunk,
  deleteNotesThunk,
  editNotesThunk,
  getNotesThunk,
  saveNotesThunk,
  getMyInfoThunk,
  updateMyInfoThunk,
  addAlbumThunk,
  deleteAlbumThunk,
} from './services/myAccount';
import { getOrdersThunk } from './services/myAccount/orders.ervices';

export const myAccountSlice = createSlice({
  name: 'myAccountSlice',
  initialState: {
    photos: {},
    albums: [],
    currentAlbum: '',
    freeUploadsCount: 0,
    purchasedUploadsCount: 0,
    purchasedPhotosCount: 0,
    freePhotos: {},
    lastPurchaseDate: null,
    localPhotos: {},
    notes: [],
    memories: [],
    orders: [],
    photoToEdit: {},
    myInfo: {},
    isLoading: false,
    callPhotosApi: true,
    callNotesApi: true,
    callMemoriesApi: true,
    error: {
      status: false,
      message: '',
    },
    sucess: {
      notes: false,
    },
  },
  reducers: {
    updatePhotos: (state, action) => {
      state.photos = action.payload;
    },
    updateLocalPhotos: (state, action) => {
      state.localPhotos[state.currentAlbum] = [
        ...action.payload,
        ...(state.localPhotos[state.currentAlbum] || []),
      ];

      let freeUploadsCount = state.freeUploadsCount;
      let purchasedUploadsCount = state.purchasedUploadsCount;
      action.payload.forEach((photo) => {
        if (state.freePhotos.date > new Date() && freeUploadsCount < state.freePhotos.count) {
          freeUploadsCount++;
        } else {
          purchasedUploadsCount++;
        }
      });

      state.freeUploadsCount = freeUploadsCount;
      state.purchasedUploadsCount = purchasedUploadsCount;
    },
    updateNoteSuccessState: (state, action) => {
      state.sucess.notes = false;
    },
    updateCallPhotosApiState: (state, action) => {
      state.callPhotosApi = true;
    },
    updatePhotoToEdit: (state, action) => {
      state.photoToEdit = action.payload;
    },
    updateCurrentAlbum: (state, action) => {
      state.currentAlbum = action.payload.title;
    },
    resetMyAccountForNewChild: (state, action) => {
      state.localPhotos = {};
      state.photos = {};
    },
    resetApisState: (state, action) => {
      state.callMemoriesApi = true;
      state.callPhotosApi = true;
      state.callNotesApi = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPhotosThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getPhotosThunk.fulfilled, (state, action) => {
        // console.log("thunk", action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.photos = action.payload.photos;
          state.albums = [...action.payload.albums];
          state.currentAlbum = state.albums[0]?.title || '';
          state.freeUploadsCount = action.payload.freeUploadsCount;
          state.purchasedUploadsCount = action.payload.purchasedUploadsCount;
          state.purchasedPhotosCount = action.payload.purchasedPhotosCount;
          state.freePhotos = {
            count: action.payload.freePhotos.count,
            date: new Date(action.payload.freePhotos.date).toISOString(),
          };
          state.lastPurchaseDate = action.payload.lastPurchaseDate;
          state.callPhotosApi = false;
          state.isLoading = false;
        }
      })
      .addCase(getPhotosThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })

      .addCase(uploadPhotosThunk.pending, (state, action) => {
        state.error.status = false;
      })
      .addCase(uploadPhotosThunk.fulfilled, (state, action) => {
        const { photoDict } = action.payload;

        state.isLoading = false;
        let freeUploadsCount = state.freeUploadsCount;
        let purchasedUploadsCount = state.purchasedUploadsCount;
        let newLocalPhotosState = state.localPhotos[state.currentAlbum];

        for (const photoId in photoDict) {
          const photoIdx = newLocalPhotosState.findIndex((lp) => lp.id === photoId);
          if (!photoDict[photoId].success) {
            newLocalPhotosState.splice(photoDict, 1);
            // Update freePhotosCount, purchasedUploadsCount (need to update here if any error because these values are updated in updateLocalPhotos)
            if (state.freePhotos.date > new Date() && freeUploadsCount > 0) {
              freeUploadsCount--;
            } else {
              purchasedUploadsCount--;
            }
          } else {
            newLocalPhotosState[photoIdx] = {
              ...newLocalPhotosState[photoIdx],
              isPending: false,
            };
          }
        }

        state.localPhotos[state.currentAlbum] = newLocalPhotosState;
        state.freeUploadsCount = freeUploadsCount;
        state.purchasedUploadsCount = purchasedUploadsCount;
      })
      .addCase(uploadPhotosThunk.rejected, (state, action) => {
        const { photoDict } = action.payload;

        state.isLoading = false;
        let freeUploadsCount = state.freeUploadsCount;
        let purchasedUploadsCount = state.purchasedUploadsCount;
        let newLocalPhotosState = state.localPhotos[state.currentAlbum];

        for (const photoId in photoDict) {
          const photoIdx = newLocalPhotosState.findIndex((lp) => lp.id === photoId);
          if (!photoDict[photoId].success) {
            newLocalPhotosState.splice(photoDict, 1);
            // Update freePhotosCount, purchasedUploadsCount (need to update here if any error because these values are updated in updateLocalPhotos)
            if (state.freePhotos.date > new Date() && freeUploadsCount > 0) {
              freeUploadsCount--;
            } else {
              purchasedUploadsCount--;
            }
          } else {
            newLocalPhotosState[photoIdx] = {
              ...newLocalPhotosState[photoIdx],
              isPending: false,
            };
          }
        }

        state.localPhotos[state.currentAlbum] = newLocalPhotosState;
        state.freeUploadsCount = freeUploadsCount;
        state.purchasedUploadsCount = purchasedUploadsCount;
      })

      .addCase(deletePhotosThunk.pending, (state, action) => {
        state.error.status = false;
      })
      .addCase(deletePhotosThunk.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.localPhotos[state.currentAlbum] =
            state.localPhotos[state.currentAlbum]?.filter((p) => {
              if (p.id !== action.payload.photoId) {
                return { ...p, isPending: false };
              }
              return false;
            }) || [];
          state.photos[state.currentAlbum] =
            state.photos[state.currentAlbum]?.filter((p) => {
              if (p.id !== action.payload.photoId) {
                return { ...p, isPending: false };
              }
              return false;
            }) || [];

          if (state.purchasedUploadsCount !== 0) {
            state.purchasedUploadsCount = state.purchasedUploadsCount - 1;
          } else if (state.freeUploadsCount !== 0) {
            state.freeUploadsCount = state.freeUploadsCount - 1;
          }
        }
      })
      .addCase(deletePhotosThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(addAlbumThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(addAlbumThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log("thunk", action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.albums = [...state.albums, action.payload];
        }
      })
      .addCase(addAlbumThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(deleteAlbumThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(deleteAlbumThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.albums = state.albums.filter((albumObj) => {
            return albumObj.id !== action.payload.id;
          });
        }
      })
      .addCase(deleteAlbumThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(saveNotesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(saveNotesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.sucess.notes = true;
          state.callNotesApi = true;
        }
      })
      .addCase(saveNotesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(getNotesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getNotesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          console.log(action.payload.data);
          state.notes = action.payload.data;
          state.callNotesApi = false;
        }
      })
      .addCase(getNotesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(editNotesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(editNotesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.sucess.notes = true;
          state.callNotesApi = true;
        }
      })
      .addCase(editNotesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(deleteNotesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(deleteNotesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.callNotesApi = true;
          state.notes = state.notes.filter((p) => {
            if (p.id !== action.payload.notesId) {
              return true;
            }
            return false;
          });
        }
      })
      .addCase(deleteNotesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(updatePhotosThunk.pending, (state, action) => {
        // state.isLoading = true;
        state.error.status = false;
      })
      .addCase(updatePhotosThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          let data = action.payload.data;
          let index = (state.photos[state.currentAlbum] || []).findIndex((p) => p.id === data.id);
          if (index < 0) {
            let lIndex = state.localPhotos[state.currentAlbum].findIndex(
              (p) => p.photoId === data.id,
            );
            if (lIndex >= 0) {
              state.localPhotos[state.currentAlbum][lIndex] = {
                ...state.localPhotos[state.currentAlbum][lIndex],
                caption: data.caption,
              };
            }
          } else {
            console.log(index);
            state.photos[state.currentAlbum][index] = {
              ...state.photos[state.currentAlbum][index],
              caption: data.caption,
              addedToBook: data.addedToBook,
            };
          }
        }
      })
      .addCase(updatePhotosThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(getMemoriesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getMemoriesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          console.log(action.payload.data);
          state.memories = action.payload.data;
          state.callMemoriesApi = false;
        }
      })
      .addCase(getMemoriesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(editMemoriesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(editMemoriesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
        }
      })
      .addCase(editMemoriesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(saveMemoriesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(saveMemoriesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          let data = action.payload;
          let memoriesIndex = state.memories.findIndex((m) => m.id === data.memories.id);
          console.log('memoriesIndex', memoriesIndex);
          if (memoriesIndex >= 0) {
            let s = [...state.memories];
            s[memoriesIndex] = data.memories;
            state.memories = s;
          } else {
            state.memories = [data.memories, ...state.memories];
          }
        }
      })
      .addCase(saveMemoriesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(deleteMemoriesThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(deleteMemoriesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          let d = action.payload;
          state.memories = state.memories.filter((m) => m.id !== d.id);
        }
      })
      .addCase(deleteMemoriesThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(getOrdersThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getOrdersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.orders = action.payload.data;
        }
      })
      .addCase(getOrdersThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(getMyInfoThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getMyInfoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.myInfo = action.payload.data;
        }
      })
      .addCase(getMyInfoThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(updateMyInfoThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(updateMyInfoThunk.fulfilled, (state, action) => {
        // state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          console.log(action.payload.message);
        }
      })
      .addCase(updateMyInfoThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      });
  },
});

export const auth = (state) => state.auth;

export const {
  updatePhotos,
  updatePhotoToEdit,
  updateLocalPhotos,
  updateNoteSuccessState,
  resetApisState,
  updateCurrentAlbum,
  resetMyAccountForNewChild,
} = myAccountSlice.actions;

export default myAccountSlice.reducer;
