import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { callPostAPI } from '../../../helpers/apiCall';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { coverType } from '../../../utils/functions';

export const parentAssociationThunk = createAsyncThunk(
  'parentAssociation/fetch',
  async ({ state, activeChild, school }, getState) => {
    let { member, schoolCode, memoryBookSteps } = getState.getState();
    const planId = memoryBookSteps.currentPlan;

    let reqObject = {
      planId,
      relation: member.relation || 0,
      toAddMember: {
        firstName: state.firstName,
        lastName: state.lastName,
        middleName: state.middleName,
        memberId: activeChild.memberId,
        rosterId: activeChild.rosterId,
        groupId: activeChild.groupId,
        groupInfo: activeChild.groupInfo,
        gender: state.gender,
        dob: state.dob === '' ? null : Timestamp.fromDate(new Date(state.dob)),
        isParentConsent: state.isParentConsent,
      },
      school: {
        name: school.name,
        type: school.type,
        id: school.id,
      },
      product: {
        productid: '64a0f49a-b2c8-4575-8d4b-eeda303a6bed',
        cover: {
          id: state.cover,
          price: schoolCode.products[0]?.cover[state.cover]?.price,
          type: coverType(state.cover),
        },
        quantity: state.copies,
      },
    };
    let auth = getAuth();
    let token = await auth.currentUser.getIdToken();
    let result = await callPostAPI({ ...reqObject, token: token }, '/parent-associations');

    if (!state.isSkipped) {
      await uploadProfilePhoto(member.member, state, result.profile);
    }

    // For navigation
    result['isSchoolBuy'] = planId === 'school-plan' ? true : false;

    return result;
  },
);

export const createTempRosterThunk = createAsyncThunk(
  'createTempRoster/fetch',
  async ({ state, school }, getState) => {
    let { member, schoolCode, memoryBookSteps } = getState.getState();
    const planId = memoryBookSteps.currentPlan;

    let reqObject = {
      planId,
      school: school,
      info: {
        firstName: state.firstName,
        lastName: state.lastName,
        middleName: state.middleName,
        // dob: Timestamp.fromDate(new Date(state.dob.$d)),
        isParentConsent: state.isParentConsent,
        gender: state.gender,
        dob: !state.dob ? null : Timestamp.fromDate(new Date(state.dob)),
      },
      relation: member.relation || 0,
      product: {
        productid: '64a0f49a-b2c8-4575-8d4b-eeda303a6bed',
        cover: {
          id: state.cover,
          price: schoolCode.products[0]?.cover[state.cover]?.price,
          type: coverType(state.cover),
        },
        quantity: state.copies,
      },
    };
    let auth = getAuth();
    let token = await auth.currentUser.getIdToken();
    let result = await callPostAPI({ ...reqObject, token: token }, '/create-temp-roster');
    if (!state.isSkipped) {
      await uploadProfilePhoto(member.member, state, result.profile);
    }

    // For navigation
    result['isSchoolBuy'] = planId === 'school-plan' ? true : false;

    return result;
  },
);

const uploadProfilePhoto = async (member, state, id) => {
  let path = `${member.id}/${member.id}/R-5/${id}.jpg`;
  const firebaseApp = getApp();
  const storage = getStorage(
    firebaseApp,
    `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-sotohp-daolpu`,
  );
  let storageRef = ref(storage, path);
  let blob = await fetch(state.profile.url).then((r) => r.blob());
  await uploadBytes(storageRef, blob);

  return;
};
