import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { Axios } from '../api/axios';

export const callPostAPI = async (data, url) => {
  let token = data.token;
  delete data.token;

  let result = await Axios.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
export const callGetAPI = async (data, url) => {
  let token = data.token;
  delete data['token'];
  let result = await Axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
export const callPutAPI = async (data, url) => {
  let token = data.token;
  delete data['token'];
  let result = await Axios.put(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return result;
};
export const callDeleteAPI = async (data, url) => {
  let token = data.token;
  delete data['token'];
  let result = await Axios.delete(url, {
    headers: {
      Authorization: token,
    },
    data,
  });
  return result;
};
export const savePersonalizeData = async (
  parentState,
  product,
  activeChild,
  props,
  isNewRoster,
  member,
  school,
) => {
  let auth = getAuth();
  let personalizedState = JSON.parse(JSON.stringify(parentState));
  let reqObject = {
    productId: product.id,
    data: {},
    memberId: member.id,
  };
  if (activeChild) {
    reqObject['childMemberId'] = activeChild.amId ? activeChild.amId : activeChild.memberId;
  } else {
    reqObject['childMemberId'] = props.childMemberId;
  }
  if (isNewRoster) {
    reqObject['isNewRoster'] = isNewRoster;
    reqObject['memberInfo'] = {
      ...member.info[member.id],
      email: auth.currentUser.email,
    };

    reqObject['group'] = parentState.group;
    reqObject['relation'] = parentState.relation;
  }
  reqObject['school'] = school;
  reqObject['rosterId'] = activeChild ? activeChild.id : props.rosterId;
  reqObject['activeCover'] = props.activeCover;
  reqObject.token = await auth.currentUser.getIdToken();
  console.log('in if for handle next', reqObject);
  if (personalizedState.photos) {
    personalizedState.photos = {
      data: parentState.photos.data.filter((p) => !p.owner || !p.isSelected),
    };
    console.log(personalizedState.photos);
    personalizedState.photos = {
      data: personalizedState.photos.data.map((p) => {
        let path = `${member.id}/${member.id}/R-5/${p.id}.jpg`;
        console.log('path', path);
        return {
          id: p.id,
          url: path,
          type: p.isSelected === false ? 'remove' : 'add',
        };
      }),
    };
    console.log(personalizedState);
  }
  let pKey = getPKeyObject(personalizedState, activeChild, props);
  reqObject.data = pKey;
  if (props.isEdited) {
    reqObject['cartId'] = props.cart.id;
  }
  console.log(reqObject);
  let result = await callPostAPI(
    reqObject,
    props.isEdited ? '/editpersonalize' : '/savepersonalize',
  );
  let photoResult = await uploadPhotos(personalizedState.photos, parentState.photos, member);
  let profile = uploadProfilePhoto(personalizedState.info.data, parentState, member);

  return result;
};

const uploadPhotos = async (photos, statePhotos, member) => {
  let data = photos.data;
  console.log('inside photos', photos.data);
  console.log('statePhotos', statePhotos);
  let photoList = [];
  for (const photo of data) {
    // let photoId = v4();
    let statePhoto = statePhotos.data.find((p) => p.id === photo.id);
    if (statePhoto.isSelected) {
      if (!photo.owner) {
        let path = `${member.id}/${member.id}/R-5/${photo.id}.jpg`;
        const firebaseApp = getApp();
        const storage = getStorage(firebaseApp, 'gs://teacher-54ff2-sotohp-daolpu');
        let storageRef = ref(storage, path);
        let blob = await fetch(statePhoto.url).then((r) => r.blob());

        let uploadPromise = await uploadBytes(storageRef, blob);
        photoList.push({
          id: photo.photoId,
          fileName: path,
        });
      }
    }
  }
  return photoList;
};
const uploadProfilePhoto = async (info, state, member) => {
  console.log(info);
  if (state.info.data.profile?.isUpload) {
    let path = `${member.id}/${member.id}/R-5/${info.profile.id}.jpg`;
    console.log(path);
    const firebaseApp = getApp();
    const storage = getStorage(firebaseApp, 'gs://teacher-54ff2-sotohp-daolpu');
    let storageRef = ref(storage, path);
    console.log(state.info.data);
    let blob = await fetch(state.info.data.profile.url).then((r) => r.blob());

    let uploadPromise = await uploadBytes(storageRef, blob);
  }
  return;
};

const getPKeyObject = (personalizedState, activeChild, props) => {
  let pKey = {};
  console.log('personalizedState', personalizedState);
  Object.keys(personalizedState).map((d) => {
    console.log(d);
    switch (d) {
      case 'info':
        pKey[d] = { data: personalizedState[d].data };
        if (personalizedState[d].data?.profile?.fileName) {
          personalizedState.info.data.profile = {
            ...personalizedState.info.data.profile,
            url: `${activeChild ? activeChild.rosterId : props.rosterId}.jpg`,
          };
        } else {
          delete pKey[d].data.profile;
        }
        pKey[d] = {
          data: { ...personalizedState[d].data, url: undefined },
        };
        break;
        // case "photos":
        // personalizedState[d].data = personalizedState[d].data.map((p) => {
        //   return {
        //     id: p.photoId,
        //     url: p.bucketPath,
        //   };
        // });
        // if (personalizedState[d].data.length > 0) {
        //   pKey[d] = { data: personalizedState[d].data };
        // } else {
        //   console.log("delete called");
        //   delete pKey[d];
        // }
        break;
      default:
        if (personalizedState[d].data) {
          pKey[d] = { data: personalizedState[d].data };
        }
        break;
    }
  });

  return pKey;
};

export const getPersonalizeData = async (props) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let reqObject = {
    childMemberId: props.childMemberId,
    cartId: props.cart.id,
    token,
  };
  let result = await callPostAPI(reqObject, '/get-personalize-data');
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp, 'gs://teacher-54ff2-eliforp');
  try {
    console.log('props', props);
    let url = await getDownloadURL(ref(storage, `${props.rosterId}.jpg`));
    console.log(url);
    result.info.profile.url = url;
  } catch (error) {}
  if (result.photos) {
    let list = await getPhotosUrl(result);
    console.log('list', list);
    result.photos.data = result.photos.data.map((photo, index) => {
      if (photo?.url) {
        return {
          ...photo,
          url: list[index],
          isSelected: true,
        };
      }
    });
    result.photos.data = result.photos.data.filter((p) => p != undefined || p != null);
  }

  return result;
};

async function getPhotosUrl(data) {
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp, 'gs://teacher-54ff2-deziser-sotohp');
  let p = data.photos.data.map((photo) => {
    if (photo?.url) {
      return getDownloadURL(ref(storage, photo.url));
    }
  });
  let list = await Promise.all(p);
  return list;
}

export const schoolSignupApi = async (state, member) => {
  // let state = schoolState;
  let reqObj = {
    school: state.school,
    plans: state.plans,
    info: member.info[member.id],
    role: state.role,
    // role: 0,
    // product: {
    //   description: "Personalized Memory Book",
    // },
  };
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  console.log('token', token);
  let result = await callPostAPI({ ...reqObj, token }, '/schools/signup', token);
  return result;
};
