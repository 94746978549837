import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState: {
        snackbar: {
            show: false,
            variant: '',
            message: ''
        }
    },
    reducers: {
        setSnackbar: (state, action) => {
            state.snackbar = {
                show: true,
                variant: action.payload.variant,
                message: action.payload.message
            }
        },
        resetSnackbar: (state, action) => {
            state.snackbar = {
                show: false,
                variant: '',
                message: ''
            }
        }
    },
});

export const { setSnackbar, resetSnackbar } = snackbarSlice.actions;
export { snackbarSlice };
export default snackbarSlice.reducer;