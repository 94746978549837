import { createAsyncThunk } from '@reduxjs/toolkit';

import { Axios } from '../../../api/axios';

export const checkInStudent = createAsyncThunk(
  'post/student-checkin',
  async ({ rosterId, groupId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.member?.activeChild?.schoolId;
      const response = await Axios.post('/school/child/checkin', {
        schoolId,
        rosterId,
        groupId,
        time: new Date(),
      });
      return { ...response, rosterId, groupId };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const checkOutStudent = createAsyncThunk(
  'post/student-checkout',
  async ({ rosterId, checkedOutGroupId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.member?.activeChild?.schoolId;
      const response = await Axios.post('/school/child/checkout', {
        schoolId,
        rosterId,
        checkedOutGroupId,
        time: new Date(),
      });
      return { ...response, rosterId };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const moveStudentGroup = createAsyncThunk(
  'post/move-student-group',
  async ({ rosterId, checkedInGroupId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.member?.activeChild?.schoolId;
      const response = await Axios.post('/school/child/movegroup', {
        schoolId,
        rosterId,
        checkedInGroupId,
        time: new Date(),
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
