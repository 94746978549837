import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography, Grid, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderItemsChildrenThunk } from '../../reducers/parentReducer';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Parents from '../../assets/parents.png';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import DismissibleBanner from '../../components/global/DismissibleBanner';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import FamilyMemories from '@mui/icons-material/Diversity1Outlined';

const ParentAccountHome = ({ handleToggleSamplePagesDialog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orderItems } = useSelector((state) => state.parent);

  useEffect(() => {
    dispatch(getOrderItemsChildrenThunk());
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <Banner /> */}
      {orderItems.isLoading && <Spinner open />}
      <Grid container alignItems={'center'} justifyContent={'center'} sx={{ mt: { xs: 8, sm: 2 } }}>
        {/* <Grid item xs={12} sm={10} lg={12} sx={{ mx: 2 }}>
          <Box
            component={'img'}
            // src={Boy1}
            alt=''
            sx={{
              width: { xs: '75px', md: '50px' },
              height: { xs: '75px', md: '50px' },
              ml: { xs: 0, md: '10px' },
            }}
          />
          <Stack alignItems={'flex-start'}>
            {orderItems.selectedChild.id && (
              <Typography
                align='center'
                sx={{
                  fontSize: '18px',
                  color: 'rgb(45,45,45)',
                  letterSpacing: -0.09,
                  fontWeight: 700,
                  mx: 'auto',
                }}
              >
                We have received photos of{' '}
                {orderItems?.selectedChild?.info?.firstName?.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                from{' '}
                {orderItems?.selectedChild?.school?.name?.replace(/^\w/, (c) => c.toUpperCase())}
              </Typography>
            )}
            <Typography
              align='center'
              sx={{ width: { md: '72%' }, mt: '4px', fontSize: '16px', lineHeight: '22px' }}
            >
              You have been invited to select or add school memories of your child.{' '}
              <strong>This is an optional step</strong> and if you decide to skip this step, our
              smart photo processing tool will select the photos for your child's yearbook.
            </Typography>
          </Stack>
        </Grid> */}
        {/* )}
            bannerBgColor='rgb(244,245,246)'
            showCloseBtn={false}
          /> */}

        {/* <Grid item xs={12} sm={10} lg={8} sx={{ mx: 2 }}>
          <Box
            sx={{
              px: 2,
              pb: '16px',
              pt: '16px',
              bgcolor: 'rgb(245,246,247)',
              borderRadius: 2,
              mt: '20px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              border: '1px solid rgba(225,225,225)',
              width: '100%',
            }}
          >
            {orderItems.selectedChild.id && (
              <Typography
                align='center'
                sx={{
                  fontSize: '18px',
                  color: 'rgb(45,45,45)',
                  letterSpacing: -0.09,
                  fontWeight: 700,
                  mx: 'auto',
                }}
              >
                We have received photos of{' '}
                {orderItems?.selectedChild?.info?.firstName?.replace(/^\w/, (c) => c.toUpperCase())}{' '}
                from{' '}
                {orderItems?.selectedChild?.school?.name?.replace(/^\w/, (c) => c.toUpperCase())}
              </Typography>
            )}
            <Typography
              align='center'
              component={'div'}
              sx={{
                fontSize: '15px',
                color: 'rgb(45,45,45)',
                letterSpacing: -0.09,
                mt: 0.5,
                lineHeight: 1.4,
                mx: 'auto',
              }}
            >
              You have been invited to select or upload school memories of your child. This is an
              optional step and if you decide to skip this step, our smart photo processing tool
              will select the photos for your child's yearbook.
            </Typography>{' '}
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={10} lg={8} sx={{ mx: 2 }}>
          <Box>
            <Typography align='center' sx={{ fontSize: '36px', fontWeight: 700, mt: 8 }}>
              Your Yearbook, Your Way
            </Typography>{' '}
            <Typography
              align='center'
              sx={{ fontSize: '16px', color: 'GrayText', mt: '-2px', lineHeight: '22px' }}
            >
              Your child's personalized yearbook is a compilation of your child's school memories,
              personal memories, messages from the school, and any key activities your child's
              school may want to highlight.{' '}
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={10} lg={8} sx={{ mx: 2 }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems='center'
            sx={{ mx: 4 }}
          >
            <Box
              sx={{
                mt: 3,
                gap: 2,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <img src={Cover} alt='' style={{ width: '100%' }}></img>
              <img src={Cover} alt='' style={{ width: '100%' }}></img>
              <img src={Cover} alt='' style={{ width: '100%' }}></img>
            </Box>
            <Button
              variant='outlined'
              onClick={() => handleToggleSamplePagesDialog(true, 'FAMILY_MEMORIES')}
              sx={{ mt: 3, width: 207, height: 50 }}
            >
              View Sample Pages
            </Button>
          </Box>
        </Grid> */}
        <Grid item container xs={12} sm={10} lg={8} sx={{ mx: 2, justifyContent: 'center' }}>
          <Grid item sm={10}>
            <Box
              sx={{
                mx: 'auto',
                mt: 5,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img src={Parents} alt='' style={{ width: '135px' }}></img>
            </Box>
          </Grid>
          {/* <Grid item xs={9} sm={6}>
            <Box>
              <Typography
                align='center'
                sx={{
                  fontSize: '20px',
                  color: 'rgb(45,45,45)',
                  letterSpacing: -0.09,
                  fontWeight: 700,
                  lineHeight: 1.35,
                  mt: 3,
                }}
              >
                Parents have an option to select or upload
              </Typography>

              <Typography
                align='center'
                sx={{
                  fontSize: '20px',
                  color: 'rgb(45,45,45)',
                  letterSpacing: -0.09,
                  fontWeight: 700,
                  lineHeight: 1.35,
                }}
              >
                up to 30 memories for their child which include
              </Typography>
            </Box>
          </Grid> */}
          <Grid item xs={10}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              sx={{ mt: '10px' }}
            >
              <BulletPoints
                icon={<AddPhotoAlternateOutlinedIcon sx={{ fontSize: 32 }} />}
                text={
                  <Box>
                    <Box>
                      <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                        <strong>OPTIONAL:</strong> You can select or add{' '}
                        <strong>upto 20 school memories</strong> which includes school photos,
                        personal note or other growing up memories.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 14, color: 'GrayText', mt: '6px' }}>
                        If you decide to skip this step, our smart photo processing tool will select
                        the photos for your child's yearbook.
                      </Typography>
                    </Box>
                  </Box>
                }
              />

              <BulletPoints
                icon={<AllInclusiveIcon sx={{ fontSize: 32 }} />}
                text={
                  <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                    In addtion to the 20 school memories above, we will add 10-15 photos
                    highlighting key school activities, staff members and other students in the
                    school. <strong>Your child may or may not be included in these photos.</strong>
                  </Typography>
                }
              />
              <BulletPoints
                icon={<FamilyMemories sx={{ fontSize: 32 }} />}
                text={
                  <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                    8 free Family photos and any additional photos you may have purchased are in
                    addition to the 20 school memories you can select and will be included in your
                    child's yearbook
                  </Typography>
                }
              />
              <Typography sx={{ fontSize: 14, color: 'Graytext', mt: 3 }}>
                Note: If you wish to include additional memories, you can upgrade to our Premium
                package.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} lg={8} sx={{ mx: 2, pb: 8 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              onClick={() => navigate('/parent-account/school-memories')}
              sx={{ mt: 5, width: 207, height: 50 }}
            >
              Get Started
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const BulletPoints = ({ icon, text }) => {
  return (
    <>
      <Stack direction={'row'} justifyContent={'center'} gap={2} sx={{ mt: '30px' }}>
        {icon}
        {text}
      </Stack>
    </>
  );
};

const Banner = () => {
  return (
    <>
      <Stack
        justifyContent='center'
        sx={{
          height: 40,
          bgcolor: 'tomato',
        }}
      >
        <Typography align='center' sx={{ color: 'white', fontWeight: 700, fontSize: 18 }}>
          Coming Soon: Personalized Workbooks
        </Typography>
      </Stack>
    </>
  );
};

export default ParentAccountHome;
