import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { callPostAPI } from '../../../helpers/apiCall';

export const getOrdersThunk = createAsyncThunk('get-orders/fetch', async (d, { getState }) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let reqObj = {
    // memberId: childMemberId,
    memberId: auth.currentUser.uid,
    token: token,
  };
  let result = await callPostAPI(reqObj, '/get-orders');
  return { data: result.data };
});
