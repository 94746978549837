import { createSlice } from '@reduxjs/toolkit';

import perfectBindingImg from '../assets/memorySteps/perfectBinding.jpg';
import hardCoverImg from '../assets/memorySteps/hardCoverBinding.png';
import saddleStitchBindingImg from '../assets/memorySteps/saddleStitchBinding.png';

export const initialChildsData = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  profile: '',
  dob: '',
  cover: '',
  isParentConsent: false,
  initialParentConsent: false,
};

const initialState = {
  childsData: { ...initialChildsData },
  isNewChild: false,
  currentScreen: '',
  availbleScreenWithoutValue: '',
  completedScreens: [],
  currentPlan: 'parent-plan',
  screensData: {
    'parent-plan': {
      selectChild: {
        name: 'Select Child',
        nextScreen: 'selectGender',
        isBottomImage: true,
      },
      selectGender: {
        name: 'Select Gender',
        nextScreen: 'uploadProfilePhoto',
        isBottomImage: true,
      },
      uploadProfilePhoto: {
        name: 'Profile Photo',
        nextScreen: 'selectCover',
        isBottomImage: false,
      },
      selectCover: {
        name: 'Select Cover',
        covers: [
          {
            id: 0,
            imgUrl: saddleStitchBindingImg,
            title: 'Saddle Stitch Cover',
            pageCount: 32,
            price: 30,
            bindingStyle: 'Saddle Stitch',
            extraText: 'Staple-bound for a clean and simple finish',
          },
          {
            id: 1,
            imgUrl: perfectBindingImg,
            title: 'Perfect Binding Cover',
            pageCount: 36,
            price: 35,
            bindingStyle: 'Perfect Binding',
            extraText: 'Smooth, flat spine for a polished look',
          },
          {
            id: 2,
            imgUrl: hardCoverImg,
            title: 'Hardcover',
            pageCount: 36,
            price: 40,
            bindingStyle: 'Hardcover',
            extraText: 'Sturdy and long-lasting with a protective hardcover',
          },
        ],
        selectedValue: '',
        nextScreen: 'selectQuantity',
        isBottomImage: false,
      },
      selectQuantity: {
        name: 'Select Quantity',
        isLastStep: true,
        navigate: '/order-summary',
        isBottomImage: false,
      },
    },
    'school-plan': {
      selectChild: {
        name: 'Select Child',
        nextScreen: 'selectGender',
        isBottomImage: true,
      },
      selectGender: {
        name: 'Select Gender',
        nextScreen: 'uploadProfilePhoto',
        isBottomImage: true,
      },
      uploadProfilePhoto: {
        name: 'Profile Photo',
        navigate: '/parent/photos',
        isBottomImage: false,
        isLastStep: true,
      },
    },
    'combo-plan': {
      selectChild: {
        name: 'Select Child',
        nextScreen: 'selectGender',
        isBottomImage: true,
      },
      selectGender: {
        name: 'Select Gender',
        nextScreen: 'uploadProfilePhoto',
        isBottomImage: true,
      },
      uploadProfilePhoto: {
        name: 'Profile Photo',
        navigate: '/parent/photos',
        isBottomImage: false,
        isLastStep: true,
      },
    },
  },
};

export const memoryBookStepsSlice = createSlice({
  name: 'memoryBookSteps',
  initialState: { ...initialState },
  reducers: {
    changeScreen: (state, action) => {
      state.currentScreen = action.payload.screenType;
    },
    changeCurrentPlan: (state, action) => {
      state.currentPlan = action.payload.planId;
    },
    updateCompletedScreen: (state, action) => {
      let { type: screenType } = action.payload;
      state.completedScreens.push(screenType);
      // Find next screen type
      let nextScreenIdx =
        Object.keys(state.screensData[state.currentPlan]).findIndex((type) => type === screenType) +
        1;
      // Set current screen as next screen
      if (nextScreenIdx === Object.keys(state.screensData[state.currentPlan]).length) {
        state.currentScreen = 'completed';
      } else {
        state.currentScreen = Object.keys(state.screensData[state.currentPlan])[nextScreenIdx];
      }
      // Set in progress screen as current screen
      state.availbleScreenWithoutValue = Object.keys(state.screensData[state.currentPlan])[
        nextScreenIdx
      ];
    },
    updateChildsData: (state, action) => {
      state.childsData = action.payload.data;
    },
    updateNewChildStatus: (state, action) => {
      state.isNewChild = action.payload.data;
    },
    resetMemoryBookState: (state, action) => {
      state.childsData = { ...initialChildsData };
      state.isNewChild = false;
      state.currentScreen = '';
      state.availbleScreenWithoutValue = '';
      state.completedScreens = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeScreen,
  changeCurrentPlan,
  updateCompletedScreen,
  updateChildsData,
  updateNewChildStatus,
  resetMemoryBookState,
} = memoryBookStepsSlice.actions;

export default memoryBookStepsSlice.reducer;
