import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { callDeleteAPI, callPostAPI } from '../../../helpers/apiCall';

export const addAlbumThunk = createAsyncThunk('addAlbum/post', async (d) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();

  // Fetch all albums
  let albumResult = await callPostAPI({ token, title: d.title }, '/member/albums');

  return albumResult;
});

export const deleteAlbumThunk = createAsyncThunk('addAlbum/delete', async (d) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();

  // Delete album by id
  const result = await callDeleteAPI({ token, id: d.id }, `/member/albums`);

  return result;
});
