import { getApp } from '@firebase/app';
import { getStorage, ref, uploadBytes } from '@firebase/storage';
import { callPostAPI } from '../../../helpers/apiCall';
import { getAuth } from '@firebase/auth';

export const findActiveOrder = (member) => {
  let id;
  let pId;
  for (const o of member.orders) {
    for (const p of Object.values(o.product)) {
      for (const r of Object.values(p)) {
        if (r.purchasedFor?.rosterId === member.activeChild.rosterId) {
          id = o.id;
          pId = r.id;
        }
      }
    }
  }
  // let index = member.orders.findIndex(
  //   (o) =>
  //     o.product["4f2426eb-ad5e-469d-b98a-85ed7c0cc8f4"][
  //       member.activeChild.rosterId
  //     ]["purchasedFor"]["rosterId"]===member.activeChild.rosterId
  // );
  // console.log("index", index, member.activeChild.rosterId, member.orders);
  // if (index >= 0) {
  let product = member?.member?.product && member?.member?.product[member.activeChild.schoolId];
  let index = product?.findIndex((p) => p.category === 'memory-book');
  if (index >= 0) {
    pId = product[index].id;
  }
  return { id: id, productId: pId };
  // }
};

export const uploadNotesProfile = async (state, id) => {
  let path = `notes/${id}.jpg`;
  console.log(path);
  const firebaseApp = getApp();
  const storage = getStorage(
    firebaseApp,
    `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-eliforp`,
  );
  let storageRef = ref(storage, path);
  let blob = await fetch(state.url).then((r) => r.blob());
  await uploadBytes(storageRef, blob);

  return;
};

export const photosCheckout = async ({ title, quantity, price, activeChild }) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();

  let reqObj = {
    schoolId: activeChild.schoolId,
    purchasedPhotos: {
      title: title,
      quantity: quantity,
      price: price,
    },
    purchasedFor: {
      rosterId: activeChild.rosterId,
      memberId: activeChild.memberId,
      groupId: activeChild.groupId,
    },
    token,
  };

  let result = await callPostAPI(reqObj, '/parent/photos-purchase-checkout');

  return result;
};
