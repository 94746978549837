// Wait till image is loaded
export const imageLoadPromise = (imgUrl) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject();
    };
    image.src = imgUrl;
  });

export const validateImageResolution = async (imgUrl, height, width) => {
  try {
    // wait for image to load
    let loadedImg = await imageLoadPromise(imgUrl);
    if (loadedImg.naturalWidth < width || loadedImg.naturalWidth < height) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

// Add more from http://en.wikipedia.org/wiki/List_of_file_signatures
/**
 *
 * @param {string} headerString first 4 hex data of file
 * @returns
 */
export function mimeType(headerString) {
  let type;
  switch (headerString) {
    case '89504e47':
      type = 'image/png';
      break;
    case 'ffd8ffdb':
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe4':
    case 'ffd8ffe5':
    case 'ffd8ffe6':
    case 'ffd8ffe7':
    case 'ffd8ffe8':
    case 'ffd8ffe9':
    case 'ffd8ffea':
    case 'ffd8ffeb':
    case 'ffd8ffec':
    case 'ffd8ffed':
    case 'ffd8ffee':
    case 'ffd8ffef':
      type = 'image/jpeg';
      break;
    default:
      type = null;
      break;
  }

  return type;
}

/**
 *
 * @param {file} file
 * @param {function} callback (error)
 * @returns Promise
 */
export async function isValidImageFileType(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(false);
    }
    try {
      var fileReader = new FileReader();

      fileReader.onloadend = function (e) {
        var arr = new Uint8Array(e.target.result).subarray(0, 4);
        var header = '';
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // Check the file signature against known types
        resolve(mimeType(header));
      };

      fileReader.readAsArrayBuffer(file);
    } catch (error) {
      resolve(false);
    }
  });
}
