import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import Spinner from '../../../../../components/Spinner';
import { photosCheckout } from '../../../../../reducers/services/myAccount/utils';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import AdditonalPhotos from './AddtionalPhotos';

const BuyMorePhotosDialog = ({ open, handleClose }) => {
  const { orderItems } = useSelector((state) => state.parent);
  const additionalPhoto1QtyPrice = 1.99;
  const taxRate = orderItems.selectedChild.school.id === '1LQS' ? 0.09375 : 0.09375;

  const [isLoading, setIsLoading] = useState(false);
  const [alertBox, setAlertBox] = useState();
  const [message, setAlertMessage] = useState();
  const [quantity, setQuantity] = useState(1);
  const [totals, setTotals] = useState({
    subTotal: 0,
    tax: 0,
    total: 0,
    shipping: 0,
  });

  const handleAlertClose = () => {
    setAlertBox(false);
  };

  const updateQuantity = (newQty) => {
    setQuantity(newQty);
  };

  const calculateTotal = () => {
    let total = quantity * additionalPhoto1QtyPrice;
    let newTax = total * taxRate;

    if (total > 999999) {
      setAlertBox(true);
      setAlertMessage('Total amount must be less than $999,999');
      setTotals({
        ...totals,
        subTotal: 0,
      });
    } else {
      setTotals({
        subTotal: total,
        tax: newTax,
        total: total + newTax,
        shipping: 0,
      });
    }
  };

  const handleCheckout = async () => {
    setIsLoading(true);
    let res = await photosCheckout({
      title: 'Additional Personal Photos',
      quantity: quantity,
      price: additionalPhoto1QtyPrice,
      activeChild: {
        schoolId: orderItems.selectedChild.school.id,
        rosterId: orderItems.selectedChild.rosterId,
        groupId: orderItems.selectedChild.groupId,
        memberId: orderItems.selectedChild.childMemberId,
      },
    });
    if (res.success) {
      loadStripe(process.env.REACT_APP_API_STRIPE_KEY).then((stripe) => {
        stripe.redirectToCheckout({ sessionId: res.sessionId });
      });
    } else {
      setAlertMessage('Something went wrong!!!');
      setAlertBox(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    calculateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
      sx={{
        '& .MuiPaper-root': {
          m: { xs: '12px', sm: '32px' },
        },
      }}
    >
      <Spinner open={isLoading} />
      <Snackbar
        open={alertBox}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity={'warning'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <DialogTitle component={'div'} sx={{ px: '30px' }}>
        <Typography variant='h5' fontWeight={500}>
          Purchase Additional Photos
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close
            sx={{
              fontSize: '18px',
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <AdditonalPhotos quantity={quantity} updateQuantity={updateQuantity} />
        <Box sx={{ p: '20px 30px' }}>
          <Summary totals={totals} />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: '30px',
          justifyContent: 'center',
          py: '20px',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          onClick={handleCheckout}
          disabled={isNaN(totals?.subTotal) || totals?.subTotal <= 0 ? true : false}
        >
          Checkout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Summary = ({ totals }) => {
  return (
    <Box display='flex' flexDirection={'column'}>
      <Stack direction={'row'} justifyContent='space-between' sx={{ px: '30px', py: '5px' }}>
        <Typography variant='body1' fontSize={'16px'} fontWeight={400}>
          Subtotal
        </Typography>
        <Typography variant='body1' fontSize={'16px'} fontWeight={400}>
          ${totals?.subTotal?.toFixed(2)}
        </Typography>
      </Stack>
      <Stack direction={'row'} justifyContent='space-between' sx={{ px: '30px', py: '5px' }}>
        <Typography variant='body1' fontSize={'16px'} fontWeight={400}>
          Taxes
        </Typography>
        <Typography variant='body1' fontSize={'16px'} fontWeight={400}>
          ${totals?.tax?.toFixed(2)}
        </Typography>
      </Stack>
      <Divider sx={{ my: '10px' }} />
      <Stack direction={'row'} justifyContent='space-between' sx={{ px: '30px' }}>
        <Typography variant='body1' fontWeight={600} fontSize={'22px'}>
          Total
        </Typography>
        <Typography variant='body1' fontWeight={600} fontSize={'22px'}>
          {' '}
          ${totals?.total?.toFixed(2)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BuyMorePhotosDialog;
