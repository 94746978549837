import React from 'react';
import Maintenace from '../../assets/website-maintenance.jpg';
import { Box, Typography } from '@mui/material';

const Maintanence = () => {
  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          margin: 'auto',
          width: '80%',
        }}
      >
        <img src={Maintenace} style={{ height: '360px' }} alt='Under maintenance' />
        <Typography
          align='center'
          variant='h5'
          fontWeight={400}
          sx={{ color: 'primary.black', mt: '0px' }}
        >
          We are updating our website for the new school year and will be back shortly.
        </Typography>
      </Box>
    </>
  );
};

export default Maintanence;
