import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { callGetAPI, callPostAPI, callPutAPI } from '../../../helpers/apiCall';
import { asyncForEach, getParentPhotoFilePath } from '../../../utils/functions';
import { findActiveOrder } from './utils';
import { Axios } from '../../../api/axios';

export const getPhotosThunk = createAsyncThunk('getPhotos/fetch', async (d, { getState }) => {
  let state = getState();

  let childMemberId = state.member?.activeChild?.memberId;
  let memberId = state.member?.member?.id;
  let schoolId = state.member?.activeChild?.schoolId;
  let rosterId = state.member?.activeChild?.rosterId;

  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();

  // Fetch all albums
  let albumResult = {
    albums: [],
  };

  try {
    albumResult = await callGetAPI({ token }, '/member/albums');
  } catch (error) {
    console.log(error);
  }

  let photosReqObj = {
    memberId,
    childMemberId,
    schoolId,
    rosterId,
    token,
  };

  let result = await callPostAPI(photosReqObj, '/photos/get');

  let allPhotosData = {};

  await asyncForEach(Object.keys(result.photos), async (albumTitle) => {
    if (Object.keys(result.photos).includes(albumTitle)) {
      allPhotosData[albumTitle] = [];
      let photos = [
        ...result.photos[albumTitle].freeUploads,
        ...result.photos[albumTitle].purchasedUploads,
      ];

      let photosList = [];
      let photoPromises = photos.map((photo) => {
        if (photo.url) {
          const firebaseApp = getApp();
          const storage = getStorage(
            firebaseApp,
            `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-deziser-sotohp`,
          );
          return getDownloadURL(ref(storage, photo.url)).catch((error) => {
            console.log(`Error retrieving photo URL: ${error}`);
            return null;
          });
        } else {
          return null;
        }
      });

      let photoUrlResult = await Promise.all(photoPromises);
      photoUrlResult.forEach((url, index) => {
        photosList.push({ ...photos[index], url: url });
      });

      allPhotosData[albumTitle] = photosList;
    }
  });

  let totalFreeUploads = 0;
  let totalPurchasedUploads = 0;

  Object.keys(result.photos).forEach((title) => {
    totalFreeUploads += result.photos[title].freeUploads.length;
    totalPurchasedUploads += result.photos[title].purchasedUploads.length;
  });

  return {
    albums: albumResult.albums,
    photos: allPhotosData,
    freeUploadsCount: totalFreeUploads,
    purchasedUploadsCount: totalPurchasedUploads,
    purchasedPhotosCount: result.purchasedPhotosCount,
    freePhotos: result.freePhotos,
    lastPurchaseDate: result.lastPurchaseDate
  };
});

export const uploadPhotosThunk = createAsyncThunk(
  'uploadPhotos/fetch',
  async (d, { getState, rejectWithValue }) => {
    let { list } = d;
    const photoDict = {};

    try {
      let state = getState();

      let member = state.member?.member;
      let activeChild = state.member?.activeChild;
      let currentAlbum = state.myAccount.albums.find(
        (albumObj) => albumObj.title === state.myAccount?.currentAlbum,
      );

      let errorMessage = '';

      let auth = getAuth();
      let token = await auth.currentUser.getIdToken();

      for (const photo of list) {
        let path = getParentPhotoFilePath({
          currentAlbum,
          photoId: photo.photoId,
          member,
          activeChild,
        });

        const formData = new FormData();
        formData.append('filePath', path);
        formData.append('photo', photo.photoFile);
        try {
          const res = await Axios.post('/photos/upload-photo', formData, {
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data',
            },
          });

          if (res.success) {
            photoDict[photo.id] = { success: true };
          } else {
            photoDict[photo.id] = { success: false };
            errorMessage = 'Error while uploading some of the images';
          }
        } catch {
          photoDict[photo.id] = { success: false };
          errorMessage = 'Error while uploading some of the images';
        }
      }

      return { photoDict, errorMessage };
    } catch (error) {
      const photoDict = {};
      for (const photo of list) {
        if (!photoDict[photo.id]) {
          photoDict[photo.id] = { success: false };
        }
      }
      return rejectWithValue({ photoDict });
    }
  },
);

export const updatePhotosThunk = createAsyncThunk('updatePhotos/fetch', async (d, { getState }) => {
  let state = getState();
  let memberId = state.member?.member?.id;
  console.log(state);
  let photoId = d.photoId;
  let toUpdate = d.toUpdate;
  let rosterId = state.member?.activeChild?.rosterId;
  let childMemberId = state.member?.activeChild?.memberId;
  let order = findActiveOrder(state.member);
  let reqObj = {
    owner: {
      type: 'members',
      id: memberId,
    },
    photoId: photoId,
    toUpdate: toUpdate,
    rosterId,
    childMemberId,
    orderId: order.id,
    productId: order.productId,
  };
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPutAPI({ ...reqObj, token }, '/photos');
  console.log(result);
  return result;
});

export const deletePhotosThunk = createAsyncThunk('deletePhotos/fetch', async (d, { getState }) => {
  let state = getState();
  let memberId = state.member?.member?.id;
  let photoId = d.photoId;
  let rosterId = state.member?.activeChild?.rosterId;
  let childMemberId = state.member?.activeChild?.memberId;
  let order = findActiveOrder(state.member);

  let reqObj = {
    owner: {
      type: 'members',
      id: memberId,
    },
    photoId: photoId,
    rosterId,
    orderId: order.id,
    productId: order.productId,
    childMemberId: childMemberId,
  };

  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  await callPostAPI({ ...reqObj, token }, '/delete-photos');

  return { photoId };
});
