import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box } from '@mui/material';
import LogoBlack from '../../components/global/LogoBlack';

import SignUp from './Signup';
import SignIn from './Signin';
import Spinner from '../../components/Spinner';

export default function Auth({ signin }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [isSignIn, setIsSignIn] = useState(signin);
  const [initialLoad, setInitialLoad] = useState(true);

  useLayoutEffect(() => {
    let auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      // Check if error is passed in navigate state
      if (user && location.state && !location.state.hasOwnProperty('error')) {
        navigate('/choose-account');
        // If url is changed to signin manually no state is passed
      } else if (user && !location.state) {
        navigate('/choose-account');
      }
      setInitialLoad(false);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoad) {
    return (
      <Spinner
        open={initialLoad}
        sx={{
          background: 'white',
        }}
      />
    );
  }

  return (
    <Box
      display='flex'
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      flexDirection='column'
      alignItems={'center'}
      height={'100vh'}
    >
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        width={{ sm: '60vw' }}
        py={{ xs: '40px', sm: '30px' }}
        boxShadow={{ xs: '0', sm: '0px 4px 24px 0px rgba(0, 0, 0, 0.04)' }}
        borderRadius={'20px'}
      >
        <Box sx={{ height: '40px' }}>
          <LogoBlack />
        </Box>

        {isSignIn ? <SignIn setIsSignIn={setIsSignIn} /> : <SignUp setIsSignIn={setIsSignIn} />}
      </Box>
    </Box>
  );
}
