import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  getAuth,
} from 'firebase/auth';
import { Axios } from '../api/axios';
import { auth } from '../firebase';
import { callPostAPI } from '../helpers/apiCall';
import { createTempRosterThunk, parentAssociationThunk } from './services/member/plan.services';

export const signInWithGmailThunk = createAsyncThunk('member/fetch', async (data) => {
  var provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

  provider.setCustomParameters({
    prompt: 'select_account',
  });
  let result = await signInWithPopup(auth, provider);
  console.log(result);
  let token = await result.user.getIdToken();
  try {
    let user = await Axios.get('/signInWithGoogle', {
      headers: {
        Authorization: token,
      },
    });
    console.log('user', user);
    if (!user.id) {
      return { error: true, message: user.message || '' };
    } else {
      return {
        user: result.user.toJSON(),
        member: user,
        accessToken: await result.user.getIdToken(),
      };
    }
  } catch (error) {
    return { error: true, message: error.message || '' };
  }
});

export const signUpThunk = createAsyncThunk(
  'signUp/fetch',
  async ({ firstName, lastName, email, password }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      let token = await userCredential.user.getIdToken();
      try {
        let user = await Axios.post(
          '/signup',
          {
            firstName: firstName,
            lastName: lastName,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
        if (!user.id) {
          return { error: true, message: user.message || '' };
        } else {
          return {
            user: userCredential.user.toJSON(),
            member: user,
          };
        }
      } catch (error) {
        return { error: true, message: error.message || '' };
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        let accountStatusRes = await Axios.post('/account-status', { email });
        return { error: true, message: accountStatusRes.message };
      }
      return { error: true, message: getErrorMessage(error.code) };
    }
  },
);

export const getOrderStatusThunk = createAsyncThunk(
  'getOrderStatus/fetch',
  async ({ memberId }) => {
    let token = await auth.currentUser.getIdToken();
    let orders = await callPostAPI({ token, memberId: memberId }, '/get-order-status');
    return orders.orders;
  },
);

export const signInThunk = createAsyncThunk('signIn/fetch', async (d) => {
  try {
    await setPersistence(auth, browserSessionPersistence);
    const userCredential = await signInWithEmailAndPassword(auth, d.email, d.password);
    let token = await userCredential.user.getIdToken();
    try {
      let user = await Axios.get('/signin', {
        headers: {
          Authorization: token,
        },
      });

      if (!user.id) {
        return { error: true, message: user.message || '' };
      } else {
        let orders = await callPostAPI({ token, memberId: user.id }, '/get-order-status');
        return {
          user: userCredential.user.toJSON(),
          member: user,
          type: d.type,
          orders: orders.orders,
        };
      }
    } catch (error) {
      return { error: true, message: error.message || '' };
    }
  } catch (error) {
    return { error: true, message: getErrorMessage(error.code) };
  }
});

export const addUserActivityThunk = createAsyncThunk(
  'addActivity/fetch',
  async (state, { getState }) => {
    let auth = getAuth();
    let allState = getState();
    let token = await auth.currentUser.getIdToken();
    try {
      await callPostAPI(
        {
          type: 'select-child',
          info: {
            firstName: state.firstName,
            lastName: state.lastName,
            middleName: state.middleName,
            rosterId: allState?.member?.activeChild?.rosterId || '',
            schoolId: allState?.member?.activeChild?.schoolId || allState?.schoolCode?.school?.id || '',
            token,
          },
        },
        '/user-activity',
      );
    } catch (error) {
      return { error: true, message: error.message || '' };
    }
  },
);

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    member: {},
    user: {},
    activeChild: { status: false },
    activeOrder: {},
    orders: [],
    callOrderStatus: true,
    navigate: '',
    relation: 0,
    isLoading: false,
    isSuccess: false,
    error: {
      status: false,
      message: '',
    },
  },
  reducers: {
    updateMember: (state, action) => {
      state.member = action.payload.member;
    },
    setActiveChild: (state, action) => {
      state.activeChild = { ...action.payload, status: true };
    },
    resetActiveChild: (state, _) => {
      state.activeChild = { status: false };
    },
    setActiveOrder: (state, action) => {
      state.activeOrder = { ...action.payload, status: true };
    },
    updateRelation: (state, action) => {
      state.relation = action.payload.relation;
    },
    updateActiveChild: (state, action) => {
      state.activeChild = { ...state.activeChild, ...action.payload };
      if (state.member?.info && action.payload.info) {
        state.member.info[state.activeChild.rosterId] = {
          ...state.member.info[state.activeChild.rosterId],
          ...action.payload.info,
        };
      }
    },
    resetState: (state, action) => {
      state.isSuccess = false;
      state.activeChild = {};
      state.navigate = '';
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithGmailThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(signInWithGmailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.error.status = false;
          state.member = action.payload.member;
          state.user = action.payload.user;
        }
        console.log(state.member);
      })
      .addCase(signInWithGmailThunk.rejected, (state, action) => {
        console.log('error', action);
      })
      .addCase(signUpThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(signUpThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.error.status = false;
          state.user = action.payload.user;
          state.member = action.payload.member;
          state.navigate = '/choose-account';
        }
      })
      .addCase(signUpThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(signInThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.error) {
          state.error.status = true;
          state.error.message = action.payload.message;
        } else {
          state.error.status = false;
          state.error.message = '';
          state.member = action.payload.member;
          state.callOrderStatus = false;

          let orders = action.payload.orders;
          state.orders = orders;
          state.navigate = '/choose-account';
        }
      })
      .addCase(signInThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
      })
      .addCase(parentAssociationThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(parentAssociationThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        let response = action.payload;
        if (response.success) {
          state.navigate = response.isSchoolBuy ? '/parent/photos' : '/order-summary';
          state.member = response.member;
          state.isSuccess = true;
        } else {
          state.error.status = true;
          state.error.message = response.message || 'Something went wrong';
        }
      })
      .addCase(parentAssociationThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
      })
      .addCase(createTempRosterThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
        state.activeChild.status = false;
      })
      .addCase(createTempRosterThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        let response = action.payload;
        if (response.success) {
          state.navigate = response.isSchoolBuy ? '/parent/photos' : '/order-summary';
          state.member = response.member;
          state.isSuccess = true;
        } else {
          state.error.status = true;
          state.error.message = response.message || 'Something went wrong';
        }
      })
      .addCase(createTempRosterThunk.rejected, (state, action) => {
        console.log('error', action);
      })
      .addCase(getOrderStatusThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getOrderStatusThunk.fulfilled, (state, action) => {
        // console.log("index", action.payload);
        state.isLoading = false;
        state.orders = action.payload;

        state.callOrderStatus = false;
      })
      .addCase(getOrderStatusThunk.rejected, (state, action) => {
        console.log('error', action);
        state.isLoading = false;
      });
  },
});

export const member = (state) => state.member;
export const {
  updateMember,
  setActiveChild,
  resetActiveChild,
  updateRelation,
  resetState,
  setActiveOrder,
  updateActiveChild,
} = memberSlice.actions;
export default memberSlice.reducer;

export function getErrorMessage(response) {
  let newErrMsg = '';
  if (response === 'auth/email-already-in-use') {
    newErrMsg = 'Email already in use by someone. Please try a different email';
  } else if (response === 'auth/invalid-email') {
    newErrMsg = 'Invalid email address. Please enter a valid email address';
  } else if (response === 'auth/operation-not-allowed') {
    newErrMsg = 'Something went wrong. Please try again';
  } else if (response === 'auth/weak-password') {
    newErrMsg = 'Password is not strong enough. Please try another password';
  } else if (response === 'auth/user-not-found') {
    newErrMsg = 'No user found with this email. Please check your email';
  } else if (response === 'auth/user-disabled') {
    newErrMsg = " Please check your email to activate your account with the link we've sent";
  } else if (response === 'auth/wrong-password') {
    newErrMsg = 'Password is incorrect. Please check your password';
  } else {
    newErrMsg = 'Something went wrong. Please try again';
  }
  return newErrMsg;
}
