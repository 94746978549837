import React, { useState } from 'react';
import { Box, Dialog, IconButton, Slide, Grid, Typography, Button } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Slider from 'react-slick';
import CloseIcon from '@mui/icons-material/Close';
import familyMemories1 from '../../../assets/sample-pages/family-memories1.jpg';
import familyMemories2 from '../../../assets/sample-pages/family-memories2.jpg';
import familyMemories3 from '../../../assets/sample-pages/family-memories3.jpg';
import personalMemories1 from '../../../assets/sample-pages/personal-memories1.jpg';
import personalNotes1 from '../../../assets/sample-pages/personal-notes1.jpg';
import schoolPhotos1 from '../../../assets/sample-pages/school-photo1.jpg';
import schoolPhotos2 from '../../../assets/sample-pages/school-photo2.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const samplePages = [
  familyMemories1,
  familyMemories2,
  familyMemories3,
  personalMemories1,
  personalNotes1,
  schoolPhotos1,
  schoolPhotos2,
];

const SampleNextArrow = (props) => {
  const { onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        color: 'primary.main',
        top: '50%',
        right: '-15px',
        zIndex: 2,
        bgcolor: 'white',
        ':hover': {
          bgcolor: 'white',
        },

        filter: 'drop-shadow(1px 1px 5px #00000055)',
      }}
    >
      <ArrowForward />
    </IconButton>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        color: 'primary.main',
        top: '50%',
        left: '-15px',
        zIndex: 2,
        bgcolor: 'white',
        ':hover': {
          bgcolor: 'white',
        },
        filter: 'drop-shadow(1px 1px 5px #00000055)',
      }}
    >
      <ArrowBack />
    </IconButton>
  );
};

const SamplePageDialog = ({ open, handleClose, initialSlideFor }) => {
  const [showSamples, setShowSamples] = useState(false);

  const getInitialSlide = () => {
    switch (initialSlideFor) {
      case 'FAMILY_MEMORIES':
        return 0;
      case 'PERSONAL_MEMORIES':
        return 3;
      case 'PERSONAL_NOTES':
        return 4;
      case 'SCHOOL_PHOTOS':
        return 5;
      default:
        return 0;
    }
  };

  const slickSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 360,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: getInitialSlide(),
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth={'md'}
        fullWidth={true}
        sx={{
          zIndex: 999999,
          height: '100vh',
        }}
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          container
          sx={{
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Grid item container direction='row' xs={10} justifyContent={'center'}>
            <Box sx={{ width: { xs: 'min(64vw, calc(90vh - 40px))', lg: 'min(44vw, calc(90vh - 40px))' }, mt: '20px', mb: '20px' }}>
              {showSamples ? (
                <Slider {...slickSettings}>
                  {samplePages.map((image, i) => (
                    <Box key={i} sx={{ width: '100%' }}>
                      <img
                        src={image}
                        alt='Loading...'
                        style={{
                          width: '100%',
                          aspectRatio: 1,
                          objectFit: 'contain',
                          borderRadius: '10px',
                          margin: 'auto',
                        }}
                      />
                    </Box>
                  ))}
                </Slider>
              ) : (
                <Box
                  sx={{
                    m: 'auto',
                    width: '100%',
                    height: '100%',
                    px: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '16px',
                      lineHeight: 1.44,
                    }}
                  >
                    Please note that the pages displayed intend to give you an idea of the layout,
                    design, and quality of our yearbooks. The actual content will be customized
                    based on the photos and information provided by your child's school. We create a
                    beautiful and memorable yearbook for every child, but the representation here is
                    a guide and not a precise depiction of the final yearbook.
                    {showSamples}
                  </Typography>
                  <Button
                    variant='contained'
                    sx={{ mt: 8, fontSize: 16, width: 180 }}
                    onClick={() => setShowSamples(true)}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default SamplePageDialog;
