import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ImageList, InputBase, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { createMemoriesNotesThunk, updateMemoriesNotesThunk } from '../../../../../../reducers/parentReducer';

const PersonalizeMemoryDialog = ({ open, handleClose, memory, type }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [selectedMemories, setSelectedMemories] = useState([]);

    const getOptions = () => {
        const selectedMemoryIds = selectedMemories?.map(m => m.id) || [];
        return memory?.options.filter((p) => !selectedMemoryIds.includes(p.id)) || [];
    };

    const toggleSelectedMemory = (memory) => {
        const index = selectedMemories.findIndex(m => m.id === memory.id);

        if (index > -1) {
            setSelectedMemories((prev) => (prev.filter(m => m.id !== memory.id)));
        } else {
            setSelectedMemories((prev) => ([...prev, { id: memory.id, title: memory.title, type: "SYSTEM" }]));
        }
    }

    const handleAddMyOwn = (memoryTitle) => {
        setSelectedMemories((prev) => ([...prev, { id: uuidv4(), title: memoryTitle, type: 'CUSTOM' }]));
    }

    const handleAddEditPersonalizedMemory = async () => {
        if (type === 'ADD') {
            const data = {
                id: memory.id,
                memories: selectedMemories,
                icon: memory.icon,
                memoryType: 'SYSTEM',
                memoryKey: memory.pKey,
                title: memory.title
            }
            await dispatch(createMemoriesNotesThunk({ data, type: 'MEMORY' }));
        } else {
            const data = {
                memories: selectedMemories
            }
            await dispatch(updateMemoriesNotesThunk({ memoryId: memory.id, data, type: 'MEMORY' }));
        }
        handleClose();
    }

    useEffect(() => {
        if (memory.memories) {
            setSelectedMemories(memory.memories);
        }
    }, [memory]);

    return (
        <Dialog
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
            sx={{
                '& .MuiPaper-root': {
                    m: { xs: '12px', sm: '32px' },
                },
            }}
        >
            <DialogTitle sx={{ px: '30px' }}>
                <Box>
                    <Typography variant='h5' fontWeight={500}>
                        {memory.title}
                    </Typography>
                    <Typography variant='body2'>Select from the below options</Typography>
                </Box>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close sx={{ fontSize: '18px' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Box
                    sx={{
                        maxWidth: '100vw',
                        height: { xs: '100vh', sm: '450px' },
                        bgcolor: 'white',
                        px: '30px',
                    }}
                >
                    <ImageList variant='standard' cols={isSmallScreen ? 2 : 4} gap={12}>
                        {getOptions().map((option, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    borderRadius: '16px',
                                }}
                                onClick={() => toggleSelectedMemory(option)}
                            >
                                <Box
                                    component={'img'}
                                    src={option.src}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '16px',
                                    }}
                                    alt='Memory'
                                />
                            </Box>
                        ))}
                    </ImageList>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    px: '20px',
                    justifyContent: 'space-between',
                    pb: '12px',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    '&.MuiDialogActions-root': {
                        boxShadow: '12px -4px 34px 0px rgba(0, 0, 0, 0.08)',
                    },
                }}
            >
                <Box
                    display={'flex'}
                    flexWrap='nowrap'
                    alignContent={'flex-start'}
                    sx={{
                        overflowY: 'auto',
                        width: '100%',
                        mb: '8px',
                        height: '35px',
                    }}
                >
                    {selectedMemories.map((memory, i) => (
                        <Chip
                            key={i}
                            label={memory.title}
                            onDelete={() => toggleSelectedMemory(memory)}
                            sx={{ mr: '12px' }}
                        />
                    ))}
                </Box>

                <Stack
                    direction={'row'}
                    alignItems='center'
                    justifyContent={'space-between'}
                    sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '10px' }}
                    width={'100%'}
                >
                    <AddYourOwn handleAddMyOwn={handleAddMyOwn} />
                    <Button
                        variant='contained'
                        disabled={selectedMemories.length === 0}
                        onClick={handleAddEditPersonalizedMemory}
                    >
                        Save
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

const AddYourOwn = ({ handleAddMyOwn }) => {
    const [myOwn, setMyOwn] = useState('');

    return (
        <Box
            sx={{
                p: '4px 4px',
                m: '0',
                border: 'solid 1px #DADADA',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
            }}
        >
            <InputBase
                autoFocus
                sx={{ ml: 1 }}
                placeholder='Enter your own'
                inputProps={{ 'aria-label': 'search google maps' }}
                value={myOwn}
                onChange={(e) => setMyOwn(e.target.value)}
            />
            <Button
                variant='text'
                disabled={!myOwn}
                sx={{
                    bgcolor: 'transparent',
                    border: '1px solid #0065CC',
                    color: '#0065CC',
                    width: 'min-content',
                    ':hover': {
                        bgcolor: '#0065CC',
                        color: 'white',
                    },
                }}
                onClick={() => {
                    if (myOwn) {
                        handleAddMyOwn(myOwn);
                        setMyOwn('');
                    }
                }}
            >
                Add
            </Button>
        </Box>
    );
};

export default PersonalizeMemoryDialog;