import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../api/axios';

import {
  getSchoolOrderDetails,
  getSchoolList,
  getAlbumsList,
  getAlbumPhotosList,
  getPeoplePhotosList,
  checkInStudent,
  checkOutStudent,
  moveStudentGroup,
  likePeopleSchoolPhoto,
  deletePeopleSchoolPhoto,
  likeAlbumsSchoolPhoto,
  deleteAlbumsSchoolPhoto,
  createGDriveFolder,
} from './services/schoolData';

export const getStudentsList = createAsyncThunk('fetch/student-list', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const schoolId = state.schoolData?.schoolHome?.schoolId;
    const response = await Axios.post('/roster/all', { schoolId });
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRoomsList = createAsyncThunk('fetch/room-list', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const schoolId = state.schoolData?.schoolHome?.schoolId;
    const response = await Axios.post('/groups/all', { schoolId });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getSchoolHomeDetails = createAsyncThunk('fetch/school-home', async (d, thunkAPI) => {
  try {
    const { id: paramsSchoolId, associationId } = d;
    const state = thunkAPI.getState();
    const schoolId = paramsSchoolId || state.member?.activeChild?.schoolId;
    const response = await Axios.post('/school/details', {
      schoolId,
    });
    return { ...response, associationId };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const MYVITYWORLD_DATA = [
  {
    title: 'Title 1',
    imgSrc: 'https://picsum.photos/id/237/200/300',
    type: 'Avatar',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
  {
    title: 'Title 2',
    imgSrc: 'https://picsum.photos/id/238/200/300',
    type: 'Poster',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
  {
    title: 'Title 3',
    imgSrc: 'https://picsum.photos/id/239/200/300',
    type: 'Avatar',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
  {
    title: 'Title 4',
    imgSrc: 'https://picsum.photos/id/240/200/300',
    type: 'Avatar',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
  {
    title: 'Title 5',
    imgSrc: 'https://picsum.photos/id/241/200/300',
    type: 'Poster',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
  {
    title: 'Title 6',
    imgSrc: 'https://picsum.photos/id/242/200/300',
    type: 'Poster',
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took ",
  },
];

const initialState = {
  isLoading: false,
  orderHistory: { data: [], loading: false },
  schoolList: { data: [], loading: false },
  schoolAlbums: { data: [], loading: false },
  photos: {
    albums: {
      title: '',
      data: [],
      loading: false,
      noData: false,
    },
    people: {
      title: '',
      data: [],
      loading: false,
      noData: false,
    },
  },
  studentsList: {
    data: [],
    loading: false,
  },
  roomsList: {
    data: [],
    loading: false,
  },
  schoolHome: {},
  myVityWorld: {
    loading: false,
    data: MYVITYWORLD_DATA,
  },
  error: {
    status: false,
    message: '',
  },
};

export const schoolRegisterSlice = createSlice({
  name: 'schoolData',
  initialState,
  reducers: {
    updateAlbumTitle: (state, action) => {
      let data = action.payload.title;
      state.photos.albums.title = data;
    },
    updatePeopleTitle: (state, action) => {
      let data = action.payload.title;
      state.photos.people.title = data;
    },
    updateStudentDetails: (state, action) => {
      let newStudentList = [...state.studentsList.data];
      let studentIdx = newStudentList.findIndex(
        (student) => student.rosterId === action.payload.rosterId,
      );
      if (studentIdx > -1) {
        newStudentList[studentIdx] = {
          ...newStudentList[studentIdx],
          ...action.payload.toUpdate,
        };
        state.studentsList.data = newStudentList;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentsList.pending, (state) => {
        state.studentsList.loading = true;
      })
      .addCase(getStudentsList.fulfilled, (state, action) => {
        state.studentsList.loading = false;
        state.studentsList.data = action.payload;
      })
      .addCase(getStudentsList.rejected, (state) => {
        state.studentsList.loading = false;
      })
      .addCase(getRoomsList.pending, (state) => {
        state.roomsList.loading = true;
      })
      .addCase(getRoomsList.fulfilled, (state, action) => {
        state.roomsList.loading = false;
        state.roomsList.data = action.payload;
      })
      .addCase(getRoomsList.rejected, (state) => {
        state.roomsList.loading = false;
      })
      .addCase(getSchoolHomeDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSchoolHomeDetails.fulfilled, (state, action) => {
        let { success, associationId, ...data } = action.payload;
        if (success) {
          delete data.message;
          state.schoolHome = { ...data, schoolId: data.school.id, associationId };
        }
        state.isLoading = false;
      })
      .addCase(getSchoolHomeDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSchoolOrderDetails.pending, (state) => {
        state.orderHistory.loading = true;
      })
      .addCase(getSchoolOrderDetails.fulfilled, (state, action) => {
        let { success, purchases } = action.payload;
        if (success) {
          // Sort by name in asce
          purchases.sort((a, b) => {
            if (a.info?.firstName.toUpperCase() > b.info?.firstName.toUpperCase()) return 1;
            if (a.info?.firstName.toUpperCase() < b.info?.firstName.toUpperCase()) return -1;
            return 0;
          });
          state.orderHistory.data = purchases;
        }
        state.orderHistory.loading = false;
      })
      .addCase(getSchoolOrderDetails.rejected, (state) => {
        state.orderHistory.loading = false;
      })
      .addCase(getSchoolList.pending, (state) => {
        state.schoolList.loading = true;
      })
      .addCase(getSchoolList.fulfilled, (state, action) => {
        let { success, schools } = action.payload;
        if (success) {
          state.schoolList.data = schools;
        }
        state.schoolList.loading = false;
      })
      .addCase(getSchoolList.rejected, (state) => {
        state.schoolList.loading = false;
      })
      .addCase(getAlbumsList.pending, (state) => {
        state.schoolAlbums.loading = true;
      })
      .addCase(getAlbumsList.fulfilled, (state, action) => {
        let { success, albums } = action.payload;
        if (success) {
          state.schoolAlbums.data = albums;
        }
        state.schoolAlbums.loading = false;
      })
      .addCase(getAlbumsList.rejected, (state) => {
        state.schoolAlbums.loading = false;
      })
      .addCase(getAlbumPhotosList.pending, (state) => {
        state.photos.albums.loading = true;
      })
      .addCase(getAlbumPhotosList.fulfilled, (state, action) => {
        let { success, photos } = action.payload;
        if (success) {
          if (photos.length > 0) {
            state.photos.albums.data = photos;
          } else {
            state.photos.albums.data = [];
            state.photos.albums.noData = true;
          }
        }
        state.photos.albums.loading = false;
      })
      .addCase(getAlbumPhotosList.rejected, (state) => {
        state.photos.albums.loading = false;
      })
      .addCase(getPeoplePhotosList.pending, (state) => {
        state.photos.people.loading = true;
      })
      .addCase(getPeoplePhotosList.fulfilled, (state, action) => {
        let { photos } = action.payload;
        if (photos.length > 0) {
          state.photos.people.data = photos;
        } else {
          state.photos.people.data = [];
          state.photos.people.noData = true;
        }
        state.photos.people.loading = false;
      })
      .addCase(getPeoplePhotosList.rejected, (state) => {
        state.photos.people.loading = false;
      })
      .addCase(checkInStudent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkInStudent.fulfilled, (state, action) => {
        let { success, rosterId, groupId, message } = action.payload;
        if (success) {
          let studentIdx = state.studentsList.data.findIndex((student) => student.id === rosterId);
          state.studentsList.data[studentIdx] = {
            ...state.studentsList.data[studentIdx],
            checkedInGroupId: groupId,
            isCheckedOut: false,
          };
        } else {
          state.error.status = true;
          state.error.message = message;
        }
        state.isLoading = false;
      })
      .addCase(checkInStudent.rejected, (state) => {
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(checkOutStudent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkOutStudent.fulfilled, (state, action) => {
        let { success, rosterId, message } = action.payload;
        if (success) {
          let studentIdx = state.studentsList.data.findIndex((student) => student.id === rosterId);
          state.studentsList.data[studentIdx] = {
            ...state.studentsList.data[studentIdx],
            isCheckedOut: true,
            checkedInGroupId: null,
          };
        } else {
          state.error.status = true;
          state.error.message = message;
        }
        state.isLoading = false;
      })
      .addCase(checkOutStudent.rejected, (state) => {
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(moveStudentGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(moveStudentGroup.fulfilled, (state, action) => {
        let { success, rosterId, checkedInGroupId, message } = action.payload;

        if (success) {
          let studentIdx = state.studentsList.data.findIndex((student) => student.id === rosterId);
          state.studentsList.data[studentIdx] = {
            ...state.studentsList.data[studentIdx],
            checkedInGroupId: checkedInGroupId,
          };
        } else {
          state.error.status = true;
          state.error.message = message;
        }
        state.isLoading = false;
      })
      .addCase(moveStudentGroup.rejected, (state) => {
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(likePeopleSchoolPhoto.pending, (state) => {
        // like Loading
      })
      .addCase(likePeopleSchoolPhoto.fulfilled, (state, action) => {
        let { message, likedPhotos } = action.payload;
        if (message === 'success') {
          // Update like status locally
          state.photos.people.data = state.photos.people.data.map((photoObj) => {
            const photoIdx = likedPhotos.findIndex((obj) => obj.id === photoObj.id);
            const likeStatus =
              photoIdx !== -1 ? likedPhotos[photoIdx].like : photoObj.like || false;
            return {
              ...photoObj,
              like: likeStatus,
            };
          });
        }
      })
      .addCase(likePeopleSchoolPhoto.rejected, (state) => {
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(deletePeopleSchoolPhoto.pending, (state) => {
        // delete Loading
      })
      .addCase(deletePeopleSchoolPhoto.fulfilled, (state, action) => {
        let { message, deletedPhotos } = action.payload;
        if (message === 'success') {
          // delete photos locally
          state.photos.people.data = state.photos.people.data.filter((photoObj) => {
            const photoIdx = deletedPhotos.findIndex((id) => id === photoObj.id);
            return photoIdx === -1 ? true : false;
          });
        }
      })
      .addCase(deletePeopleSchoolPhoto.rejected, (state) => {
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(likeAlbumsSchoolPhoto.pending, (state) => {
        // like Loading
      })
      .addCase(likeAlbumsSchoolPhoto.fulfilled, (state, action) => {
        let { message, likedPhotos } = action.payload;
        if (message === 'success') {
          // Update like status locally
          state.photos.albums.data = state.photos.albums.data.map((photoObj) => {
            const photoIdx = likedPhotos.findIndex((obj) => obj.id === photoObj.id);
            const likeStatus =
              photoIdx !== -1 ? likedPhotos[photoIdx].like : photoObj.like || false;
            return {
              ...photoObj,
              like: likeStatus,
            };
          });
        }
      })
      .addCase(likeAlbumsSchoolPhoto.rejected, (state) => {
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(deleteAlbumsSchoolPhoto.pending, (state) => {
        // delete Loading
      })
      .addCase(deleteAlbumsSchoolPhoto.fulfilled, (state, action) => {
        let { message, deletedPhotos } = action.payload;
        if (message === 'success') {
          // delete photos locally
          state.photos.albums.data = state.photos.albums.data.filter((photoObj) => {
            const photoIdx = deletedPhotos.findIndex((id) => id === photoObj.id);
            return photoIdx === -1 ? true : false;
          });
        }
      })
      .addCase(deleteAlbumsSchoolPhoto.rejected, (state) => {
        state.error.status = true;
        state.error.message = 'Something went wrong';
      })
      .addCase(createGDriveFolder.pending, (state) => {
        // Loading
      })
      .addCase(createGDriveFolder.fulfilled, (state, action) => {
        let { success, folderLink } = action.payload;
        if (success) {
          state.schoolHome.school.gDriveFolder = folderLink;
        }
      })
      .addCase(createGDriveFolder.rejected, (state) => {
        state.error.status = true;
        state.error.message = 'Something went wrong';
      });
  },
});

export const { updateAlbumTitle, updatePeopleTitle, updateStudentDetails } =
  schoolRegisterSlice.actions;

export default schoolRegisterSlice.reducer;
