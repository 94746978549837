import React from 'react';
import { Box, IconButton, ImageList, Slide, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const SelectedSchoolPhotos = ({ open, handleClose, photos, width }) => {
  return (
    <Slide direction='right' in={open} key={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: width,
          height: '100vh',
          zIndex: 9999,
          bgcolor: 'rgb(249,249,249)',
          overflowY: 'auto',
          p: 0,
        }}
      >
        <IconButton sx={{ position: 'absolute', top: 8, right: 4 }} onClick={handleClose}>
          {' '}
          <CloseIcon />
        </IconButton>

        <Typography align='center' sx={{ mt: 6, fontSize: 16, fontWeight: 500 }}>
          Selected Photos{' '}
        </Typography>

        <Box sx={{ px: 2, pt: 2 }}>
          {!photos.filter((p) => p.isLiked).length && (
            <Typography sx={{ fontSize: 14, lineHeight: '18px', color: 'GrayText' }} align='center'>
              You have not selected any photo yet.
            </Typography>
          )}
          <ImageList variant='masonry' cols={2} sx={{ mt: 1, px: 2 }}>
            {photos
              .filter((p) => p.isLiked)
              .map((photo, i) => (
                <Box key={i}>
                  <img
                    srcSet={photo.photoUrl}
                    src={photo.photoUrl}
                    loading='lazy'
                    alt='School Photos'
                    style={{
                      width: '100%',
                      aspectRatio: 1,
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
          </ImageList>
        </Box>
      </Box>
    </Slide>
  );
};
