import React, { useCallback, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  useMediaQuery,
  Button,
} from '@mui/material';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import LogoBlack from '../../components/global/LogoBlack';
import ParentAccountHome from './ParentAccountHome';
import ParentAccountPersonalPhotos from './ParentAccountPersonalPhotos';
import ParentAccountSchoolMemories from './ParentAccountSchoolMemories';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import FamilyMemories from '@mui/icons-material/Diversity1Outlined';
import SchoolMemories from '@mui/icons-material/AccountBalanceOutlined';

import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import SamplePageDialog from './components/SamplePageDialog';
import ParentChildren from './components/ParentChildren';
import { getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { updateMember } from '../../reducers/memberReducer';
import { resetApisState } from '../../reducers/myAccountReducer';
import { Logout } from '@mui/icons-material';

const drawerWidth = 240;
const parentAccountSideMenu = [
  {
    id: 'parent-account',
    label: 'My Yearbook',
    path: '/parent-account',
    icon: <StarOutlineOutlinedIcon />,
  },
  {
    id: 'parent-account-personal-photos',
    label: 'Family Photos',
    path: '/parent-account/personal-photos',
    icon: <FamilyMemories />,
  },
  {
    id: 'parent-account-school-memories',
    label: 'School Memories',
    path: '/parent-account/school-memories',
    icon: <SchoolMemories />,
  },
];

const ParentAccountWrapper = ({
  samplePagesDialog,
  handleToggleSamplePagesDialog
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen((prev) => !prev);
    }
  };

  const handleSignOut = useCallback(async () => {
    const auth = getAuth();
    await auth.signOut();
    dispatch(updateMember({ member: {} }));
    dispatch(resetApisState());
    navigate('/signin');
    localStorage.clear();
  }, [dispatch, navigate]);

  const drawer = (
    <div style={{ marginLeft: '20px' }}>
      <Box sx={{ width: '108px', my: 4, mx: 'auto' }}>
        <LogoBlack />
      </Box>
      <ParentChildren />
      <List
        sx={{
          pt: '30px',
          height: '100%',
          maxHeight: '100%',
          overflowY: 'auto',
          '& .Mui-selected': {
            bgcolor: 'transparent',
          },
        }}
      >
        {parentAccountSideMenu.map((item) => (
          <ListItem
            key={item.label}
            disablePadding
            sx={{
              p: 0,
              mt: '20px',
              bgcolor: location.pathname === item.path ? 'rgba(12,12,12,0.09)' : 'inherit',
              width: '190px',
              borderRadius: 45,
              minHeight: 32,
              height: 40,
            }}
          >
            <ListItemButton
              disableRipple
              onClick={() => {
                setMobileOpen(false);
                navigate(item.path);
              }}
              sx={{
                '&:hover': {
                  borderRadius: '45px',
                  minHeight: 32,
                  height: 40
                },
                '&.active': {
                  bgcolor: 'rgba(12,12,12,0.09)',
                  color: '#1976d2',
                  '& .MuiListItemIcon-root': { color: '#1976d2' },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '36px',
                  color: 'rgb(18,18,18)',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: 'rgb(18,18,18)',
                  letterSpacing: -0.36,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: 'flex' }}>
        <Button
          sx={{
            mx: 'auto',
            borderRadius: '10px',
            border: '1px solid rgba(0,0,0,0.2)',
            color: 'black',
          }}
          endIcon={
            <Logout
              sx={{
                display: 'block',
                mx: 'auto',
                fontSize: '18px',
              }}
            />
          }
          onClick={handleSignOut}
        >
          <Typography
            variant={isSmallScreen ? 'caption' : 'body2'}
            sx={{
              width: 'max-content',
            }}
          >
            Sign Out
          </Typography>
        </Button>
      </Box>
    </div>
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position='fixed'
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: '#fff'
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mx: 1,
              display: { sm: 'none' },
              justifyContent: 'start',
              width: 'max-content'
            }}
          >
            <MenuIcon sx={{ color: '#000' }} />
          </IconButton>
        </AppBar>
        <Box
          component='nav'
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label='mailbox folders'
        >
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                bgcolor: 'rgb(243,244,245)',
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant='permanent'
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                border: 'none',
                bgcolor: 'rgb(249,249,249)',
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component='main' sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          <Outlet />
        </Box>

        <SamplePageDialog
          open={samplePagesDialog.open}
          handleClose={() => handleToggleSamplePagesDialog(false, 0)}
          initialSlideFor={samplePagesDialog.initialSlideFor}
        />
      </Box>
    </>
  );
};

const ParentAccount = () => {
  const [samplePagesDialog, setSamplePagesDialog] = useState({ open: false, initialSlideFor: 'FAMILY_MEMORIES' });

  const handleToggleSamplePagesDialog = (open, initialSlideFor) => {
    setSamplePagesDialog({ open, initialSlideFor });
  };

  return (
    <Routes>
      <Route
        element={
          <ParentAccountWrapper
            samplePagesDialog={samplePagesDialog}
            handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
          />
        }
      >
        <Route index element={<ParentAccountHome
          handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
        />} />
        <Route
          path='/personal-photos'
          element={
            <ParentAccountPersonalPhotos
              handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
            />
          }
        />
        <Route
          path='/school-memories'
          element={
            <ParentAccountSchoolMemories
              handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default ParentAccount;
