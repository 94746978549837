import React, { useEffect, useState } from 'react';
import { Alert, Box, Collapse, IconButton, InputAdornment, InputBase, Snackbar, Stack, Typography } from '@mui/material';
import { Visibility, VisibilityOff, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import NextArrowButton from '../../components/global/NextArrowButton';
import { hasLowerCase, hasNumber, hasUpperCase } from '../../utils/functions';
import { Axios } from '../../api/axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';

const AccountActivation = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState('error');
    const [isAccountAlreadyActivated, setIsAccountAlreadyActivated] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const isValidToActivate = () => {
        if (!firstName || !lastName || password.length < 8) {
            return false;
        }
        return true;
    }
    const handleAccountActivation = async () => {
        if (!isValidToActivate()) { return; }

        try {
            setIsLoading(true);
            let response = await Axios.post('/account-activation', {
                password,
                uid: searchParams.get('uid'),
                token: searchParams.get('token'),
                firstName,
                lastName
            });

            setIsLoading(false);
            if (response.success) {
                navigate('/signin', { state: { fromAccountActivation: true } });
            } else {
                setMessageType("error");
                setMessage(response?.message || "Something went wrong, please try again later");
                setOpen(true);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setMessageType("error");
            setMessage(error?.response?.data?.message || "Something went wrong, please try again later");
            setOpen(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setTimeout(() => {
            setMessage("");
        }, 100);
    };

    useEffect(() => {
        const uid = searchParams.get('uid');
        const token = searchParams.get('token');
        if (!uid || !token) {
            navigate('/');
        }
        Axios.post('/account-status/uid', { uid }).then((accountStatusRes) => {
            if (!accountStatusRes.isAccountDisabled) {
                setIsAccountAlreadyActivated(true)
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })

        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Spinner open={isLoading} sx={{ background: 'white' }} />
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh'
            }}>
                <Box sx={{
                    mt: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: "center",
                    p: '30px',
                    height: "100%"
                }}>

                    {isAccountAlreadyActivated ? (
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            marginTop: "5%",

                        }}>
                            <Typography variant='h4' sx={{
                                textAlign: 'center',
                                mb: '15px',
                                fontWeight: 500
                            }} >
                                Your Account is Already Active!
                            </Typography>
                            <Typography variant='body1' sx={{
                                textAlign: 'center',
                                color: "#666",
                                padding: "0rem 2rem",
                                width: "50%",
                                margin: "auto"
                            }} >
                                It looks like you're all set. Your account has already been activated,
                                so there's no need to go through the activation process again.
                                You can directly log in to access your account
                                and personalize your book.
                            </Typography>
                            <NextArrowButton
                                sx={{ margin: "0 auto", marginTop: "100px", width: "10%" }}
                                text={'Login'}
                                handleClick={() => {
                                    navigate('/signin');
                                }}

                            />
                        </Box>
                    ) : (
                        <>
                            <Box>
                                <Typography variant='h4' sx={{
                                    textAlign: 'center',
                                    mb: '15px',
                                    fontWeight: 500
                                }} >
                                    Activate your account
                                </Typography>
                                <Typography variant='body1' sx={{
                                    textAlign: 'center',
                                    color: "#666"
                                }} >
                                    Create a password that you will use to login to your account
                                </Typography>
                            </Box>
                            <Box sx={{ mt: '50px' }}>
                                <Box sx={{
                                    width: { xs: '100%', md: '100%' },
                                    display: 'flex',
                                    justifyContent: 'center'
                                }} >
                                    <InputBase
                                        type='text'
                                        variant='leftborder'
                                        placeholder='First name'
                                        sx={{
                                            width: '100%',
                                            maxWidth: '360px',
                                        }}
                                        autoFocus
                                        field='firstName'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    ></InputBase>
                                </Box>
                                <Box
                                    sx={{
                                        width: { xs: '100%', md: '100%' }, mt: '10px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <InputBase
                                        type='text'
                                        variant='leftborder'
                                        placeholder='Last name'
                                        sx={{
                                            width: '100%',
                                            maxWidth: '360px',
                                        }}
                                        field='lastName'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    ></InputBase>
                                </Box>
                                <Stack sx={{
                                    width: { xs: '100%', md: '100%' },
                                    mt: '30px',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <InputBase
                                        variant='leftborder'
                                        placeholder='Password'
                                        sx={{
                                            width: '100%',
                                            maxWidth: '360px',
                                        }}
                                        autoComplete='off'
                                        type={isVisible ? 'text' : 'password'}
                                        field='password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setIsPasswordFocused(true)}
                                        onBlur={() => setIsPasswordFocused(false)}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={() => setIsVisible((prev) => !prev)}
                                                >
                                                    {isVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <Collapse
                                        in={isPasswordFocused}
                                        sx={{
                                            width: '100%',
                                            maxWidth: '360px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                backgroundColor: 'primary.white',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Box sx={{ p: '10px' }}>
                                                <Box sx={{ mt: '10px' }}>
                                                    <Typography
                                                        variant='body1'
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                        }}
                                                    >
                                                        {password.length >= 8 ? (
                                                            <CheckCircle fontSize='16px' color='success' />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize='16px' />
                                                        )}{' '}
                                                        at least 8 characters in length
                                                    </Typography>
                                                    <Typography
                                                        variant='body1'
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                        }}
                                                    >
                                                        {hasLowerCase(password) ? (
                                                            <CheckCircle fontSize='16px' color='success' />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize='16px' />
                                                        )}{' '}
                                                        lower case letters (ie. a-z)
                                                    </Typography>
                                                    <Typography
                                                        variant='body1'
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                        }}
                                                    >
                                                        {hasUpperCase(password) ? (
                                                            <CheckCircle fontSize='16px' color='success' />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize='16px' />
                                                        )}{' '}
                                                        upper case letters (ie. A-Z)
                                                    </Typography>
                                                    <Typography
                                                        variant='body1'
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                        }}
                                                    >
                                                        {hasNumber(password) ? (
                                                            <CheckCircle fontSize='16px' color='success' />
                                                        ) : (
                                                            <RadioButtonUnchecked fontSize='16px' />
                                                        )}{' '}
                                                        numbers (ie. 0-9)
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Collapse>
                                </Stack>
                                <Box sx={{
                                    width: '100%',
                                    mx: 'auto',
                                    mt: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <NextArrowButton
                                        text={'Activate account'}
                                        handleClick={handleAccountActivation}
                                        disabled={!isValidToActivate()}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default AccountActivation;