import * as faceapi from 'face-api.js';

// Load models and weights
export async function loadModels() {
  const modelLoaded = sessionStorage.getItem('modelsLoaded');
  if (!modelLoaded || modelLoaded === 'false') {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
    await faceapi.nets.faceExpressionNet.loadFromUri('/models');
    await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
    sessionStorage.setItem('modelsLoaded', 'true');
  }
}
