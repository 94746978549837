import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Container from '@mui/material/Container';

import LogoBlack from './LogoBlack';

import { updateMember } from '../../reducers/memberReducer';
import { resetApisState } from '../../reducers/myAccountReducer';

function AuthNavBar({ showSignup = true, showLogin = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const member = useSelector((state) => state.member);

  return (
    <>
      <AppBar
        position='static'
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          margin: '0px',
          position: 'relative',
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box
              display='flex'
              justifyContent={'space-between'}
              flexDirection='row'
              sx={{ width: '100%' }}
            >
              <Box
                sx={{
                  my: '30px',
                  height: { xs: '33px', sm: '36px' },
                  cursor: 'pointer',
                }}
              >
                <LogoBlack />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              ></Box>
              {showLogin && (
                <Button
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    border: 'none',
                    fontSize: { xs: '14px', md: '14px' },
                    color: 'white',
                    textTransform: 'none',
                    px: '8px',
                    py: '0px',
                    minHeight: 0,
                    height: '32px',
                    width: '90px',
                    borderRadius: '20px',
                  }}
                  onClick={async (e) => {
                    if (member.member.id) {
                      const auth = getAuth();
                      await auth.signOut();
                      dispatch(updateMember({ member: {} }));
                      dispatch(resetApisState());
                    }
                    navigate(`/signin`);
                  }}
                >
                  Sign in
                  {/* {member.member.id ? "Sign Out" : "Sign in"} */}
                </Button>
              )}{' '}
              {showSignup && (
                <Button
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    border: 'none',
                    fontSize: { xs: '14px', md: '14px' },
                    color: 'white',
                    textTransform: 'none',
                    px: '8px',
                    py: '0px',
                    minHeight: 0,
                    height: '32px',
                    width: '90px',
                    borderRadius: '20px',
                  }}
                  onClick={(e) => navigate(`/signup`)}
                >
                  Sign Up
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default AuthNavBar;
