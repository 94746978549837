import React from 'react';
import EastIcon from '@mui/icons-material/East';
import Button from '@mui/material/Button';

export default function NextArrowButton({ handleClick, disabled, text, arrow = false, sx }) {
  return (
    <Button onClick={handleClick} variant='contained' disabled={disabled} sx={{ ...sx }}>
      {text}
      {arrow && (
        <EastIcon
          sx={{
            color: disabled ? 'rgba(0,0,0,0.13)' : 'inherit',
            '&:hover': {
              color: 'inherit',
            },
            height: '24px',
          }}
        />
      )}
    </Button>
  );
}
