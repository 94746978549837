import React, { useEffect, useState } from 'react';
import { Box, Fab, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PersonalPhotoItem from './PersonalPhotoItem';
import { Add, KeyboardArrowUp } from '@mui/icons-material';
import { uploadPersonalPhotosThunk } from '../../../../reducers/parentReducer';
import { v4 as uuidv4 } from 'uuid';
import PreschoolArt from '../../../../assets/preschoolArt.jpg';
import PreschoolSoccer from '../../../../assets/preschoolSoccer.jpg';
import PreschoolSwimming from '../../../../assets/preschoolSwimming.jpg';

const PersonalPhotosGrid = ({ handleBuyPhotosWarningDialog, handleBuyMorePhotosDialog }) => {
  const dispatch = useDispatch();
  const { personalPhotos } = useSelector((state) => state.parent);
  const theme = useTheme();
  const isDownToMd = useMediaQuery(theme.breakpoints.down('md'));

  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function isUploadedFilesAllImages(files) {
    return Array.from(files).every((file) => file.type.startsWith('image/'));
  }

  const doesHavePhotosToUpload = (newUploadCount) => {
    if (newUploadCount > personalPhotos.totalRemainingPhotosCount) {
      return false;
    } else {
      return true;
    }
  };

  const handleUploadPersonalPhotos = (e) => {
    e.preventDefault();

    const files = e.target?.files ? [...e.target.files] : [];
    if (files.length > 0) {
      if (isUploadedFilesAllImages(files)) {
        if (doesHavePhotosToUpload(files.length)) {
          let photos = [];
          files.forEach((file) => {
            let photoId = uuidv4();
            photos.push({
              id: photoId,
              caption: '',
              file,
              isSchoolPersonal: false,
            });
          });
          dispatch(uploadPersonalPhotosThunk({ photos }));
        } else {
          handleBuyPhotosWarningDialog(true);
        }
      }
    }
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setShowScrollToTopButton(true);
      } else {
        setShowScrollToTopButton(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Box sx={{ mb: '30px', position: 'relative' }}>
        <Box
          sx={{
            mt: '16px',
            borderTopLeftRadius: '13px',
            borderTopRightRadius: '13px',
            overflow: 'hidden',
          }}
        >
          <Box>
            {(personalPhotos.lastPurchaseDate && new Date() < new Date(personalPhotos.lastPurchaseDate)) && (
              <ImageList variant='masonry' cols={isDownToMd ? 2 : 4} gap={3} sx={{ mt: '30px' }}>
                <ImageListItem>
                  <Box
                    component={'label'}
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 2
                    }}
                    onClick={() => {
                      if (personalPhotos.totalRemainingPhotosCount <= 0) {
                        handleBuyMorePhotosDialog(true);
                      }
                    }}
                  >
                    <Add sx={{ fontSize: { xs: '24px', sm: '40px' } }} />
                    <Typography sx={{ mt: '8px', textAlign: 'center' }}>Upload Photos</Typography>
                    {personalPhotos.totalRemainingPhotosCount > 0 && (
                      <input
                        hidden
                        accept='image/*'
                        multiple
                        type='file'
                        onChange={handleUploadPersonalPhotos}
                      />
                    )}
                  </Box>
                </ImageListItem>
                <ImageListItem>
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={PreschoolArt} alt='' style={{ width: '100%' }} />
                  </Box>
                </ImageListItem>
                <ImageListItem>
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={PreschoolSoccer} alt='' style={{ width: '100%' }} />
                  </Box>
                </ImageListItem>
                <ImageListItem>
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={PreschoolSwimming} alt='' style={{ width: '100%' }} />
                  </Box>
                </ImageListItem>
              </ImageList>
            )}
            {personalPhotos.selectedAlbumPhotos.length === 0 ? (
              <Box
                sx={{
                  textAlign: 'left',
                  ml: '10px',
                  mt: '20px',
                  fontSize: '16px',
                  color: 'GrayText',
                }}
              >
                No photos uploaded.
              </Box>
            ) : (
              <>
                <ImageList variant='masonry' cols={isDownToMd ? 2 : 4} gap={3}>
                  {personalPhotos.selectedAlbumPhotos.map((photo, i) => (
                    <PersonalPhotoItem key={i} photo={photo} />
                  ))}
                </ImageList>
                {showScrollToTopButton && (
                  <Fab
                    variant='extended'
                    disableTouchRipple
                    onClick={scrollToTop}
                    sx={{
                      position: 'fixed',
                      bottom: '25px',
                      left: '50%',
                      transform: 'translateX(-50 %)',
                      background: '#fff',
                      cursor: 'pointer',
                    }}
                  >
                    <KeyboardArrowUp sx={{ mr: 1 }} />
                    To Top
                  </Fab>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PersonalPhotosGrid;
