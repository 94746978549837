import React from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography, Button, Box } from '@mui/material';

const UpgradePremiumBox = ({ handleUpgradePremiumDialog }) => {
  const schoolMemories = useSelector((state) => state.parent.schoolMemories);

  return (
    <Stack
      variant='extended'
      sx={{
        position: 'fixed',
        bottom: '25px',
        right: '60px',
        transform: 'translateX(-50 %)',
        zIndex: 99,
      }}
    >
      {!schoolMemories.upgradedToPremium && (
        <Box
          sx={{
            boxShadow: '0px 9px 8px -5px rgba(0,0,0,0.45)',
            bgcolor: 'white',
            borderRadius: '9px',
            border: 'solid 0.5px rgba(180,180,180)',
            py: 1,
            px: 1,
          }}
        >
          <Typography align='center' sx={{ fontWeight: 700, py: 0, my: 0, fontSize: '15px' }}>
            {schoolMemories.totalAddedMemoriesCount} memories selected
          </Typography>
          <Button
            variant='text'
            sx={{
              fontSize: 14,
              letterSpacing: -0.27,
              mt: -0.5,
              py: 0,
            }}
            onClick={() => handleUpgradePremiumDialog(true)}
          >
            Upgrade to Premium
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default UpgradePremiumBox;
