import { lazy } from 'react';

/**
 * Function to return lazy loaded component with preload function
 *
 * @param {ReactNode} componentToimport
 * @returns ReactNode
 */
export function importWithPreload(componentToimport) {
  const Component = lazy(componentToimport);
  Component.preload = componentToimport;
  return Component;
}
