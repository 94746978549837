import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../api/axios';
import { getListWithImageUrls } from '../utils/assetHelper';
import { setSnackbar } from './snackbarReducer';
import { getParentPhotoFilePath } from '../utils/functions';

const getOrderItemsChildrenThunk = createAsyncThunk('FETCH/ORDER-ITEMS-CHILDREN', async (_, thunkAPI) => {
  try {
    const response = await Axios.get('/member-order-items');

    if (response?.success) {
      return { ...response };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getAlbumsThunk = createAsyncThunk('FETCH/ALBUMS', async (_, thunkAPI) => {
  try {
    const response = await Axios.get('/member/albums');

    if (response?.albums) {
      return { albums: response.albums };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const createAlbumsThunk = createAsyncThunk('CREATE/ALBUMS', async (params, thunkAPI) => {
  try {
    const response = await Axios.post('/member-order-items/create-album', {
      title: params.title,
    });

    if (response?.success) {
      thunkAPI.dispatch(setSnackbar({ message: response?.message || 'Album created!', variant: 'success' }));
      return response;
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getPersonalPhotosThunk = createAsyncThunk('FETCH/PERSONAL-PHOTOS', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    let endpoint = `/member-order-items/photos?schoolId=${orderItems.selectedChild.school.id}&orderItemId=${orderItems.selectedChild.id}&rosterId=${orderItems.selectedChild.rosterId}&type=PERSONAL`;
    if (params?.isSchoolPersonal) {
      endpoint += `&isSchoolPersonal=true`;
    }
    let response = await Axios.get(endpoint);

    if (response?.success) {
      let photos = await getListWithImageUrls(
        response.photos,
        ['url'],
        'deziser-sotohp',
        'photoUrl',
      );
      return {
        ...response,
        photos,
        isSchoolPersonal: params?.isSchoolPersonal || false,
      };
    } else {
      console.log(response);
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    console.log(error);
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const uploadPersonalPhotosThunk = createAsyncThunk('UPLOAD/PERSONAL-PHOTOS', async (params, thunkAPI) => {
  let isSchoolPersonal = false;

  try {
    const state = thunkAPI.getState();
    const member = state.member?.member;
    const orderItems = state.parent.orderItems;
    const activeChild = {
      schoolId: orderItems?.selectedChild?.school?.id,
      rosterId: orderItems?.selectedChild?.rosterId,
      groupId: orderItems?.selectedChild?.groupId,
    };
    let currentAlbum = state.parent.albums.selectedAlbum;

    let errorMessage = '';
    const uploadPromises = params.photos.map(async (photo) => {
      try {
        if (photo?.isSchoolPersonal) {
          isSchoolPersonal = true;
          currentAlbum = {
            id: "4",
            title: "Others"
          }
        }

        let path = getParentPhotoFilePath({
          currentAlbum,
          photoId: photo.id,
          member,
          activeChild,
        });

        const formData = new FormData();
        formData.append('filePath', path);
        formData.append('orderItemId', orderItems?.selectedChild?.id);
        formData.append('caption', photo?.caption || '');
        formData.append('isSchoolPersonal', photo?.isSchoolPersonal || false);
        formData.append('photo', photo.file);

        const response = await Axios.post('/member-order-items/upload-personal-photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (!response.success) {
          errorMessage = 'Error while uploading some of the images';
        }
      } catch (error) {
        errorMessage = 'Error while uploading some of the images';
      }
    });
    await Promise.all(uploadPromises);

    if (!errorMessage) {
      await thunkAPI.dispatch(getPersonalPhotosThunk({ isSchoolPersonal }));
      thunkAPI.dispatch(setSnackbar({ message: 'Photos uploaded successfully!', variant: 'success' }));
      return { success: true };
    } else {
      await thunkAPI.dispatch(getPersonalPhotosThunk({ isSchoolPersonal }));
      thunkAPI.dispatch(setSnackbar({ message: errorMessage, variant: 'error' }));
      return thunkAPI.rejectWithValue({ message: errorMessage });
    }
  } catch (error) {
    await thunkAPI.dispatch(getPersonalPhotosThunk({ isSchoolPersonal }));
    thunkAPI.dispatch(setSnackbar({ message: error?.response?.data?.message || 'Something went wrong!', variant: 'error' }));
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const savePhotoCaptionThunk = createAsyncThunk('SAVE/PHOTO-CAPTION', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    let orderItemId = orderItems.selectedChild.id;

    const response = await Axios.post('/member-order-items/photo-caption', {
      orderItemId,
      photoId: params.photoId,
      caption: params.caption,
    });

    if (response?.success) {
      thunkAPI.dispatch(setSnackbar({ message: response?.message || 'Caption saved!', variant: 'success' }));
      return { ...response, type: params.type };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const deletePersonalPhotosThunk = createAsyncThunk('DELETE/PERSONAL-PHOTOS', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    let isSchoolPersonal = params.isSchoolPersonal;
    const response = await Axios.delete('/member-order-items/personal-photo', {
      data: {
        orderItemId: orderItems.selectedChild.id,
        photoId: params.photoId,
      },
    });

    if (response?.success) {
      thunkAPI.dispatch(setSnackbar({ message: response?.message || 'Photo deleted successfully!', variant: 'success' }));
      return { success: response.success, photoId: params.photoId, isSchoolPersonal };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getSchoolPhotos = createAsyncThunk('FETCH/GET-SCHOOL-PHOTOS', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    let orderItemId = orderItems.selectedChild.id;
    let rosterId = orderItems.selectedChild.rosterId;
    let type = 'SCHOOL';

    const response = await Axios.get(`/member-order-items/photos?schoolId=${orderItems.selectedChild.school.id}&orderItemId=${orderItemId}&rosterId=${rosterId}&type=${type} `);

    if (response?.success) {
      let photosWithUrls = await getListWithImageUrls(
        response.photos,
        ['url'],
        'deziser-sotohp',
        'photoUrl',
      );

      photosWithUrls = photosWithUrls.map((p) => {
        return { ...p, category: p?.category?.toLowerCase() || '' };
      });

      return { ...response, photos: photosWithUrls };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    console.log('error', error);
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const likePhoto = createAsyncThunk('SAVE/LIKE-PHOTO', async (photoId, thunkAPI) => {
  try {
    thunkAPI.dispatch(parentSlice.actions.setPhotoLiked(photoId));
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    let orderItemId = orderItems.selectedChild.id;

    const response = await Axios.post(`/member-order-items/like-photo`, {
      orderItemId,
      photoId: photoId,
    });

    if (response?.success) {
      return { ...response };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getMemoriesNotesThunk = createAsyncThunk('FETCH/MEMORIES-NOTES', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;
    const orderItemId = orderItems.selectedChild.id;

    const response = await Axios.get(`/member-order-items/memories?orderItemId=${orderItemId}&type=${params.type}`);

    if (response?.success) {
      return { ...response, type: params.type };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const createMemoriesNotesThunk = createAsyncThunk('SAVE/MEMORIES-NOTES', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;

    const response = await Axios.post(`/member-order-items/memories`, {
      orderItemId: orderItems.selectedChild.id,
      data: {
        ...params.data,
        rosterId: orderItems.selectedChild.rosterId,
        groupId: orderItems.selectedChild.groupId,
      },
      type: params.type,
    });

    if (response?.success) {
      thunkAPI.dispatch(
        setSnackbar({
          message: response?.message || `${params.type === 'NOTE' ? 'Note' : 'Memory'} created successfully!`,
          variant: 'success',
        }),
      );
      return { ...response, type: params.type };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const updateMemoriesNotesThunk = createAsyncThunk('UPDATE/MEMORIES-NOTES', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;

    const response = await Axios.put(`/member-order-items/memories`, {
      orderItemId: orderItems.selectedChild.id,
      memoryId: params.memoryId,
      data: params.data,
      type: params.type,
    });

    if (response?.success) {
      thunkAPI.dispatch(
        setSnackbar({
          message: response?.message || `${params.type === 'NOTE' ? 'Note' : 'Memory'} updated successfully!`,
          variant: 'success',
        }),
      );
      return { ...response, type: params.type };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const deleteMemoriesNotesThunk = createAsyncThunk('DELETE/MEMORIES-NOTES', async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orderItems = state.parent.orderItems;

    const response = await Axios.delete(`/member-order-items/memories`, {
      data: {
        orderItemId: orderItems.selectedChild.id,
        memoryId: params.memoryId,
        type: params.type,
      },
    });

    if (response?.success) {
      thunkAPI.dispatch(
        setSnackbar({
          message: response?.message || `${params.type === 'NOTE' ? 'Note' : 'Memory'} deleted successfully!`,
          variant: 'success',
        }),
      );
      return { ...response, type: params.type, isIncluded: params.isIncluded };
    } else {
      thunkAPI.dispatch(
        setSnackbar({ message: response?.message || 'Something went wrong!', variant: 'error' }),
      );
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error) {
    thunkAPI.dispatch(
      setSnackbar({
        message: error?.response?.data?.message || 'Something went wrong!',
        variant: 'error',
      }),
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  isLoading: false,
  orderItems: {
    isLoading: false,
    count: 0,
    children: [],
    selectedChild: {},
  },
  albums: {
    isLoading: false,
    data: [],
    selectedAlbum: {},
  },
  personalPhotos: {
    isLoading: false,
    count: 0,
    photos: [],
    selectedAlbumPhotos: [],
    freePhotosCount: 0,
    purchasedPhotosCount: 0,
    uploadedPhotosCount: 0,
    totalAvailablePhotosCount: 0,
    totalRemainingPhotosCount: 0,
    lastPurchaseDate: null
  },
  schoolMemories: {
    isLoading: false,
    photos: [],
    categories: ['Recommended', 'Solo', 'Friends', 'Group', 'All'],
    photoCategories: [],
    selectedPhotoCategory: '',
    notes: [],
    addNoteLoader: false,
    personalMemories: {
      count: 0,
      memories: [],
    },
    totalAvailableMemoriesCount: 0,
    totalAddedMemoriesCount: 0,
    totalIncludedMemoriesCount: 0,
    totalRemainingMemoriesCount: 0,
    upgradedToPremium: false,
    schoolPersonal: [],
    lastPurchaseDate: null
  }
}

const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    setSelectedAlbum: (state, action) => {
      state.albums.selectedAlbum = action.payload;
      state.personalPhotos.selectedAlbumPhotos = state.personalPhotos.photos.filter(
        (photo) => photo.album.id === action.payload.id,
      );
    },
    setSelectedChild: (state, action) => {
      state.orderItems.selectedChild = action.payload;
      state.albums = initialState.albums;
      state.personalPhotos = initialState.personalPhotos;
      state.schoolMemories = initialState.schoolMemories;
    },
    setPhotoLiked: (state, action) => {
      let photoId = action.payload;
      state.schoolMemories.photos = state.schoolMemories.photos.map((p) => {
        if (p.id === photoId) {
          let isLiked = p.isLiked || false;
          if (isLiked) {
            state.schoolMemories.totalAddedMemoriesCount =
              state.schoolMemories.totalAddedMemoriesCount - 1;
            state.schoolMemories.totalRemainingMemoriesCount =
              state.schoolMemories.totalRemainingMemoriesCount + 1;
          } else {
            state.schoolMemories.totalAddedMemoriesCount =
              state.schoolMemories.totalAddedMemoriesCount + 1;
            state.schoolMemories.totalRemainingMemoriesCount =
              state.schoolMemories.totalRemainingMemoriesCount - 1;
          }
          return { ...p, isLiked: !isLiked };
        } else {
          return p;
        }
      });
    },
    setSchoolSelectedPhotoCategory: (state, action) => {
      state.schoolMemories.selectedPhotoCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderItemsChildrenThunk.pending, (state) => {
        state.orderItems.isLoading = true;
      })
      .addCase(getOrderItemsChildrenThunk.fulfilled, (state, { payload }) => {
        state.orderItems.count = payload.count;
        state.orderItems.children = payload.memberOrderItems;
        state.orderItems.selectedChild = payload?.memberOrderItems[0] || {};
        state.orderItems.isLoading = false;
      })
      .addCase(getOrderItemsChildrenThunk.rejected, (state) => {
        state.orderItems.isLoading = false;
      })

      .addCase(getAlbumsThunk.pending, (state) => {
        state.albums.isLoading = true;
      })
      .addCase(getAlbumsThunk.fulfilled, (state, { payload }) => {
        state.albums.data = payload.albums;
        state.albums.selectedAlbum = payload?.albums[0] || {};
        state.albums.isLoading = false;
      })
      .addCase(getAlbumsThunk.rejected, (state) => {
        state.albums.isLoading = false;
      })

      .addCase(createAlbumsThunk.pending, (state) => {
        state.albums.isLoading = true;
      })
      .addCase(createAlbumsThunk.fulfilled, (state, { payload }) => {
        state.albums.data = [...state.albums.data, { id: payload.id, title: payload.title }];
        state.albums.isLoading = false;
      })
      .addCase(createAlbumsThunk.rejected, (state) => {
        state.albums.isLoading = false;
      })

      .addCase(getPersonalPhotosThunk.pending, (state) => {
        state.personalPhotos.isLoading = true;
        state.schoolMemories.isLoading = true;
      })
      .addCase(getPersonalPhotosThunk.fulfilled, (state, { payload }) => {
        if (payload.isSchoolPersonal) {
          state.schoolMemories.schoolPersonal = payload.photos;
          state.schoolMemories.totalAvailableMemoriesCount = payload.totalAvailableMemoriesCount;
          state.schoolMemories.totalAddedMemoriesCount = payload.totalAddedMemoriesCount;
          state.schoolMemories.totalIncludedMemoriesCount = payload.totalIncludedMemoriesCount;
          state.schoolMemories.totalRemainingMemoriesCount = payload.totalRemainingMemoriesCount;
          state.schoolMemories.upgradedToPremium = payload.upgradedToPremium;
        } else {
          state.personalPhotos.photos = payload.photos;
          state.personalPhotos.selectedAlbumPhotos = payload.photos;
          state.personalPhotos.count = payload.count;
          state.personalPhotos.freePhotosCount = payload.freePhotosCount;
          state.personalPhotos.purchasedPhotosCount = payload.purchasedPhotosCount;
          state.personalPhotos.uploadedPhotosCount = payload.uploadedPhotosCount;
          state.personalPhotos.totalAvailablePhotosCount = payload.totalAvailablePhotosCount;
          state.personalPhotos.totalRemainingPhotosCount = payload.totalRemainingPhotosCount;
          state.personalPhotos.lastPurchaseDate = payload.lastPurchaseDate;
        }

        state.personalPhotos.isLoading = false;
        state.schoolMemories.isLoading = false;
      })
      .addCase(getPersonalPhotosThunk.rejected, (state) => {
        state.personalPhotos.isLoading = false;
      })

      .addCase(uploadPersonalPhotosThunk.pending, (state) => {
        state.personalPhotos.isLoading = true;
        state.schoolMemories.isLoading = true;
      })
      .addCase(uploadPersonalPhotosThunk.rejected, (state) => {
        state.personalPhotos.isLoading = false;
        state.schoolMemories.isLoading = false;
      })

      .addCase(savePhotoCaptionThunk.pending, (state) => {
        state.personalPhotos.isLoading = true;
        state.schoolMemories.isLoading = true;
      })
      .addCase(savePhotoCaptionThunk.fulfilled, (state, { payload }) => {
        if (payload.type === 'PERSONAL_PHOTO') {
          const tempParentPhotos = [...state.personalPhotos.photos];
          const index = tempParentPhotos.findIndex((p) => p.id === payload.photo.id);

          if (index > -1) {
            tempParentPhotos[index].caption = payload.photo.caption;
            state.personalPhotos.photos = tempParentPhotos;
            state.personalPhotos.selectedAlbumPhotos = tempParentPhotos.filter(
              (photo) => photo.album.id === state.albums.selectedAlbum.id,
            );
          }
        }
        if (payload.type === 'SCHOOL_PERSONAL') {
          const tempParentPhotos = [...state.schoolMemories.schoolPersonal];
          const index = tempParentPhotos.findIndex((p) => p.id === payload.photo.id);

          if (index > -1) {
            tempParentPhotos[index].caption = payload.photo.caption;
            state.schoolMemories.schoolPersonal = tempParentPhotos;
          }
        }
        if (payload.type === 'SCHOOL_PHOTO') {
          const tempSchoolPhotos = [...state.schoolMemories.photos];
          const index = tempSchoolPhotos.findIndex((p) => p.id === payload.photo.id);
          console.log('captioj', index)
          if (index > -1) {
            tempSchoolPhotos[index].caption = payload.photo.caption;
            state.schoolMemories.photos = tempSchoolPhotos;
          }
        }

        state.personalPhotos.isLoading = false;
        state.schoolMemories.isLoading = false;
      })
      .addCase(savePhotoCaptionThunk.rejected, (state) => {
        state.personalPhotos.isLoading = false;
        state.schoolMemories.isLoading = false;
      })

      .addCase(deletePersonalPhotosThunk.pending, (state) => {
        state.personalPhotos.isLoading = true;
        state.schoolMemories.isLoading = true;
      })
      .addCase(deletePersonalPhotosThunk.fulfilled, (state, { payload }) => {
        if (payload.isSchoolPersonal) {
          state.schoolMemories.isLoading = false;
          state.schoolMemories.schoolPersonal = state.schoolMemories.schoolPersonal.filter(
            (photo) => photo.id !== payload.photoId,
          );
          // state.schoolMemories.personalMemories.count =
          //   state.schoolMemories.personalMemories.count - 1;
          if (!payload.isIncluded) {
            state.schoolMemories.totalAddedMemoriesCount =
              state.schoolMemories.totalAddedMemoriesCount - 1;
            state.schoolMemories.totalRemainingMemoriesCount =
              state.schoolMemories.totalRemainingMemoriesCount + 1;
          }
        } else {
          state.personalPhotos.count = state.personalPhotos.count - 1;
          state.personalPhotos.photos = state.personalPhotos.photos.filter(
            (photo) => photo.id !== payload.photoId,
          );
          state.personalPhotos.selectedAlbumPhotos = state.personalPhotos.photos.filter(
            (photo) => photo.album.id === state.albums.selectedAlbum.id,
          );
          state.personalPhotos.uploadedPhotosCount = state.personalPhotos.uploadedPhotosCount - 1;
          state.personalPhotos.totalRemainingPhotosCount =
            state.personalPhotos.totalRemainingPhotosCount + 1;
          state.personalPhotos.isLoading = false;
        }
      })
      .addCase(deletePersonalPhotosThunk.rejected, (state) => {
        state.personalPhotos.isLoading = false;
        state.schoolMemories.isLoading = false;
      })

      .addCase(getSchoolPhotos.pending, (state) => {
        state.schoolMemories.isLoading = true;
      })
      .addCase(getSchoolPhotos.fulfilled, (state, { payload }) => {
        let categoriesSet = new Set(payload.photos.map((p) => p.category));
        state.schoolMemories.photos = payload.photos;
        state.schoolMemories.photoCategories = Array.from(categoriesSet);
        state.schoolMemories.selectedPhotoCategory = state.schoolMemories.photoCategories[0] || '';
        state.schoolMemories.totalAvailableMemoriesCount = payload.totalAvailableMemoriesCount;
        state.schoolMemories.totalAddedMemoriesCount = payload.totalAddedMemoriesCount;
        state.schoolMemories.totalIncludedMemoriesCount = payload.totalIncludedMemoriesCount;
        state.schoolMemories.totalRemainingMemoriesCount = payload.totalRemainingMemoriesCount;
        state.schoolMemories.upgradedToPremium = payload.upgradedToPremium;
        state.schoolMemories.lastPurchaseDate = payload.lastPurchaseDate;
        state.schoolMemories.isLoading = false;
      })
      .addCase(getSchoolPhotos.rejected, (state) => {
        state.schoolMemories.isLoading = false;
      })

      .addCase(getMemoriesNotesThunk.pending, (state) => {
        state.schoolMemories.isLoading = true;
      })
      .addCase(getMemoriesNotesThunk.fulfilled, (state, { payload }) => {
        if (payload.type === 'NOTE') {
          state.schoolMemories.notes = payload.memories;
        }
        if (payload.type === 'MEMORY') {
          state.schoolMemories.personalMemories.count = payload.count;
          state.schoolMemories.personalMemories.memories = payload.memories;
        }
        state.schoolMemories.isLoading = false;
      })
      .addCase(getMemoriesNotesThunk.rejected, (state) => {
        state.schoolMemories.isLoading = false;
      })

      .addCase(createMemoriesNotesThunk.pending, (state) => {
        state.schoolMemories.addNoteLoader = true;
        state.schoolMemories.isLoading = true;
      })
      .addCase(createMemoriesNotesThunk.fulfilled, (state, { payload }) => {
        if (payload.type === 'NOTE') {
          state.schoolMemories.notes = [payload.memories, ...state.schoolMemories.notes];
        }
        if (payload.type === 'MEMORY') {
          state.schoolMemories.personalMemories.count =
            state.schoolMemories.personalMemories.count + 1;
          state.schoolMemories.personalMemories.memories = [
            payload.memories,
            ...state.schoolMemories.personalMemories.memories,
          ];
        }
        state.schoolMemories.totalAddedMemoriesCount =
          state.schoolMemories.totalAddedMemoriesCount + 1;
        state.schoolMemories.totalRemainingMemoriesCount =
          state.schoolMemories.totalRemainingMemoriesCount - 1;
        state.schoolMemories.addNoteLoader = false;
        state.schoolMemories.isLoading = false;
      })
      .addCase(createMemoriesNotesThunk.rejected, (state) => {
        state.schoolMemories.addNoteLoader = false;
        state.schoolMemories.isLoading = false;
      })

      .addCase(updateMemoriesNotesThunk.pending, (state) => {
        state.schoolMemories.isLoading = true;
      })
      .addCase(updateMemoriesNotesThunk.fulfilled, (state, { payload }) => {
        if (payload.type === 'NOTE') {
          const index = state.schoolMemories.notes.findIndex((n) => n.id === payload.memory.id);
          if (index > -1) {
            state.schoolMemories.notes[index] = payload.memory;
          }
        }
        if (payload.type === 'MEMORY') {
          const index = state.schoolMemories.personalMemories.memories.findIndex(
            (m) => m.id === payload.memory.id,
          );
          if (index > -1) {
            state.schoolMemories.personalMemories.memories[index] = payload.memory;
          }
        }
        state.schoolMemories.isLoading = false;
      })
      .addCase(updateMemoriesNotesThunk.rejected, (state) => {
        state.schoolMemories.isLoading = false;
      })

      .addCase(deleteMemoriesNotesThunk.pending, (state) => {
        state.schoolMemories.isLoading = true;
      })
      .addCase(deleteMemoriesNotesThunk.fulfilled, (state, { payload }) => {
        if (payload.type === 'NOTE') {
          state.schoolMemories.notes = state.schoolMemories.notes.filter(
            (n) => n.id !== payload.memoryId,
          );
        }
        if (payload.type === 'MEMORY') {
          state.schoolMemories.personalMemories.count =
            state.schoolMemories.personalMemories.count - 1;
          state.schoolMemories.personalMemories.memories =
            state.schoolMemories.personalMemories.memories.filter((m) => m.id !== payload.memoryId);
        }
        if (!payload.isIncluded) {
          state.schoolMemories.totalAddedMemoriesCount =
            state.schoolMemories.totalAddedMemoriesCount - 1;
          state.schoolMemories.totalRemainingMemoriesCount =
            state.schoolMemories.totalRemainingMemoriesCount + 1;
        }
        state.schoolMemories.isLoading = false;
      })
      .addCase(deleteMemoriesNotesThunk.rejected, (state) => {
        state.schoolMemories.isLoading = false;
      });
  },
});

export {
  getOrderItemsChildrenThunk,
  getAlbumsThunk,
  createAlbumsThunk,
  getPersonalPhotosThunk,
  uploadPersonalPhotosThunk,
  savePhotoCaptionThunk,
  deletePersonalPhotosThunk,
  getSchoolPhotos,
  likePhoto,
  getMemoriesNotesThunk,
  createMemoriesNotesThunk,
  updateMemoriesNotesThunk,
  deleteMemoriesNotesThunk,
};
export const { setSelectedAlbum, setSelectedChild, setSchoolSelectedPhotoCategory } =
  parentSlice.actions;
export { parentSlice };
export default parentSlice.reducer;
