import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAlbumsThunk, getPersonalPhotosThunk } from '../../reducers/parentReducer';
import { Box, Typography, Stack, Paper, Button } from '@mui/material';
import Spinner from '../../components/Spinner';
import PersonalPhotosGrid from './components/personal-photos/PersonalPhotosGrid';
import BuyPhotosWarningDialog from './components/personal-photos/buy-more-photos/BuyPhotosWarningDialog';
import BuyMorePhotosDialog from './components/personal-photos/buy-more-photos/BuyMorePhotosDialog';
// import Boy1 from '../../assets/boyIcon.webp';
// import DismissibleBanner from '../../components/global/DismissibleBanner';

const drawerWidth = 240;

const ParentAccountPersonalPhotos = ({ handleToggleSamplePagesDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderItems, personalPhotos } = useSelector((state) => state.parent);

  const [showBuyPhotosWarningDialog, setShowBuyPhotosWarningDialog] = useState(false);
  const [showBuyMorePhotosDialog, setShowBuyMorePhotosDialog] = useState(false);

  const remainingPhotosCount = useMemo(() => {
    if (!personalPhotos.isLoading && personalPhotos.totalRemainingPhotosCount !== undefined) {
      return personalPhotos.totalRemainingPhotosCount;
    } else {
      return 0;
    }
  }, [personalPhotos.isLoading, personalPhotos.totalRemainingPhotosCount]);

  const handleBuyPhotosWarningDialog = (isOpen) => {
    setShowBuyPhotosWarningDialog(isOpen);
  };

  const handleBuyMorePhotosDialog = (isOpen) => {
    setShowBuyMorePhotosDialog(isOpen);
  };

  useEffect(() => {
    if (!orderItems?.selectedChild?.id) {
      navigate('/parent-account', { replace: true });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderItems?.selectedChild?.id) {
      dispatch(getAlbumsThunk());
      dispatch(getPersonalPhotosThunk());
    }
    //eslint-disable-next-line
  }, [orderItems.selectedChild]);

  return (
    <>
      {personalPhotos.isLoading && <Spinner open />}
      {(personalPhotos.lastPurchaseDate && new Date() > new Date(personalPhotos.lastPurchaseDate)) && (
        <Banner />
      )}
      <Box
        sx={{
          width: `calc(100%-${drawerWidth})`,
        }}
      >
        <Box
          sx={{
            width: '96%',
            position: 'fixed',
            height: '100px',
            top: 40,
            left: { drawerWidth },
            right: 0,
            pointerEvents: 'none',
            backgroundImage:
              'linear-gradient(180deg,#fcfcfd 0,rgba(252,252,253,.987) 14%,rgba(252,252,253,.951) 26.2%,rgba(252,252,253,.896) 36.8%,rgba(252,252,253,.825) 45.9%,rgba(252,252,253,.741) 53.7%,rgba(252,252,253,.648) 60.4%,rgba(252,252,253,.55) 66.2%,rgba(252,252,253,.45) 71.2%,rgba(252,252,253,.352) 75.6%,rgba(252,252,253,.259) 79.6%,rgba(252,252,253,.175) 83.4%,rgba(252,252,253,.104) 87.2%,rgba(252,252,253,.049) 91.1%,rgba(252,252,253,.013) 95.3%,rgba(252,252,253,0))',
            zIndex: 2,
          }}
        />

        {/* <Box
          sx={{
            position: 'fixed',
            pl: '8px',
            pr: '30px',
            zIndex: 3,
            width: `calc(100% - ${drawerWidth}px)`,
            left: { drawerWidth },
            top: 20,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              px: '10px',
              mt: '50px',
            }}
          >
            <AlbumsSection />
          </Box>
        </Box> */}

        <Box
          sx={{
            pt: '100px',
            pl: { xs: '16px', sm: '8px' },
            pr: { xs: '16px', sm: '30px' },
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {/* <DismissibleBanner
            Content={() => (
              <Box
                sx={{
                  width: '100%',
                  bgcolor: 'rgb(245,246,247)',
                  p: 2,
                  border: '1px solid rgba(225,225,225)',
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: { xs: 2, md: 4 },
                }}
              >
                <Box
                  component={'img'}
                  src={Boy1}
                  alt=''
                  sx={{
                    width: { xs: '75px', md: '50px' },
                    height: { xs: '75px', md: '50px' },
                    ml: { xs: 0, md: '10px' },
                  }}
                />
                <Stack alignItems={'flex-start'}>
                  <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
                    Include your family's cherished moments using 8 free photos with grandparents,
                    vacations, celebrations or more. You can purchase as many additional photos as
                    you wish and we will include them in the Yearbook.
                  </Typography>
                  <Button
                    variant='text'
                    onClick={() => handleToggleSamplePagesDialog(true, 'FAMILY_MEMORIES')}
                    sx={{
                      p: 0,
                      fontSize: 14,
                      letterSpacing: -0.18,
                      mt: '4px',
                    }}
                  >
                    View Sample
                  </Button>
                </Stack>
              </Box>
            )}
            bannerBgColor='rgb(244,245,246)'
            showCloseBtn={false}
          /> */}

          <PersonalPhotosGrid
            handleBuyPhotosWarningDialog={handleBuyPhotosWarningDialog}
            handleBuyMorePhotosDialog={handleBuyMorePhotosDialog}
          />
        </Box>

        {(personalPhotos.lastPurchaseDate && new Date() < new Date(personalPhotos.lastPurchaseDate)) && (
          <Stack
            variant='extended'
            sx={{
              position: 'fixed',
              bottom: '25px',
              right: '60px',
              transform: 'translateX(-50 %)',
              zIndex: 99,
            }}
          >
            <Paper
              sx={{
                bgcolor: 'white',
                borderRadius: '13px',

                background: '#fff',
                alignItems: 'center',
                direction: 'column',
                py: '16px',
                px: '24px',
              }}
              elevation={9}
            >
              <Typography align='center' sx={{ fontSize: 54, fontWeight: 700, lineHeight: '54px' }}>
                {remainingPhotosCount}
              </Typography>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ mt: '-4px' }}
              >
                <Typography align='center' sx={{ fontSize: 12, color: 'GrayText' }}>
                  available
                </Typography>
              </Stack>
              <Button
                variant='contained'
                sx={{ height: '32px', width: '120px', fontSize: 14, mt: 1 }}
                onClick={() => handleBuyMorePhotosDialog(true)}
              >
                Buy
              </Button>
            </Paper>
          </Stack>
        )}

        {showBuyPhotosWarningDialog && (
          <BuyPhotosWarningDialog
            open={showBuyPhotosWarningDialog}
            handleClose={() => handleBuyPhotosWarningDialog(false)}
            handlePurchase={() => {
              handleBuyPhotosWarningDialog(false);
              handleBuyMorePhotosDialog(true);
            }}
          />
        )}
        {showBuyMorePhotosDialog && (
          <BuyMorePhotosDialog
            open={showBuyMorePhotosDialog}
            handleClose={() => handleBuyMorePhotosDialog(false)}
          />
        )}
      </Box>
    </>
  );
};

const Banner = () => {
  return (
    <>
      <Stack
        justifyContent='center'
        sx={{
          height: 40,
          bgcolor: 'darkOrange',
          position: 'fixed',
          top: 0,
          left: `${drawerWidth}px`,
          width: `calc(100vw - ${drawerWidth}px)`,
          zIndex: 999,
        }}
      >
        <Typography
          align='center'
          sx={{ color: 'black', fontWeight: 500, fontSize: 16, letterSpacing: -0.27 }}
        >
          The deadline to upload family photos has passed.
        </Typography>
      </Stack>
    </>
  );
};

export default ParentAccountPersonalPhotos;
