import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../api/axios';

export const getProduct = createAsyncThunk('product/fetch', async (data) => {
  let product = await Axios.post('/product/:productId', {
    ...data,
  });
  console.log('product', product);
  return product;
});

export const getProductFromSchool = createAsyncThunk('fetch/product', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const schoolId = state.member?.activeChild?.schoolId;
    let response = await Axios.post('/schools/get-product', { schoolId });
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const ProductSlice = createSlice({
  name: 'product',
  initialState: {
    product: {},
    schoolProduct: {},
    activeRoster: undefined,

    personalizedData: {
      info: {
        firstName: 'Tanay',
        lastName: 'Van',
        dob: new Date().toISOString().substring(0, 10),
        profile: {},
      },
      photos: {},
      memories: {
        personality: { tags: [], notes: '' },
        likesTo: { tags: [], notes: '' },
        greatAt: { tags: [], notes: '' },
        growUp: { tags: [], notes: '' },
      },
    },
    isError: false,
    isLoading: false,
  },
  reducers: {
    updateInfo: (state, action) => {
      console.log('action.payload.value', action.payload);
      let type = action.payload.type;
      let value = action.payload.value;
      state.personalizedData.info[type] = value;
    },
    updateMemories: (state, action) => {
      let type = action.payload.type;
      let field = action.payload.field;
      let value = action.payload.value;
      if (field === 'tags') {
        state.personalizedData.memories[type][field] = [
          ...state.personalizedData.memories[type][field],
          value,
        ];
      } else {
        state.personalizedData.memories[type][field] = value;
      }
    },
    updateActiveRoster: (state, action) => {
      state.activeRoster = action.payload;
      if (!action.payload) {
        state.activeRoster = undefined;
        return;
      }
      if (action.payload.info) {
        state.personalizedData.info.firstName = action.payload.info.firstName;
        state.personalizedData.info.lastName = action.payload.info.lastName;
        state.personalizedData.info.gender = action.payload.info.gender;
        // if (action.payload.info.dob) {
        //   state.personalizedData.info.dob = new Date(
        //     action.payload.info.dob.seconds * 1000
        //   )
        //     .toISOString()
        //     .substring(0, 10);
        // }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.isError = true;
        } else {
          state.isError = false;
          state.product = action.payload;
        }
      })
      .addCase(getProduct.rejected, (state, action) => {
        console.log('error', action);
      })
      .addCase(getProductFromSchool.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductFromSchool.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.schoolProduct = action.payload.product;
        }
      })
      .addCase(getProductFromSchool.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
// export const currentTab = (state) => state.tab.currentTab;
// export const schoolTab = (state) => state.tab.schoolTab;
export const { updateInfo, updateMemories, updateActiveRoster } = ProductSlice.actions;

export default ProductSlice.reducer;
