import Brave from '../assets/personality/personality-brave.webp';
import Curious from '../assets/personality/personality-curious.webp';
import Happy from '../assets/personality/personality-happy.webp';
import Naughty from '../assets/personality/personality-naughty.webp';
import Quiet from '../assets/personality/personality-quiet.webp';
import Shy from '../assets/personality/personality-shy.webp';
import SmartyPants from '../assets/personality/personality-smarty-pants.webp';
import Talkative from '../assets/personality/personality-talkative.webp';
import BaaBaa from '../assets/rhyme/rhyme-baa-baa.webp';
import Diddle from '../assets/rhyme/rhyme-diddle-diddle.webp';
import Humpty from '../assets/rhyme/rhyme-humpty-dumpty.webp';
import ItsyBitsy from '../assets/rhyme/rhyme-itsy-bitsy.webp';
import LittleBoBeep from '../assets/rhyme/rhyme-little-beep.webp';
import LondonBridge from '../assets/rhyme/rhyme-london-bridge.webp';
import MaryLamb from '../assets/rhyme/rhyme-mary-lamb.webp';
import TeaPot from '../assets/rhyme/rhyme-old-mcdonald.webp';
import TenBed from '../assets/rhyme/rhyme-ten-in-the-bed.webp';
import Astronaut from '../assets/growUp/growUp-astronaut.webp';
import Ballet from '../assets/growUp/growUp-ballet-dancer.webp';
import Engineer from '../assets/growUp/growUp-engineer.webp';
import Fireman from '../assets/growUp/growUp-fireman.webp';
import Policeman from '../assets/growUp/growUp-policeman.webp';
import Scientist from '../assets/growUp/growUp-scientist.webp';
import Soccer from '../assets/growUp/growUp-soccer-player.webp';
import Teacher from '../assets/growUp/growUp-teacher.webp';
import Tennis from '../assets/growUp/growUp-tennis-player.webp';
import WhenIWas1 from '../assets/timeline/whenIWas1.webp';
import WhenIWas2 from '../assets/timeline/whenIWas2.webp';
import WhenIWas3 from '../assets/timeline/whenIWas3.webp';
import WhenIWas4 from '../assets/timeline/whenIWas4.webp';
import WhenIWas5 from '../assets/timeline/whenIWas5.webp';
import WhenIWas6 from '../assets/timeline/whenIWas6.webp';
import FavRhymeIcon from '../assets/memoryIcons/favorite-rhyme-icon.webp';
import FavToyIcon from '../assets/memoryIcons/favorite-toy-icon.webp';
import GrowupIcon from '../assets/memoryIcons/growup-icon.webp';
import HalloweenIcon from '../assets/memoryIcons/halloween-icon.webp';
import Personality from '../assets/memoryIcons/personality-icon.webp';
import Blocks from '../assets/toys/toys-blocks.webp';
import Softoys from '../assets/toys/toys-softtoys.webp';
import Puzzles from '../assets/toys/toys-puzzles.webp';
import Playdoh from '../assets/toys/toys-playdoh.webp';
import Dolls from '../assets/toys/toys-dolls.webp';
import Firetruck from '../assets/toys/toys-firetruck.webp';
import Dumptruck from '../assets/toys/toys-dumptruck.webp';
import Cars from '../assets/toys/toys-cars.webp';
import Avengers from '../assets/halloween/halloween-avengers.png';
import Dinosaur from '../assets/halloween/halloween-dinosaur.png';
import Frozen from '../assets/halloween/halloween-frozen.png';
import Minions from '../assets/halloween/halloween-minions.png';
import NinjaTurtle from '../assets/halloween/halloween-ninja-turtle.png';
import Pirate from '../assets/halloween/halloween-pirate.png';
import Rabbit from '../assets/halloween/halloween-rabbit.png';
import StarWars from '../assets/halloween/halloween-star-wars.png';
import Superhero from '../assets/halloween/halloween-superhero.png';

export const MemoriesDefault =
  'One day they want to be an Astronaut and Fireman the next. Capture these memories and we will build a timeline for you.';

export const ParentNextSteps = {
  personalize: "Share your child's info and help us personalize their memory book",
  checkout:
    "Purchase your child's Personalized Memory Book and preserve their school memories forever",
  memories:
    'Include  milestones, birthday celebrations, family vacations and preserve growing up memories forever',
};

export const MemoriesIntro =
  'Buzzing Bee at Halloween or a budding scientist, kids change everyday and grow up fast. Create a timeline of such precious moments and we will include them in your personalized memory book. ';

export const WhenIWasImages = [
  {
    id: 'd9bdf86a-95a9-4c6c-b7be-c9ad6960f1ef',
    src: WhenIWas1,
    age: { number: 1, text: 'One' },
  },
  {
    id: '6e5c6f80-1e21-4472-9544-167ba3969eaa',
    src: WhenIWas2,
    age: { number: 2, text: 'Two' },
  },
  {
    id: 'ff583ea7-1bb2-40a3-9e66-c9cb7e0ed76f',
    src: WhenIWas3,
    age: { number: 3, text: 'Three' },
  },
  {
    id: '88d59a77-6a28-481f-876a-c32b8812f607',
    src: WhenIWas4,
    age: { number: 4, text: 'Four' },
  },
  {
    id: 'bbdd82f7-0687-4336-83df-c8bf7b945293',
    src: WhenIWas5,
    age: { number: 5, text: 'Five' },
  },
  {
    id: '25bf9208-0a12-418b-bf3e-dc67edabfa20',
    src: WhenIWas6,
    age: { number: 6, text: 'Six' },
  },
];

export const MemoriesData = [
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-3',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 3,
    icon: GrowupIcon,
    options: [
      {
        id: '0b3ff965-8ef6-4b68-b741-3e75e3c51ddb',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: 'c4572a74-f4e0-41b7-a47b-e28c764f5cde',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '22ebf049-6750-47f6-ab59-a555517c8f7f',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: 'd930291c-fc36-49e5-a139-f5465e5a338c',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: '8db3e546-11b1-4769-a5fc-5780f97a84bb',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: 'd140dc88-26b9-476f-a84b-9b2c52fc3389',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: 'f4be3dc8-e45f-4ec7-9c0e-552a1231b247',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '3df9389a-a3b2-458d-b235-b76cb8669d09',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: 'ed3d64d9-902d-44e7-a10e-9a50ab5fecb3',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-3',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 3,
    options: [
      {
        id: '47d7f39e-a2ae-43b0-bb0e-98efb912ec9b',
        src: Brave,
        title: 'Brave',
      },
      {
        id: '12cadc94-bb67-4dac-ab6b-95ce1494ac60',
        src: Curious,
        title: 'Curious',
      },
      {
        id: 'ea38f10d-f1ba-4478-8a8a-b256a6318e23',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '43fca091-e7b9-4c49-96e9-86992c1cd2d3',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: 'bac907ce-9715-489b-be25-236b626d57c0',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: '1d6a2091-9bf8-4cc9-b633-4fd3555f26d6',
        src: Shy,
        title: 'Shy',
      },
      {
        id: 'cac85219-e055-4837-a342-3fd3510f4f4c',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: '4c120f39-d87c-4ce2-ac2b-8edc464cca19',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-3',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 3,
    options: [
      {
        id: 'e72a81b0-20f3-4698-a242-1bd10d4c4117',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '488643dd-5688-4d1b-be78-0405a8300265',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '1c12e2ac-88b7-4c5a-8482-e42979948f35',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: 'e0cac7a4-880d-46f6-b1db-ce236c711fd6',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: '259bf318-6f42-44f5-aa50-7eaaf4f63d9a',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '096b5672-f1b5-4fa7-bb82-9a1d3c02b9ac',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: 'd4477a37-b7fc-4606-be97-1937867c0ea8',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: 'a875208f-e5b8-44d0-a877-b3131854d4df',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-3',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 3,
    options: [
      {
        id: '550c7ac4-bcac-4187-ba96-91c94d9601f9',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: '88b5f725-e0ff-4269-b863-fc7a4244388c',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: '304500dd-8784-4eaf-bbce-3f0a0a7b5b59',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: '68aa407e-ec24-44c3-86b9-cd5e8c603a55',
        title: 'Minions',
        src: Minions,
      },
      {
        id: '418daf63-7088-48db-88bd-a08a18439dd7',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: '5c7fa1de-d0eb-4927-b0e6-9e224504d402',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: '415e2fb7-abf8-4f43-ad0e-1554240095bb',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: 'a724648e-700a-40e7-9f95-4f0419fe3a70',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: 'f3405ec8-ec6d-4eaa-bb3e-9d2a37a31d15',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-3',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 3,
    options: [
      {
        id: '08ccdffa-1a1a-459c-b5bd-2c1ecaab2344',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: 'b74e4fcd-0b08-4102-b44b-0894e1a86f05',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: '4118a84f-82b7-4565-8882-899bbe7e19ab',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: '563d7b5b-442e-4afb-9b04-294fbca51dff',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: '46dec8a2-1c20-4406-b9c5-a73fd508cb6e',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: '042bd6e8-6d81-40f3-a4df-0a8ba079914b',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: '69de531b-5ecc-452d-bbca-366301e5b2a7',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: '1ad7649d-4e80-4e9a-acb9-cadeba4cbb0d',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: '8418a75f-0dac-42fa-8825-4de94189833e',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-1',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 1,
    icon: GrowupIcon,
    options: [
      {
        id: '32a24d2d-a153-4ac1-8613-f78fda5df901',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: '2ed4fe83-e08c-4330-8464-45d8b730bc40',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '9a3e8c5e-7339-4872-8313-df4ce489dca0',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: '96e39844-c198-4ff6-a628-e4d4703ed1ea',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: '7f4caee8-6646-40b7-b2aa-e10aefdb3460',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: 'dc461034-b137-4afb-b8d0-39435ceac41f',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: '3f4b55c1-ae52-4904-983a-40bbecc71145',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '4cfcc3ce-1927-4d9c-b975-ec95e5bfd02f',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: 'bea7c9ba-c9b1-4e6e-a468-fcfcf8fb149d',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-1',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 1,
    options: [
      {
        id: 'fa962abe-a4fc-45a7-987c-ee8d9377e14b',
        src: Brave,
        title: 'Brave',
      },
      {
        id: '98578149-40e0-4962-91b1-5695a9b0b41a',
        src: Curious,
        title: 'Curious',
      },
      {
        id: '41303c58-3403-49f4-b18b-6069e6928a4f',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '43fca091-e7b9-4c49-96e9-86992c1cd2d3',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: '86226dd9-1a7e-4075-b80d-cff0230ba025',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: 'a9016fa0-285a-4e02-a242-c583afc23afe',
        src: Shy,
        title: 'Shy',
      },
      {
        id: 'fcfe6d9a-2479-4c45-bee7-bc4d80d319e9',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: 'eaa78fc7-30b5-4f77-ac49-0c391e086cd0',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-1',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 1,
    options: [
      {
        id: '1cf4b598-eabe-4eb8-8eef-ec50d9b74931',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '79b47d6a-5656-4881-a60a-abb5b678e5ee',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '8325c703-c2fa-40db-96b1-df1c94e29d74',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: '914ddb0a-9fb5-4658-a7e0-281962b070b7',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: 'fb579ac0-4efe-4418-8e15-5d4808548a98',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '1d016de3-bf93-4232-981a-3882b47d0a59',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: '69793f94-6b3f-4a75-8b05-09381425898f',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: '1a70ac07-948e-43d6-97c3-227b4b3830f5',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-1',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 1,
    options: [
      {
        id: '1f60f642-b37a-4edb-b763-45831b04e3f1',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: '93516186-b3ec-4db4-8e54-009b8f7f6b7b',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: '923617db-b9af-4d81-898f-14fd0d7c31b2',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: 'c4f4940a-b77e-49ef-b9b6-14ab290cb056',
        title: 'Minions',
        src: Minions,
      },
      {
        id: '2414c1f7-ad25-40f5-9869-39c89d4936e2',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: '57e925ba-05c6-4bfd-ab00-1cf44ea96491',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: '102c7054-a45e-4525-84af-61d5ef4738dd',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: '91f3503f-7639-4f6b-b67e-8afe4a5e2c33',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: '39533d63-035a-4f42-ba13-4df0c2cfab3b',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-1',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 1,
    options: [
      {
        id: '350a73a0-7786-4c80-8ded-ca9690354cba',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: '18d5a607-522e-4f01-971f-bf3ada36b708',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: '7d69f3eb-402c-48ea-9047-4f743797db9b',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: '366e1ff2-879c-4f83-a461-8141c61a254c',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: '8c44286e-5ffa-4627-92bf-57f0391b8a1e',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: 'e04aa76c-3248-4b6f-a492-82e44a0ae98c',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: '27f01d09-4199-419b-9bcb-4b75de9fe5ae',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: '29b79bcd-0f80-4962-9086-7e44b64b6349',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: 'a75f2451-2b1c-4438-a16b-9bd7c518082f',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-2',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 2,
    icon: GrowupIcon,
    options: [
      {
        id: '73327f27-db79-4814-add8-3eb53e21bb27',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: 'fa8f64e5-f7da-427b-9594-ee55007fbc33',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '670390b9-cbbb-42eb-9c7b-6e882b8fac4e',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: 'fd91cfcc-3996-4e89-9531-8dc74851d3dc',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: 'ed7974ef-378e-4e91-ba20-f7e963ff79b7',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: 'd5f27d71-e7e6-4fb9-a70b-ec92fdb6625d',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: '40726c6d-35c2-4105-a7c1-6aecafb632f0',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '1824a61e-34b4-4240-b91f-9049b5fad6e4',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: '2bd6484a-0367-48a4-9c44-2c3d84497e91',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-2',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 2,
    options: [
      {
        id: '2f40784a-27d6-45de-bffc-b36e4128cd32',
        src: Brave,
        title: 'Brave',
      },
      {
        id: '59b2d48c-8004-44b5-ae17-6df1a4b71e81',
        src: Curious,
        title: 'Curious',
      },
      {
        id: 'b03f179e-119e-403d-a7f2-95a269b63310',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '1c281c91-d6a0-4032-a48c-95f8a0ff5b4f',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: 'b2080af9-712f-4fb5-a5e0-a73daa50e394',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: 'e93f312b-f249-4b94-a423-951f9d05ba54',
        src: Shy,
        title: 'Shy',
      },
      {
        id: '2592b780-38d2-4102-b6d3-4e72d726a574',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: '49ae1603-379a-4378-b812-a6d1fc6b773f',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-2',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 2,
    options: [
      {
        id: '7f5e7237-d1c2-4047-920a-c14f2984da38',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '6aa4118f-0167-4381-8ee2-2d2f39e41fe7',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '1d5ceab4-9a9a-4422-9359-ad20262cef78',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: '9e97c453-e3ed-477b-9625-a561a0d45d1e',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: 'a8d4015a-5013-4b17-ad31-24d573afbdc8',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '69f1d950-bf39-4023-8b4e-b45ed2f6abae',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: '59c89abf-4984-496a-bf8c-baa6498967a2',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: '46df8b1e-5934-456d-95b9-5b13f8513c47',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-2',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 2,
    options: [
      {
        id: '9a7839db-0dba-41e3-9fc8-e404cbbfe4f0',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: 'fbc004a2-0d6a-4e4f-a404-8709395b0e51',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: '6c50d08b-0553-41f0-be98-f08210e07488',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: 'e1cbf49d-342f-4279-aa0b-c9c838347156',
        title: 'Minions',
        src: Minions,
      },
      {
        id: '9520751b-887b-44da-9b09-b7cd3fc268e3',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: '0acb3dba-36af-44e9-bb96-bc2d91d45b99',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: '2f9835eb-4f89-458b-a2ca-32644f8dfc7e',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: '5539a146-00d8-4d32-8e3e-5418423ec83f',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: 'c70ff222-bcaf-4899-8ece-644f2baef389',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-2',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 2,
    options: [
      {
        id: 'b2d3ca07-a56c-41a3-b1dc-3ff58b7b38b8',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: '192acb39-4e79-4a23-81c7-8b24ea5d7be3',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: '7de9ca7e-b0e3-4562-83a7-c6ab940cf947',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: 'b68fcb37-1582-4c67-b5f4-7a9fa0a05c23',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: '28dafe81-0efd-4425-95b8-f103e659fee3',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: 'f21a7a5b-c2fa-4e1c-96a0-f3e750174908',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: 'a826b1f1-cb03-406c-8953-641c6a499631',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: '308484ae-f0f0-4023-8dc4-33e1cd0988c8',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: '3253f832-5939-41ae-8857-0c103f183649',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-4',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 4,
    icon: GrowupIcon,
    options: [
      {
        id: 'b4ac544f-8ded-4427-9750-a73f248a4a91',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: '77d74b06-78c7-4442-bf04-5d211fc7bcc9',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '373ac7c9-3409-4a36-a5a5-2239a0920830',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: 'f9a0ccbb-01b9-4685-84bf-cdf43f0f9b1c',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: '3a5a06eb-25b4-4259-ae4d-d12734769a12',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: '987d74a8-34ab-48f0-819c-b51706ae1bae',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: '0ffd17c3-850b-49ad-a6bc-4ef8e3a49151',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '265ff42c-008b-435a-9d39-0645036cbe95',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: '14a7ca3f-1c08-42d3-b6a4-9c6f2bf83884',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-4',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 4,
    options: [
      {
        id: '13e317ff-a410-49f2-a187-13d71c30c637',
        src: Brave,
        title: 'Brave',
      },
      {
        id: '44c28bce-3355-4e12-9991-b6c9f34002dc',
        src: Curious,
        title: 'Curious',
      },
      {
        id: 'd2e278b0-e27a-4307-8454-19a58c095694',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '1a300a05-5c6b-4e1d-a0b3-c2d39752b1ab',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: '43bd40c3-6dc1-4a7b-ac45-596ca0cab153',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: '056c0a50-3e31-4db3-93a6-d18ce9794799',
        src: Shy,
        title: 'Shy',
      },
      {
        id: '7557dfa4-ec3f-4b0b-bcdb-755f361bdd0e',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: '20c5ddd1-b991-44a7-abdf-63352df7498e',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-4',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 4,
    options: [
      {
        id: '589d9963-d94a-428e-954f-f79dc6de23fa',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '4c63026c-e061-43c3-b489-0dc9a2b13078',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '1a439618-6553-4c4c-9cb8-7a6a8b4308de',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: '5ad63256-9d9f-4bdc-9a5e-b9db1dc21283',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: 'b7123c10-46f7-4d95-8f1f-fd3105273c16',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '5326f23f-8f2e-4533-b12a-d00c23491234',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: '58790095-7f31-47a5-adb8-7326a14a88da',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: 'e237618a-bd73-4ed5-beba-5b3cb851d15c',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-4',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 4,
    options: [
      {
        id: 'da9c4509-c1cb-4ed2-bded-5d5d7d55e535',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: 'cf854e8c-2a0b-4a42-baee-20058503720c',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: 'cb901f42-c865-42dc-9e1a-2864e4304952',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: '08ff138c-393f-413c-b5b1-b3bf289f73c2',
        title: 'Minions',
        src: Minions,
      },
      {
        id: 'c6f9369e-241f-4a75-9d89-8a9243253d7f',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: '5599c234-dd4e-4f89-998b-ee3dfdecef33',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: 'f362ec47-90d9-4385-b115-edeb12cc021b',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: '1ab62b44-c87f-4ccc-9c97-11a8989c9cde',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: '9195aee9-acae-495d-ba57-e38dd180325f',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-4',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 4,
    options: [
      {
        id: 'a53ce967-5ce3-45b6-b52c-087e32eb069d',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: 'b81e1008-9b59-44a8-ad9c-59e770711341',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: 'd8c9ccac-1b49-4904-8286-a34cfe79ef03',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: '2984415e-8e35-4e3b-b92c-77987d52750e',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: 'cfe9cced-3863-4073-844f-1d44f691e921',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: '7b7c7674-ee52-4168-ab7e-403754c9758d',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: '42f7e017-0782-468d-bd05-3389b1b87404',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: '619ca0eb-1d67-4120-8470-766e9e79cffb',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: 'e38609ed-e4bf-4041-b161-52935dd3f8d1',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-5',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 5,
    icon: GrowupIcon,
    options: [
      {
        id: '48dba472-47af-4b9f-bf57-894f1bc95334',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: '2e66017c-3784-4f6f-a1c8-afbcba1e7b50',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '368979d6-db39-44e8-80ca-4f3f62305cda',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: 'c137f8ef-2606-475c-ab05-13ee4240765c',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: 'ce82bbcc-a901-47dc-90ff-b95c8e8881d5',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: '545bb950-c2d9-4537-9cfa-b1327100b20b',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: 'a9ae0e23-4e4d-4b40-a904-9e2c77c3fe59',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '3093bef98-00ef-424b-b7f0-c71287fddde3',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: 'afb21fb5-036f-4c07-bacc-715948c4c6c6',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-5',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 5,
    options: [
      {
        id: '0cf3da41-5bd6-4d2b-9305-7e7f4dad92c1',
        src: Brave,
        title: 'Brave',
      },
      {
        id: 'c606886b-ed4d-41cc-a1f4-050143a476d0',
        src: Curious,
        title: 'Curious',
      },
      {
        id: '275e2255-4c0b-4e4a-9155-e354fc011d3f',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '55bb19de-3cf5-47dc-82a8-f559ecf28bff',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: 'b7539e12-2878-4df5-885d-2ed8936f05f5',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: 'd36f34d9-fdfc-4595-a711-d7e437e15412',
        src: Shy,
        title: 'Shy',
      },
      {
        id: '04732573-a729-4b8e-9ca5-5347d78b3876',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: '2e19252c-8f05-4c8f-b95d-f36bd12e7387',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-5',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 5,
    options: [
      {
        id: 'a832d655-5a50-43d7-b40f-249aeab195c2',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '41778dac-969f-4efc-ac62-41798250b97e',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '108c8e45-01f5-4f7d-9d6c-8ebe6398dbb3',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: 'afc0cfea-d223-4c5a-a7c6-872c3cebf2d7',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: '9362bf92-b97a-4632-ac5e-46ccbaf65fb0',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '23478dfc-86ef-4830-8fd0-95dcf264218a',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: '23cc12d4-e343-4e81-8bd3-6c3349cf824a',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: '9a6f8b69-d521-4408-b88e-da3660160e72',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-5',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 5,
    options: [
      {
        id: 'edf55d6c-02d2-47a9-b498-1ef44bda0bbb',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: '112199d5-5de9-4757-9082-b57ebcb914c8',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: '020a12d3-6f8d-4a43-a53f-09bcb5c4f8ab',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: '739fc8b7-bfd9-46a5-b60f-8625a21f3185',
        title: 'Minions',
        src: Minions,
      },
      {
        id: 'd58dd782-eae4-4ea5-bb41-254b041d4482',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: 'ecef5024-7445-4fef-b8bb-aa51bb157b07',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: '0ffd2cea-6cd2-4561-a51a-358bbcf944b6',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: 'e59307cc-c5aa-42d1-9355-db45fafc3209',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: 'a7db8983-2612-4774-936d-06543f9c1f07',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-5',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 5,
    options: [
      {
        id: '80d20789-eff7-4211-b67e-11e7b0c6a19d-5',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: 'bec0aa0e-653a-43cb-af31-6f04a6dfedd3-5',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: 'a3045782-ffb2-4d7e-9891-738bdc6140c0-5',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: '483b0b38-62f6-4e54-adce-7cd2bd1ec2e1-5',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: '6e3eed2a-9895-4281-afc0-ad15a7405ed5-5',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: '476edac0-6012-41b1-b544-e53e32603cd8-5',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: '45243114-2b9e-4acf-af0c-6bf860829604-5',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: 'b253b4ee-bda6-4a9d-84ad-5daba641674c-5',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: '522c8482-a89d-4a4d-b5b0-5ed9e500cb2d-5',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  {
    id: 'aafd999a-e000-4d7d-b84e-1caf3447aa4d-6',
    pKey: 'growUp',
    mType: 'memory',
    title: 'When I Grow Up',
    detailTitle: 'When I Grow Up I Want To Be',
    formType: 'image',
    type: 'system',
    age: 6,
    icon: GrowupIcon,
    options: [
      {
        id: '0b3ff965-8ef6-4b68-b741-3e75e3c51ddb-6',
        src: Policeman,
        title: 'Policeman',
      },
      {
        id: 'c4572a74-f4e0-41b7-a47b-e28c764f5cde-6',
        src: Astronaut,
        title: 'Astronaut',
      },
      {
        id: '22ebf049-6750-47f6-ab59-a555517c8f7f-6',
        src: Ballet,
        title: 'Ballet Dancer',
      },
      {
        id: 'd930291c-fc36-49e5-a139-f5465e5a338c-6',
        src: Engineer,
        title: 'Engineer',
      },
      {
        id: '8db3e546-11b1-4769-a5fc-5780f97a84bb-6',
        src: Scientist,
        title: 'Scientist',
      },
      {
        id: 'd140dc88-26b9-476f-a84b-9b2c52fc3389-6',
        src: Soccer,
        title: 'Soccer Player',
      },
      {
        id: 'f4be3dc8-e45f-4ec7-9c0e-552a1231b247-6',
        src: Teacher,
        title: 'Teacher',
      },
      {
        id: '3df9389a-a3b2-458d-b235-b76cb8669d09-6',
        src: Tennis,
        title: 'Tennis Player',
      },
      {
        id: 'ed3d64d9-902d-44e7-a10e-9a50ab5fecb3-6',
        src: Fireman,
        title: 'Fireman',
      },
    ],
  },
  {
    id: '3d1b4d40-7e3d-445f-aa53-2906d279758e-6',
    pKey: 'personality',
    formType: 'image',
    type: 'system',
    title: 'Personality',
    mType: 'memory',
    icon: Personality,

    age: 6,
    options: [
      {
        id: '47d7f39e-a2ae-43b0-bb0e-98efb912ec9b-6',
        src: Brave,
        title: 'Brave',
      },
      {
        id: '12cadc94-bb67-4dac-ab6b-95ce1494ac60-6',
        src: Curious,
        title: 'Curious',
      },
      {
        id: 'ea38f10d-f1ba-4478-8a8a-b256a6318e23-6',
        src: Happy,
        title: 'Happy',
      },
      {
        id: '43fca091-e7b9-4c49-96e9-86992c1cd2d3-6',
        src: Naughty,
        title: 'Naughty',
      },
      {
        id: 'bac907ce-9715-489b-be25-236b626d57c0-6',
        src: Quiet,
        title: 'Quiet',
      },
      {
        id: '1d6a2091-9bf8-4cc9-b633-4fd3555f26d6-6',
        src: Shy,
        title: 'Shy',
      },
      {
        id: 'cac85219-e055-4837-a342-3fd3510f4f4c-6',
        src: SmartyPants,
        title: 'Smarty Pants',
      },
      {
        id: '4c120f39-d87c-4ce2-ac2b-8edc464cca19-6',
        src: Talkative,
        title: 'Talkative',
      },
    ],
  },
  {
    id: '023306d1-bf8b-40ee-a9a8-2ddfd9017d08-6',
    pKey: 'favoriteToy',
    formType: 'image',
    type: 'system',
    detailTitle: 'My favorite toy is',
    title: 'Favorite Toy',
    mType: 'memory',
    icon: FavToyIcon,

    age: 6,
    options: [
      {
        id: 'e72a81b0-20f3-4698-a242-1bd10d4c4117-6',
        src: Blocks,
        title: 'Blocks',
      },
      {
        id: '488643dd-5688-4d1b-be78-0405a8300265-6',
        src: Cars,
        title: 'Cars',
      },

      {
        id: '3c8a2ab0-dbb5-4e3e-a805-0ed90ebd563a-6',
        src: Dolls,
        title: 'Dolls',
      },
      {
        id: '0f153b0e-b415-4304-98e9-93af53e170fe-6',
        src: Dumptruck,
        title: 'Dumptruck',
      },
      {
        id: '660ec40a-e36f-4ce6-ae0e-1b8fb42a0951-6',
        src: Firetruck,
        title: 'Firetruck',
      },
      {
        id: '1dfd2fee-81a0-4ec6-9adc-4e7110ed34a4-6',
        src: Playdoh,
        title: 'Playdoh',
      },
      {
        id: '7bc11d9a-f0e8-481b-9b95-7191d65802ae-6',
        src: Puzzles,
        title: 'Puzzles',
      },
      {
        id: '392a0ae9-9bbb-4ec8-8df9-d6976e9117e9-6',
        src: Softoys,
        title: 'Soft Toys',
      },
    ],
  },

  {
    id: 'e2357d01-54de-4616-97f0-9bd0ece4fd21-6',
    pKey: 'atHalloween',
    title: 'Halloween Costume',
    formType: 'image',
    type: 'system',
    mType: 'memory',
    icon: HalloweenIcon,
    desc: '',
    age: 6,
    options: [
      {
        id: 'c8229d02-157a-4ffc-9f27-7a0f3dc2d4ac',
        title: 'Avengers',
        src: Avengers,
      },
      {
        id: '699d6252-e856-4460-b5d0-c1269c184642',
        title: 'Dinosaur',
        src: Dinosaur,
      },
      {
        id: 'eb8c9461-15a2-4b93-b7d6-07fb6df8f1b6',
        title: 'Frozen',
        src: Frozen,
      },
      {
        id: 'd2bbb359-75ca-47be-9804-82bdd308b24b',
        title: 'Minions',
        src: Minions,
      },
      {
        id: '4cb14af1-9060-408b-bcfb-ee01ee6a9b98',
        title: 'Ninja Turtle',
        src: NinjaTurtle,
      },
      {
        id: '84aaa4ef-bbc5-48b0-8b87-c92991234105',
        title: 'Pirate',
        src: Pirate,
      },
      {
        id: '750be589-af24-447b-a26f-bf432f3eac47',
        title: 'Rabbit',
        src: Rabbit,
      },
      {
        id: 'cbb3ee8e-da1a-4e29-859b-fc0daa50f6c6',
        title: 'Star Wars',
        src: StarWars,
      },
      {
        id: '949bf346-daab-4122-ad92-4a0cfed73019',
        title: 'Superhero',
        src: Superhero,
      },
    ],
  },

  {
    id: '01d37440-ad06-4b9d-87b0-6cb2dc9f55be-6',
    pKey: 'rhyme',
    formType: 'image',
    type: 'system',
    title: 'Favorite Rhyme',
    mType: 'memory',
    icon: FavRhymeIcon,

    desc: '',
    age: 6,
    options: [
      {
        id: 'ca234021-7647-46e5-8747-587cdcec3e0d',
        src: BaaBaa,
        title: 'Baa Baa Black Sheep',
      },
      {
        id: 'bec0aa0e-653a-43cb-af31-6f04a6dfedd3-6',
        src: Diddle,
        title: 'Hey Diddle Diddle',
      },
      {
        id: 'a3045782-ffb2-4d7e-9891-738bdc6140c0-6',
        src: Humpty,
        title: 'Humpty Dumpty',
      },
      {
        id: '483b0b38-62f6-4e54-adce-7cd2bd1ec2e1-6',
        src: ItsyBitsy,
        title: 'Itsy Bitsy Spider',
      },
      {
        id: '6e3eed2a-9895-4281-afc0-ad15a7405ed5-6',
        src: LittleBoBeep,
        title: 'Little Bo Beep',
      },
      {
        id: '476edac0-6012-41b1-b544-e53e32603cd8-6',
        src: TenBed,
        title: 'Ten in the Bed',
      },
      {
        id: '45243114-2b9e-4acf-af0c-6bf860829604-6',
        src: MaryLamb,
        title: 'Mary Had a Little Lamb',
      },
      {
        id: 'b253b4ee-bda6-4a9d-84ad-5daba641674c-6',
        src: TeaPot,
        title: "I'm a Little Tea Pot",
      },
      {
        id: '522c8482-a89d-4a4d-b5b0-5ed9e500cb2d-6',
        src: LondonBridge,
        title: 'London Bridge is Falling Down',
      },
    ],
  },
  // {
  //   pKey: 7,
  //   type: "text",
  //   title: "Favorite Superhero",
  //   age: 3,
  // },

  // {
  //   id: 4,
  //   type: "text",
  //   title: "Halloween",
  // },
  // {
  //   id: 5,
  //   type: "text",
  //   title: "Christmas",
  //   desc: "Due to a vast number of sanitary losses [wounded] among the invaders, the maternity clinic in the occupied Luhansk is being used for the treatment of the Russian military. ",
  // },
];
