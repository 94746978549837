import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { Axios } from '../../../api/axios';

export const getPeoplePhotosList = createAsyncThunk(
  'fetch/school-people-photos-list',
  async (data, thunkAPI) => {
    const { rosterId } = data;
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const response = await Axios.post('/roster-in-photos', {
        schoolId,
        rosterId,
      });

      let mergedPhotos = [];
      Object.keys(response.data).forEach((key) => {
        mergedPhotos = mergedPhotos.concat([...response.data[key]]);
      });

      // Load url for all photos
      let photoPromises = mergedPhotos.map((photo) => {
        if (photo.url) {
          const firebaseApp = getApp();
          const storage = getStorage(
            firebaseApp,
            `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-deziser-sotohp`,
          );
          return getDownloadURL(ref(storage, photo.url)).catch((error) => {
            console.log(`Error retrieving photo URL: ${error}`);
            return null;
          });
        } else {
          return null;
        }
      });

      response.photos = [];

      let photoUrlResult = await Promise.all(photoPromises);
      photoUrlResult.forEach((url, index) => {
        response.photos[index] = { ...mergedPhotos[index], url: url };
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const likePeopleSchoolPhoto = createAsyncThunk(
  'post/like-school-people-photo',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const likedPhotos = data.photos;
      const response = await Axios.post('/photos-like/school', { schoolId, data: likedPhotos });
      return { ...response, likedPhotos };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deletePeopleSchoolPhoto = createAsyncThunk(
  'post/delete-school-people-photo',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const deletedPhotos = data.photos;
      const response = await Axios.delete('/photos/school', {
        data: { schoolId, data: deletedPhotos },
      });
      return { ...response, deletedPhotos };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
