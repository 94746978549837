import React, { useState } from 'react';
import { Box, Typography, Stack, Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../components/Spinner';
import AddMemorySection from './add-memory/AddMemorySection';
import MemoriesSection from './memories/MemoriesSection';
import AddEditMyOwnMemoryDialog from './add-memory/AddEditMyOwnMemoryDialog';
import PersonalizeMemoryDialog from './add-memory/PersonalizeMemoryDialog';
import DismissibleBanner from '../../../../../components/global/DismissibleBanner';
import Boy2 from '../../../../../assets/boy2.jpg';

const PersonalMemories = ({ handleUpgradePremiumDialog, handleToggleSamplePagesDialog }) => {
  const { isLoading, totalRemainingMemoriesCount } = useSelector(
    (state) => state.parent.schoolMemories,
  );

  const [addEditMyOwnMemoryDialog, setAddEditMyOwnMemoryDialog] = useState({
    open: false,
    type: 'ADD',
    memory: {},
  });
  const [personalizeMemoryDialog, setPersonalizeMemoryDialog] = useState({
    open: false,
    memory: {},
    type: 'ADD',
  });

  const handleAddEditMyOwnMemoryDialogToggle = (open, type, memory = {}) => {
    if (totalRemainingMemoriesCount > 0) {
      setAddEditMyOwnMemoryDialog({ open, type, memory });
    } else {
      handleUpgradePremiumDialog(true);
    }
  };

  const handlePersonalizeMemoryDialogToggle = (open, type, memory = {}) => {
    if (totalRemainingMemoriesCount > 0) {
      setPersonalizeMemoryDialog({ open, memory, type });
    } else {
      handleUpgradePremiumDialog(true);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Box sx={{ mt: '20px' }}>
            <DismissibleBanner
              Content={() => (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'rgb(245,246,247)',
                    p: 2,
                    border: '1px solid rgba(225,225,225)',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 2, md: 4 },
                  }}
                >
                  <Box
                    component={'img'}
                    src={Boy2}
                    alt=''
                    sx={{
                      width: { xs: '75px', md: '50px' },
                      height: { xs: '75px', md: '50px' },
                      ml: { xs: 0, md: '10px' },
                    }}
                  />
                  <Stack alignItems={'flex-start'}>
                    <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
                      Fill your child's yearbook with cherished memories of their growing-up years.
                      From their beloved nursery rhymes and favorite Halloween costumes to unique
                      quirks and unforgettable milestones, share the stories that paint a picture of
                      their unique journey.
                    </Typography>
                    <Button
                      variant='text'
                      onClick={() => handleToggleSamplePagesDialog(true, 'PERSONAL_MEMORIES')}
                      sx={{
                        p: 0,
                        fontSize: 14,
                        letterSpacing: -0.18,
                        mt: '4px',
                      }}
                    >
                      View Sample Pages
                    </Button>
                  </Stack>
                </Box>
              )}
              bannerBgColor='rgb(244,245,246)'
              showCloseBtn={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Box sx={{ mb: '30px', mt: '32px' }}>
            {isLoading && <Spinner open />}
            {/* <Typography sx={{ fontWeight: 700, fontSize: { xs: 18, md: 24 }, letterSpacing: -0.36 }}>
              Select from the memories below or add your own
            </Typography> */}
            <AddMemorySection
              handleAddEditMyOwnMemoryDialogToggle={handleAddEditMyOwnMemoryDialogToggle}
              handlePersonalizeMemoryDialogToggle={handlePersonalizeMemoryDialogToggle}
            />
            <MemoriesSection
              handleAddEditMyOwnMemoryDialogToggle={handleAddEditMyOwnMemoryDialogToggle}
              handlePersonalizeMemoryDialogToggle={handlePersonalizeMemoryDialogToggle}
            />

            {addEditMyOwnMemoryDialog.open && (
              <AddEditMyOwnMemoryDialog
                open={addEditMyOwnMemoryDialog.open}
                handleClose={() => handleAddEditMyOwnMemoryDialogToggle(false, 'ADD')}
                memory={addEditMyOwnMemoryDialog.memory}
                type={addEditMyOwnMemoryDialog.type}
              />
            )}
            {personalizeMemoryDialog.open && (
              <PersonalizeMemoryDialog
                open={personalizeMemoryDialog.open}
                handleClose={() => handlePersonalizeMemoryDialogToggle(false, 'ADD')}
                memory={personalizeMemoryDialog.memory}
                type={personalizeMemoryDialog.type}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalMemories;
