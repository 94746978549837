import React from 'react';
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

const BuyPhotosWarningDialog = ({ open, handleClose, handlePurchase }) => {
    return (
        <Dialog
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
            sx={{
                '& .MuiPaper-root': {
                    m: { xs: '12px', sm: '32px' },
                },
            }}
        >
            <DialogTitle sx={{ px: '30px' }}>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close
                        sx={{
                            fontSize: '18px',
                        }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Typography sx={{ p: '10px 20px' }}>You have to Purchase to upload more photos</Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    px: '30px',
                    justifyContent: 'space-between',
                    py: '20px',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Button variant='contained' onClick={handlePurchase}>
                    Purchase more photos
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default BuyPhotosWarningDialog;