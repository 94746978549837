import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Axios } from './api/axios';

import Spinner from './components/Spinner';

import { updateMember } from './reducers/memberReducer';
import { getCartThunk } from './reducers/services/cart';

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (!user) {
          throw Error('No user found!');
        }
        let token = await user.getIdToken();
        let member = await Axios.get('/signin', {
          headers: {
            Authorization: token,
          },
        });
        dispatch(updateMember({ member: member }));
        dispatch(
          getCartThunk({
            id: member.id,
          }),
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Pass ERR_NETWORK error to prevent race condition
        navigate(`/signin`, {
          ...(error.code === 'ERR_NETWORK' ||
            (error.code === 'ERR_BAD_REQUEST' && {
              state: {
                error: { type: 'error', message: error.message },
              },
            })),
        });
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Spinner
        open={loading}
        sx={{
          background: 'white',
        }}
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
