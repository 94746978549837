import { createContext, useContext, useState } from "react";

const initialState = {
    snackbarOpen: false,
    variant: 'info',
    message: '',
    handleSnackbarOpen: () => { },
    handleSnackbarClose: () => { },
}

const SnackbarContext = createContext(initialState);

const SnackbarProvider = ({ children }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('info');

    const handleSnackbarOpen = ({ open, message, variant }) => {
        setSnackbarOpen(open);
        setMessage(message || '');
        setVariant(variant || 'info');
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setTimeout(() => {
            setMessage('');
            setVariant('info');
        }, 100)

    }

    return (
        <SnackbarContext.Provider value={{ snackbarOpen, handleSnackbarOpen, handleSnackbarClose, message, variant }}>
            {children}
        </SnackbarContext.Provider>
    )
}

export const useSnackbar = () => {
    return useContext(SnackbarContext);
}

export default SnackbarProvider;