import { importWithPreload } from '../utils/lazyLoadFuncs';

export const OrderSummary = importWithPreload(() =>
  import(/* webpackChunkName: 'OrderSummary' */ '../pages/orderSummary/OrderSummary'),
);

//* School
export const SchoolPages = importWithPreload(() =>
  import(/* webpackChunkName: 'SchoolPages' */ '../pages/school/SchoolPages'),
);
export const RegisterSchool = importWithPreload(() =>
  import(/* webpackChunkName: 'RegisterSchool' */ '../pages/school/registration/RegisterSchool'),
);

//* Parent
export const MemoryBookManager = importWithPreload(() =>
  import(
    /* webpackChunkName: 'MemoryBookManager' */ '../pages/parent/memoryBooks/MemoryBookManager'
  ),
);
export const ParentPages = importWithPreload(() =>
  import(/* webpackChunkName: 'ParentsPages' */ '../pages/parent/ParentPages'),
);

/**
 * Function to preload chunk
 */
export const fetchSchoolPages = () => {
  SchoolPages.preload();
};

export const fetchRegisterSchool = () => {
  RegisterSchool.preload();
};

export const fetchOrderSummary = () => {
  OrderSummary.preload();
};

export const fetchMemoryBookManager = () => {
  MemoryBookManager.preload();
};

export const fetchParentPages = () => {
  ParentPages.preload();
};
