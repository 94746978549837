import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const InfoAlertBox = ({
  Icon,
  title,
  subtitle = false,
  alertVariant = "info",
  alertSx = {},
  alertTitleSx = {}
}) => {
  return (
    <Alert
      variant={alertVariant}
      sx={{
        borderRadius: '10px',
        borderLeft: '8px solid #0065CC',
        bgcolor: '#F8FBFF',
        '& .MuiAlert-icon': {
          padding: '12px 0'
        },
        ...alertSx
      }}
      icon={<Icon sx={{ fontSize: alertTitleSx.fontSize ? alertTitleSx.fontSize : '22px' }} />}
    >
      <AlertTitle
        sx={{
          fontSize: '22px',
          fontWeight: 500,
          color: 'rgb(38,38,40)',
          ...(!subtitle && { mb: 0 }),
          ...alertTitleSx
        }}
      >
        {title}
      </AlertTitle>
      {subtitle && subtitle}
    </Alert>
  );
};

export default InfoAlertBox;
