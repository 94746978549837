import React, { useEffect, useState } from 'react';
import {
  Box,
  Fab,
  // ImageList,
  Stack,
  Button,
  Typography,
  ImageListItem,
  useTheme,
  useMediaQuery,
  // FormControl,
  // Select,
  // MenuItem,
  // InputLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PhotoCaptionDialog from './PhotoCaptionDialog';
import { Add, KeyboardArrowUp } from '@mui/icons-material';
import SchoolPhotoItem from './SchoolPhotoItem';
import DismissibleBanner from '../../../../../components/global/DismissibleBanner';
// import Girl3 from '../../../../../assets/girl3.jpg';
import { SelectedSchoolPhotos } from './SelectedSchoolPhotos';
// import PreschoolArt from '../../../../../assets/preschoolArt.jpg';
// import PreschoolSwimming from '../../../../../assets/preschoolSwimming.jpg';
// import PreschoolSoccer from '../../../../../assets/preschoolSoccer.jpg';
import { v4 as uuidv4 } from 'uuid';
import { uploadPersonalPhotosThunk } from '../../../../../reducers/parentReducer';
import PersonalPhotoItem from '../personal-photos/PersonalPhotoItem';
import Masonry from '@mui/lab/Masonry';
import Select20Memories from '../../../../../assets/1.jpg';
import MemoriesInclude from '../../../../../assets/2.jpg';
import FamilyPhotosNotIncluded from '../../../../../assets/3.jpg';
import InAdditionTo20Memories from '../../../../../assets/4.jpg';
import UpgradeToPremium from '../../../../../assets/5.jpg';

const drawerWidth = 240;

const SchoolPhotos = ({ handleUpgradePremiumDialog, handleToggleSamplePagesDialog }) => {
  const dispatch = useDispatch();
  const schoolMemories = useSelector((state) => state.parent.schoolMemories);
  const theme = useTheme();
  const isDownToMd = useMediaQuery(theme.breakpoints.down('md'));

  const [showSelectedPhotos, setShowSelectedPhotos] = useState(false);
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  const [photoCaptionDialog, setPhotoCaptionDialog] = useState({ open: false, photo: {} });
  const [schoolPhotos, setSchoolPhotos] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All Photos');

  const handleTogglePhotoCaptionDialog = (open, photo) => {
    setPhotoCaptionDialog({ open, photo });
  };

  const filterSchoolPhotos = (filter = selectedFilter) => {
    let filteredSchoolPhotos = [];

    switch (filter) {
      case 'Recommended':
        filteredSchoolPhotos = schoolMemories?.photos?.filter((p) => p.isRecommended) || [];
        break;
      case 'Solo':
        filteredSchoolPhotos = schoolMemories?.photos?.filter((p) => p.faceCount === 1) || [];
        break;
      case 'Friends':
        filteredSchoolPhotos =
          schoolMemories?.photos?.filter((p) => p.faceCount > 1 && p.faceCount < 5) || [];
        break;
      case 'Group':
        filteredSchoolPhotos = schoolMemories?.photos?.filter((p) => p.faceCount >= 5) || [];
        break;
      case 'All Photos':
        filteredSchoolPhotos = schoolMemories?.photos || [];
        break;
      default:
        break;
    }

    filteredSchoolPhotos = filteredSchoolPhotos.filter((p) => p.confidence >= 95);
    filteredSchoolPhotos.sort((a, b) => b.confidence - a.confidence);

    setSelectedFilter(filter);
    setSchoolPhotos(filteredSchoolPhotos);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function isUploadedFilesAllImages(files) {
    return Array.from(files).every((file) => file.type.startsWith('image/'));
  }
  const doesHavePhotosToUpload = (newUploadCount) => {
    if (newUploadCount > schoolMemories.totalRemainingMemoriesCount) {
      return false;
    } else {
      return true;
    }
  };

  const handleUploadPersonalPhotos = (e) => {
    e.preventDefault();

    const files = e.target?.files ? [...e.target.files] : [];
    if (files.length > 0) {
      if (isUploadedFilesAllImages(files)) {
        if (doesHavePhotosToUpload(files.length)) {
          let photos = [];
          files.forEach((file) => {
            let photoId = uuidv4();
            photos.push({
              id: photoId,
              caption: '',
              file,
              isSchoolPersonal: true,
            });
          });
          dispatch(uploadPersonalPhotosThunk({ photos }));
        } else {
          handleUpgradePremiumDialog(true);
        }
      }
    }
  };

  useEffect(() => {
    filterSchoolPhotos();
    //eslint-disable-next-line
  }, [schoolMemories?.photos]);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setShowScrollToTopButton(true);
      } else {
        setShowScrollToTopButton(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
    //eslint-disable-next-line
  }, []);

  if (schoolMemories.isLoading) {
    return <></>;
  }

  return (
    <Box>
      <Box sx={{ mt: '20px' }}>
        <DismissibleBanner
        // Content={() => (
        //   <Box
        //     sx={{
        //       width: '100%',
        //       bgcolor: 'rgb(245,246,247)',
        //       p: 2,
        //       border: '1px solid rgba(225,225,225)',
        //       display: 'flex',
        //       flexDirection: { xs: 'column', md: 'row' },
        //       gap: { xs: 2, md: 4 },
        //     }}
        //   >
        //     <Box
        //       component={'img'}
        //       src={Girl3}
        //       alt=''
        //       sx={{
        //         width: { xs: '75px', md: '50px' },
        //         height: { xs: '75px', md: '50px' },
        //         ml: { xs: 0, md: '10px' },
        //       }}
        //     />
        //     <Stack alignItems={'flex-start'}>
        //       <Box>
        //         <Typography
        //           sx={{
        //             fontSize: '20px',
        //             lineHeight: '20px',
        //             fontWeight: 700,
        //             letterSpacing: -0.45,
        //           }}
        //         >
        //           Select or add upto 20 Memories
        //         </Typography>
        //         <Typography
        //           sx={{
        //             mt: '4px',
        //             fontSize: '14px',
        //             lineHeight: '20px',
        //             letterSpacing: -0.36,
        //             fontWeight: 500,
        //             bgcolor: 'yellow',

        //             // color: 'darkRed',
        //           }}
        //         >
        //           Memories = School Photos + Personal Note + Growing up Memories
        //         </Typography>
        //       </Box>
        //       {/* <Button
        //         variant='text'
        //         onClick={() => handleToggleSamplePagesDialog(true, 'SCHOOL_PHOTOS')}
        //         sx={{
        //           p: 0,
        //           fontSize: 14,
        //           letterSpacing: -0.18,
        //           mt: '4px',
        //         }}
        //       >
        //         View Sample Pages
        //       </Button> */}
        //       <Typography
        //         sx={{
        //           mt: '4px',
        //           fontSize: '14px',
        //           lineHeight: '20px',
        //           letterSpacing: -0.36,
        //           color: 'GrayText',
        //         }}
        //       >
        //         Note: Family photos are in addition to the 20 memories
        //       </Typography>
        //     </Stack>
        //   </Box>
        // )}
        // bannerBgColor='rgb(244,245,246)'
        // showCloseBtn={false}
        />
      </Box>

      <Box sx={{ mb: '30px', position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            overflowX: 'auto',
            gap: '20px',
            mt: '40px',
          }}
        >
          {['All Photos'].map((category, i) => (
            <Box
              key={i}
              onClick={() => filterSchoolPhotos(category)}
              sx={{
                minWidth: 'max-content',
                cursor: 'pointer',
              }}
            >
              <Stack direction='row' gap='2px' alignItems='center' sx={{ ml: '10px' }}>
                <Box
                  sx={{
                    fontSize: '16px',
                    fontWeight: selectedFilter === category ? 700 : 500,
                    color: selectedFilter === category ? 'rgb(45,45,45)' : '#929292',
                    userSelect: 'none',
                    letterSpacing: -0.36,
                  }}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Box>
              </Stack>
            </Box>
          ))}
          <Box
            onClick={() => filterSchoolPhotos('UploadYourOwn')}
            sx={{
              minWidth: 'max-content',
              cursor: 'pointer',
            }}
          >
            <Box sx={{ ml: 'auto' }}>
              <Button
                variant='text'
                sx={{
                  fontSize: '16px',
                  height: 36,
                  fontWeight: 500,
                  letterSpacing: -0.36,
                }}
              >
                Upload Your Own
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: '16px' }}>
          {selectedFilter === 'UploadYourOwn' ? (
            <>
              <Masonry variant='masonry' columns={isDownToMd ? 2 : 3} gap={3}>
                {/* <Masonry variant='masonry' columns={3} gap={3}> */}
                <ImageListItem>
                  <Box
                    component={'label'}
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Add sx={{ fontSize: 40 }} />
                    <Typography sx={{ mt: '8px' }}>Select photos to upload</Typography>
                    <Typography
                      align='center'
                      sx={{
                        mt: '20px',
                        width: '80%',
                        fontSize: '13px',
                        color: 'GrayText',
                        lineHeight: '18px',
                      }}
                    >
                      You can include your child's art, photos from after school activities or any
                      other photos you wish to include.
                    </Typography>
                    <input
                      hidden
                      accept='image/*'
                      multiple
                      type='file'
                      onChange={handleUploadPersonalPhotos}
                    />
                  </Box>
                </ImageListItem>
                {/* <ImageListItem>
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={PreschoolArt} alt='' style={{ width: '100%' }} />
                  </Box>
                </ImageListItem>
                <ImageListItem>
                  <Box
                    sx={{
                      width: '100%',
                      aspectRatio: 1,
                      border: '1px solid rgb(225,225,225)',
                      bgcolor: 'rgb(241,242,243)',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={PreschoolSoccer} alt='' style={{ width: '100%' }} />
                  </Box>
                </ImageListItem> */}
                {/* </Masonry>
              <Masonry columns={3} gap={2} sx={{ mt: '3px' }}> */}
                {schoolMemories.schoolPersonal.map((photo, i) => (
                  <PersonalPhotoItem key={i} photo={photo} isSchoolPersonal={true} />
                ))}
              </Masonry>
            </>
          ) : schoolPhotos.length === 0 ? (
            <Box
              sx={{
                textAlign: 'left',
                ml: '10px',
                mt: '20px',
                fontSize: '16px',
                color: 'GrayText',
              }}
            >
              There are no photos!
            </Box>
          ) : (
            <Box sx={{ pb: '180px' }}>
              <Box
                sx={{
                  borderTopLeftRadius: '13px',
                  borderTopRightRadius: '13px',
                  overflow: 'hidden',
                }}
              >
                <Masonry columns={3} gap={2} sx={{ mt: '3px' }}>
                  {schoolPhotos.map((photo, i) => (
                    <React.Fragment key={i}>
                      {breakImages.some((breakImage) => breakImage.index === i) && (
                        <img
                          src={breakImages.find((breakImage) => breakImage.index === i).url}
                          alt='Break Images'
                        />
                      )}
                      <SchoolPhotoItem
                        photo={photo}
                        handleUpgradePremiumDialog={handleUpgradePremiumDialog}
                      />
                    </React.Fragment>
                  ))}
                </Masonry>
              </Box>
              <Fab
                variant='extended'
                disableTouchRipple
                onClick={() => {
                  setShowSelectedPhotos((prev) => !prev);
                }}
                sx={(theme) => ({
                  position: 'fixed',
                  bottom: '25px',
                  left: {
                    xs: '30px', // Apply for xs and down
                    sm: `calc(${drawerWidth}px + 40px)`, // Apply from sm and up
                  },
                  background: '#fff',
                  cursor: 'pointer',
                })}
              >
                {showSelectedPhotos ? 'Hide Selected' : 'Show Selected'}
              </Fab>

              {/* <Fab
                variant='extended'
                disableTouchRipple
                onClick={() => {
                  setShowSelectedPhotos((prev) => !prev);
                }}
                sx={{
                  position: 'fixed',
                  bottom: '25px',
                  left: 30,
                  // left: `calc(${drawerWidth}px + 40px)`,

                  background: '#fff',
                  cursor: 'pointer',
                }}
              >
                {showSelectedPhotos ? 'Hide Selected' : 'Show Selected '}
              </Fab> */}
            </Box>
          )}

          {showScrollToTopButton && (
            <Fab
              variant='extended'
              disableTouchRipple
              onClick={scrollToTop}
              sx={{
                position: 'fixed',
                bottom: '25px',
                left: '50%',
                // right: 50,
                transform: 'translateX(-50 %)',
                background: '#fff',
                cursor: 'pointer',
              }}
            >
              <KeyboardArrowUp sx={{ mr: 1 }} />
              To Top
            </Fab>
          )}
        </Box>

        <PhotoCaptionDialog
          photoCaptionDialog={photoCaptionDialog}
          handleTogglePhotoCaptionDialog={handleTogglePhotoCaptionDialog}
          photo={photoCaptionDialog.photo}
        />
      </Box>

      {showSelectedPhotos && (
        <SelectedSchoolPhotos
          open={showSelectedPhotos}
          handleClose={() => setShowSelectedPhotos(false)}
          photos={schoolPhotos}
          width={drawerWidth}
        />
      )}
    </Box>
  );
};

const breakImages = [
  { index: 0, url: Select20Memories },
  { index: 9, url: MemoriesInclude },
  { index: 18, url: FamilyPhotosNotIncluded },
  { index: 27, url: InAdditionTo20Memories },
  { index: 36, url: UpgradeToPremium },
];

export default SchoolPhotos;
