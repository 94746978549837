import { v4 } from 'uuid';

export function isObject(obj) {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function checkPasswordFormat(pwd) {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return re.test(pwd);
}

export function hasUpperCase(str) {
  return /[A-Z]/.test(str);
}

export function hasLowerCase(str) {
  return /[a-z]/.test(str);
}

export function hasNumber(str) {
  return /\d/.test(str);
}

export function calcAge(dob) {
  return new Date(new Date() - new Date(dob)).getFullYear() - 1970;
}

export function getMemoryBookPrice(markup = 0, type) {
  console.log('markup is', markup);
  if (type === 'softcover') {
    return parseFloat(30 + parseFloat(markup)).toFixed(2);
  }
  return parseFloat(40 + parseFloat(markup)).toFixed(2);
}
export function generateArrayOfYears(minYear) {
  var max = new Date().getFullYear();
  var min = max - minYear;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function generateArrayOfDays() {
  var days = [];
  for (var i = 1; i <= 31; i++) {
    days.push(i);
  }
  return days;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const pluralHelper = (length, singular, plural) => (length === 1 ? singular : plural);

export const formatAmount = (amount, currency) => {
  amount = Number(amount);
  if (isNaN(amount)) {
    throw new Error('Invalid amount');
  }

  currency = currency ? String(currency) : '$';
  if (currency.trim().length === 0) {
    throw new Error('Invalid currency');
  }

  const formattedAmount = amount.toFixed(2);
  const parts = formattedAmount.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result = currency + integerPart + '.' + parts[1];

  return result;
};

export const coverType = (id) => {
  if (id === 0) return 'Soft Cover';
  if (id === 1) return 'Perfect Binding';
  return 'Hard Cover';
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const getRandomColor = () => {
  // Generate a random hex color
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getSchoolProfileFilePath = ({ schoolId, member, associationId, profileData }) => {
  // file Format: schoolId/uid/associationId/fileName_schoolId_GroupId_creationDate_Latitude_Longitude_Orietation_AlbumId_AlbumTitle_MediaType_Source_rosterID.jpg
  const path = `${schoolId}/${member.id}/${associationId}/${v4()}_${schoolId}_${profileData.groupId
    }_${new Date().getTime()}_0_0_1_0_0_jpg_website_${profileData.id}.jpg`;

  return path;
};

/**
 *
 * @param {object} member = member.member
 * @param {object} activeChild = member.activeChild
 * @returns
 */
export const getParentProfileFilePath = ({ member, activeChild }) => {
  // file Format: uid/uid/R-5/fileName_schoolId_GroupId_creationDate_Latitude_Longitude_Orietation_AlbumId_AlbumTitle_MediaType_Source_rosterID.jpg
  const path = `${member.id}/${member.id}/R-5/${v4()}_${activeChild?.schoolId}_${activeChild?.groupId
    }_${new Date().getTime()}_0.0_0.0_6_profile_Profile_0_website_${activeChild?.rosterId}.jpg`;

  return path;
};

/**
 *
 * @param {object} currentAlbum = {id, title}
 * @param {string} photoId = uuidv4()
 * @param {object} member = member.member
 * @param {object} activeChild = member.activeChild
 * @returns
 */
export const getParentPhotoFilePath = ({ currentAlbum, photoId, member, activeChild }) => {
  // file Format: uid/uid/R-5/fileName_schoolId_GroupId_creationDate_Latitude_Longitude_Orietation_AlbumId_AlbumTitle_MediaType_Source_rosterID@np.jpg
  const path = `${member.id}/${member.id}/R-5/${photoId}_${activeChild?.schoolId}_${activeChild?.groupId
    }_${new Date().getTime()}_0.0_0.0_6_${currentAlbum.id}_0_website_${activeChild?.rosterId
    }@np.jpg`;

  return path;
};
