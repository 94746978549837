import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Box, InputBase, Snackbar, Typography } from '@mui/material';

import Spinner from '../../components/Spinner';
import LogoBlack from '../../components/global/LogoBlack';
import NextArrowButton from '../../components/global/NextArrowButton';

import { Axios } from '../../api/axios';
import { validateEmail } from '../../utils/functions';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.post(`/reset/password`, {
        email,
      });
      if (response.success) {
        navigate(`/signin`, {
          state: {
            snackbar: {
              type: 'success',
              message: response.message,
            },
          },
        });
      }
    } catch (error) {
      setMessage({
        ...message,
        message: error.message,
        type: 'error',
      });
      setAlertOpen(true);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setAlertOpen(false);
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  return (
    <Box
      display='flex'
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      flexDirection='column'
      alignItems={'center'}
      height={'100vh'}
    >
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={message?.type}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        width={{ sm: '60vw' }}
        py={{ xs: '40px', sm: '30px' }}
        boxShadow={{ xs: '0', sm: '0px 4px 24px 0px rgba(0, 0, 0, 0.04)' }}
        borderRadius={'20px'}
      >
        <Box sx={{ height: '40px' }}>
          <LogoBlack />
        </Box>

        <Spinner open={isLoading} />

        <Box
          sx={{ width: '100%', mt: '40px' }}
          display={'flex'}
          justifyContent='center'
          flexDirection={'column'}
        >
          <Typography textAlign={'center'} variant='h4' mb={4} fontWeight={400}>
            Forgot Password
          </Typography>
          {/* Input */}
          <Box display={'flex'} justifyContent='center'>
            <InputBase
              variant='leftborder'
              type='email'
              placeholder='Enter Your Email'
              sx={{ width: '100%', maxWidth: '360px' }}
              field='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          {/* Button */}
          <Box
            sx={{
              width: '100%',
              mx: 'auto',
              mt: '20px',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems='center'
          >
            <NextArrowButton
              text='Reset My Password'
              handleClick={handleForgotPassword}
              disabled={!validateEmail(email)}
            />
          </Box>
          {/* To Signin */}
          <Box
            sx={{ width: { xs: '100%', md: '100%' }, mt: '20px' }}
            display={'flex'}
            flexDirection='column'
            gap='10px'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant={'body2'} align='center' color={'#0065CC'}>
              <Box
                component={Link}
                to={`/signin`}
                sx={{
                  ':link, :hover, :active, :visited': {
                    color: '#0065CC',
                  },
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Sign In
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
