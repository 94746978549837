const RoleTypes = Object.freeze({
  0: 'OWNER',
  1: 'DIRECTOR',
  2: 'TEACHER',
  3: 'STUDENT',
  4: 'CHILD',
  5: 'PARENT',
  6: 'CAREGIVER',
  7: 'COACH',
  8: 'ADMIN',
  OWNER: 0,
  DIRECTOR: 1,
  TEACHER: 2,
  STUDENT: 3,
  CHILD: 4,
  PARENT: 5,
  CAREGIVER: 6,
  COACH: 7,
  ADMIN: 8,
});

module.exports = RoleTypes;
