import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import Theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from './AppRoutes';
import SnackbarProvider from './context/SnackbarContext';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <SnackbarProvider>
        <AppRoutes />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
