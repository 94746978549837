import { createSlice } from '@reduxjs/toolkit';

import { coverType } from '../utils/functions';
import { checkoutThunk, deleteCartItemThunk, getCartThunk } from './services/cart';

export const cartSlice = createSlice({
  name: 'cartReducer',
  initialState: {
    data: [],
    schoolBuys: [],
    parentBuys: [],
    count: 0,
    sessionId: '',
    products: [],
    isSuccess: false,
    freePhotos: null,
    amount: {
      taxRate: 0,
      total: 0,
      shipping: 0,
      subTotal: 0,
    },
    personalPhotos: 1,
    isLoading: false,
    isDeleteLoading: false,
    error: {
      status: false,
      message: '',
    },
  },
  reducers: {
    updatePersonalPhotosStatus: (state, action) => {
      let data = action.payload;
      let list = JSON.parse(
        data.type === 'parent'
          ? JSON.stringify(state.parentBuys || [])
          : JSON.stringify(state.schoolBuys || []),
      );
      let index = list.findIndex(
        (p) => p.purchasedFor.rosterId === data.product.purchasedFor.rosterId,
      );
      list[index]['additionalPhotos'].isPurchased = data.status;
      if (data.type === 'parent') {
        state.parentBuys = list;
      } else {
        state.schoolBuys = list;
      }
    },
    updatePersonalPhotos: (state, action) => {
      let data = action.payload;
      let list = JSON.parse(
        data.type === 'parent'
          ? JSON.stringify(state.parentBuys || [])
          : JSON.stringify(state.schoolBuys || []),
      );
      let index = list.findIndex(
        (p) => p.purchasedFor.rosterId === data.product.purchasedFor.rosterId,
      );
      list[index]['additionalPhotos'].quantity = data.quantity;
      if (data.type === 'parent') {
        state.parentBuys = list;
      } else {
        state.schoolBuys = list;
      }
    },
    updateCart: (state, action) => {
      let data = action.payload;
      let list = JSON.parse(
        data.type === 'parent'
          ? JSON.stringify(state.parentBuys || [])
          : JSON.stringify(state.schoolBuys || []),
      );
      let index = list.findIndex(
        (p) => p.purchasedFor.rosterId === data.product.purchasedFor.rosterId,
      );
      list[index]['quantity'] = data.quantity;
      if (data.type === 'parent') {
        state.parentBuys = list;
      } else {
        state.schoolBuys = list;
      }
    },
    updateCover: (state, action) => {
      let data = action.payload;
      let list = state.parentBuys;
      list[data.index] = {
        ...list[data.index],
        cover: {
          ...list[data.index].cover,
          ...data.toUpdate,
        },
        price: data.toUpdate.price,
      };
      state.parentBuys = list;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getCartThunk.fulfilled, (state, action) => {
        let response = action.payload;
        state.isLoading = false;
        state.data = response.cart;
        state.count = response.count;
        state.amount = response.amount || state.amount;
        state.products = response.products;
        state.freePhotos = response.freePhotos;
        state.parentBuys = response.cart.filter((d) => d?.plan?.type === 'parent-plan');
        state.schoolBuys = response.cart.filter((d) => d?.plan?.type === 'school-plan');
        state.parentBuys = state.parentBuys.map((data) => {
          let initialCover = data.cover;
          let coverWithType = { ...data.cover, type: coverType(data.cover?.id) };
          return {
            ...data,
            cover: { ...coverWithType },
            initialCover,
          };
        });
      })
      .addCase(getCartThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(deleteCartItemThunk.pending, (state, action) => {
        state.isDeleteLoading = true;
        state.error.status = false;
      })
      .addCase(deleteCartItemThunk.fulfilled, (state, action) => {
        let response = action.payload;
        state.isDeleteLoading = false;
        state.data = response.cart;
        state.count = response.count;
        state.amount = response.amount || state.amount;
        state.parentBuys = response.cart.filter((d) => d?.plan?.type === 'parent-plan');
        state.schoolBuys = response.cart.filter((d) => d?.plan?.type === 'school-plan');
        state.parentBuys = state.parentBuys.map((data) => {
          let initialCover = data.cover;
          let coverWithType = { ...data.cover, type: coverType(data.cover.id || '') };
          return {
            ...data,
            cover: { ...coverWithType },
            initialCover,
          };
        });
      })
      .addCase(deleteCartItemThunk.rejected, (state, action) => {
        state.isDeleteLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      })
      .addCase(checkoutThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(checkoutThunk.fulfilled, (state, action) => {
        let response = action.payload;
        state.isLoading = false;
        if (response.id) {
          state.sessionId = response.id;
          state.isSuccess = true;
        } else {
          state.error.status = true;
          state.error.message = 'Something Went Wrong!';
        }
      })
      .addCase(checkoutThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error.status = true;
        state.error.message = 'Something Went Wrong!';
      });
  },
});

export const {
  updateCart,
  updateCover,
  deleteProduct,
  updatePersonalPhotos,
  updatePersonalPhotosStatus,
} = cartSlice.actions;

export default cartSlice.reducer;
