import pureAxios from 'axios';
import { getAuth } from 'firebase/auth';

let baseURL = '',
  couldFuncBaseURL = '';
baseURL = process.env.REACT_APP_API_BASE_URL;
couldFuncBaseURL = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/`;

const getToken = () => {
  const auth = getAuth();
  return auth?.currentUser?.getIdToken();
};

const Axios = pureAxios.create({
  baseURL,
  timeout: 80000,
  // adapter: cache.adapter,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) config.headers.Authorization = `${token}`;

    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // if (error && error.response && error.response.status === 401) {
    //   window.location.reload();
    // }
    console.log(error);
    return Promise.reject(error);
  },
);

const CloudFuncAxios = pureAxios.create({
  baseURL: couldFuncBaseURL,
  timeout: 80000,
  // adapter: cache.adapter,
});

CloudFuncAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

export { Axios, CloudFuncAxios };
