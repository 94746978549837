import React from 'react';

import logoImage from '../../assets/myVityLogo.png';

export default function LogoBlack() {
  return (
    <a
      href={process.env.REACT_APP_SCHOOL_SUCCESS_URL}
      style={{
        textAlign: 'center',
      }}
    >
      <img src={logoImage} alt='Logo' style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        objectFit: 'contain'
      }} />
    </a>
  );
}
