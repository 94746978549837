import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Snackbar,
  InputBase,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Visibility, VisibilityOff, Info } from '@mui/icons-material';

import Spinner from '../../components/Spinner';
import NextArrowButton from '../../components/global/NextArrowButton';
import InfoAlertBox from '../../components/global/InfoAlertBox';

import { signInThunk } from '../../reducers/memberReducer';
import { getCartThunk } from '../../reducers/services/cart';

import { validateEmail } from '../../utils/functions';

export default function SignIn({ setIsSignIn }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const memberState = useSelector((state) => state.member);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error');
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [fromAccountActivation, setFromAccountActivation] = useState(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.hasOwnProperty('snackbar')) {
        setOpen(true);
        setMessage(location.state.snackbar.message);
        setMessageType(location.state.snackbar.type);
      }
      if (location.state.hasOwnProperty('error')) {
        setOpen(true);
        setMessage(location.state.error.message);
        setMessageType(location.state.error.type);
      }
      if (location.state?.fromAccountActivation) {
        setFromAccountActivation(true);
      }
      setTimeout(() => {
        // To clear the state
        navigate(location.pathname, { replace: true });
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberState.error.status) {
      setOpen(true);
      setMessage(memberState.error.message);
      return;
    }
    if (memberState.member.id) {
      navigate(memberState.navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberState]);

  const handleSignIn = () => {
    dispatch(signInThunk({ ...data, type: params.type })).then(() => {
      let auth = getAuth();
      dispatch(
        getCartThunk({
          id: auth?.currentUser?.uid,
        }),
      );
    });
  };

  const handleInput = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setTimeout(() => {
      setMessage(null);
    }, 100);
  };

  return (
    <>
      <Spinner
        open={memberState.isLoading}
        sx={{
          background: 'white',
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Box
        component='form'
        sx={{ width: '100%', mt: '40px' }}
        display={'flex'}
        justifyContent='center'
        flexDirection={'column'}
      >
        {fromAccountActivation && (
          <Box sx={{ mb: "20px", mx: 'auto', width: { xs: '90%', md: '70%' } }}>
            <InfoAlertBox Icon={Info} title={"Your Account is Now Active!"} subtitle={"Welcome aboard! You can now log in to access your account and start personalizing your book."} alertTitleSx={{ fontSize: '20px' }} />
          </Box>
        )}
        <Typography textAlign={'center'} variant='h4' mb={4} fontWeight={400}>
          Sign in with your account
        </Typography>
        {/* Inputs */}
        <Box display={'flex'} justifyContent='center'>
          <InputBase
            variant='leftborder'
            placeholder='Email'
            sx={{
              width: '100%',
              maxWidth: '360px',
            }}
            field='email'
            autoFocus
            value={data.email}
            onChange={(e) => {
              handleInput('email', e.target.value);
            }}
          ></InputBase>
        </Box>
        <Box
          sx={{ width: { xs: '100%', md: '100%' }, mt: '10px' }}
          display={'flex'}
          justifyContent='center'
        >
          <InputBase
            variant='leftborder'
            placeholder='Password'
            sx={{
              width: '100%',
              maxWidth: '360px',
            }}
            autoComplete='on'
            type={isVisible ? 'text' : 'password'}
            field='password'
            value={data.password}
            onChange={(e) => {
              handleInput('password', e.target.value);
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={(e) => setIsVisible(!isVisible)}
                >
                  {isVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        {/* Button */}
        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            mt: '30px',
          }}
          display={'flex'}
          justifyContent={'center'}
          alignItems='center'
        >
          <NextArrowButton
            text={'Sign in'}
            handleClick={handleSignIn}
            disabled={!(validateEmail(data.email) && data.password.length >= 8)}
          />
        </Box>
        {/* Forgot */}
        <Box
          sx={{ width: { xs: '100%', md: '100%' }, mt: '20px' }}
          display={'flex'}
          flexDirection='column'
          gap='10px'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant={'body2'} align='center' color={'#0065CC'}>
            <Box
              component={RouterLink}
              to={`/forgot-password`}
              sx={{
                ':link, :hover, :active, :visited': {
                  color: '#0065CC',
                },
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Forget your ID or Password?
            </Box>
          </Typography>
          <Typography variant={'body2'} align='center' color={'#0065CC'}>
            <Box
              component={'span'}
              onClick={() => setIsSignIn(false)}
              sx={{
                color: '#0065CC',
                cursor: 'pointer',
              }}
            >
              Create MyVity Account
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
