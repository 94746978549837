import React from 'react';
import { Box } from '@mui/material';
import { MemoriesData } from '../../../../../../data/text';
import { useSelector } from 'react-redux';

const AddMemorySection = ({
    handleAddEditMyOwnMemoryDialogToggle,
    handlePersonalizeMemoryDialogToggle
}) => {
    const { personalMemories } = useSelector((state) => state.parent.schoolMemories);

    return (
        <>
            <Box sx={{
                mt: '20px',
                pb: '10px',
                borderBottom: '1px solid #f1f1f1',
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                width: '100%',
                overflowX: 'auto'
            }}>
                <Box
                    onClick={() => handleAddEditMyOwnMemoryDialogToggle(true, 'ADD')}
                    sx={{
                        minWidth: 'max-content',
                        pb: '10px'
                    }}
                >
                    <Box sx={{
                        fontSize: { xs: '16px', md: '18px' },
                        fontWeight: 700,
                        color: '#000',
                        userSelect: 'none',
                        cursor: 'pointer'
                    }}>
                        + Add My Own
                    </Box>
                </Box>
                {MemoriesData.filter((p) => p.age === 1 && !personalMemories.memories.some(m => m.memoryKey === p.pKey)).map((memory, i) => (
                    <Box
                        key={i}
                        onClick={() => handlePersonalizeMemoryDialogToggle(true, 'ADD', memory)}
                        sx={{
                            minWidth: 'max-content',
                            pb: '10px'
                        }}
                    >
                        <Box sx={{
                            fontSize: { xs: '16px', md: '18px' },
                            fontWeight: 700,
                            color: '#929292',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            {memory.title}
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default AddMemorySection;