import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Boy2 from '../../assets/boy2.jpg';

const DismissibleBanner = ({
  Content = () => <></>,
  bannerId,
  bannerBgColor = '#3274b51a',
  showCloseBtn = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem(`bannerClosed_${bannerId}`, 'true');
  };

  useEffect(() => {
    const isBannerClosed = localStorage.getItem(`bannerClosed_${bannerId}`) === 'true';
    setIsVisible(!isBannerClosed);
  }, [bannerId]);

  return (
    isVisible && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          alignItems: 'start',
          borderRadius: '6px',
          position: 'relative',
        }}
      >
        {' '}
        <Box
          sx={{
            width: '100%',
            bgcolor: 'rgb(245,246,247)',
            p: 2,
            border: '1px solid rgba(225,225,225)',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 2, md: 4 },
          }}
        >
          <Box
            component={'img'}
            src={Boy2}
            alt=''
            sx={{
              width: { xs: '75px', md: '50px' },
              height: { xs: '75px', md: '50px' },
              ml: { xs: 0, md: '10px' },
            }}
          />
          <Stack alignItems={'flex-start'}>
            <Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  letterSpacing: -0.45,
                }}
              >
                Select or add upto 20 Memories
              </Typography>
              <Typography
                sx={{
                  mt: '4px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: -0.36,
                  fontWeight: 500,
                  // bgcolor: 'lightGray',

                  // color: 'darkRed',
                }}
              >
                Memories = School Photos + Personal Note + Growing up Memories
              </Typography>
            </Box>
            {/* <Button
          variant='text'
          onClick={() => handleToggleSamplePagesDialog(true, 'SCHOOL_PHOTOS')}
          sx={{
            p: 0,
            fontSize: 14,
            letterSpacing: -0.18,
            mt: '4px',
          }}
        >
          View Sample Pages
        </Button> */}
            <Typography
              sx={{
                mt: '4px',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: -0.36,
                color: 'GrayText',
              }}
            >
              Note: Family photos are in addition to the 20 memories
            </Typography>
          </Stack>
        </Box>
        {/* <Content /> */}
        {/* {showCloseBtn && (
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        )} */}
      </Box>
    )
  );
};

export default DismissibleBanner;
