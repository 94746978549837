import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../api/axios';

export const verifyEmailThunk = createAsyncThunk('verifyemail/fetch', async (d) => {
  // let d = data.value;
  try {
    let user = await Axios.post(
      '/signup/email/verify',
      {
        verificationCode: d.verificationCode,
        memberId: d.memberId,
      },
      {
        headers: {
          Authorization: d.token,
        },
      },
    );

    if (!user.member) {
      return { error: true, message: user.message || '' };
    } else {
      return { user: user };
    }
  } catch (error) {
    console.log(error);
    return { error: true, message: error?.response?.data?.message || '' };
  }
});

export const verifyEmailSlice = createSlice({
  name: 'verifyemail',
  initialState: {
    member: {},
    isLoading: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailThunk.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(verifyEmailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        console.log('thunk', action.payload);
        if (action.payload.error) {
          state.isError = true;
          setTimeout(() => {
            state.isError = false;
          }, 4000);
          state.errorMessage = action.payload.message;
        } else {
          state.isError = false;
          state.member = action.payload.user;
        }
      })
      .addCase(verifyEmailThunk.rejected, (state, action) => {
        console.log('error', action);
      });
  },
});
export const verifyemail = (state) => state.verifyemail;

export default verifyEmailSlice.reducer;
