import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { Collapse } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import Spinner from '../../components/Spinner';
import LogoBlack from '../../components/global/LogoBlack';
import NextArrowButton from '../../components/global/NextArrowButton';

import { hasLowerCase, hasUpperCase, hasNumber } from '../../utils/functions';
import { Axios } from '../../api/axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const UpdatePassword = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!searchParams.get('uid') || !searchParams.get('token')) {
      navigate(`/signin`, {
        state: {
          snackbar: {
            type: 'error',
            message: 'Invalid Url',
          },
        },
      });
    }
  }, [searchParams, navigate]);

  const passwordValidate = () => {
    if (
      !(
        hasUpperCase(newPassword) &&
        hasLowerCase(newPassword) &&
        hasNumber(newPassword) &&
        newPassword.length >= 8
      )
    )
      return true;
    return false;
  };

  const handleUpdatePassword = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.post(`/update/password`, {
        uid: searchParams.get('uid'),
        token: searchParams.get('token'),
        new_password: newPassword,
      });
      if (response.success) {
        navigate(`/signin`, {
          state: {
            snackbar: {
              type: 'success',
              message: response.message,
            },
          },
        });
      }
    } catch (error) {
      setMessage({
        message: error.message,
        type: 'error',
      });
      setAlertOpen(true);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setAlertOpen(false);
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  return (
    <Box
      display='flex'
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      flexDirection='column'
      alignItems={'center'}
      height={'100vh'}
    >
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={message?.type}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        width={{ sm: '60vw' }}
        py={{ xs: '40px', sm: '30px' }}
        boxShadow={{ xs: '0', sm: '0px 4px 24px 0px rgba(0, 0, 0, 0.04)' }}
        borderRadius={'20px'}
      >
        <Box sx={{ height: '40px' }}>
          <LogoBlack />
        </Box>

        <Spinner open={isLoading} />

        <Box
          sx={{ width: '100%', mt: '40px' }}
          display={'flex'}
          justifyContent='center'
          flexDirection={'column'}
        >
          <Typography textAlign={'center'} variant='h4' mb={4} fontWeight={400}>
            Update Password
          </Typography>
          {/* Input */}
          <Stack display={'flex'} justifyContent='center' alignItems={'center'} width={'100%'}>
            <InputBase
              variant='leftborder'
              type={isVisible ? 'text' : 'password'}
              placeholder='Enter New Password'
              sx={{ width: '100%', maxWidth: '360px' }}
              autoFocus
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => {
                      setIsVisible(!isVisible);
                    }}
                  >
                    {isVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Collapse
              in={isPasswordFocused}
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.white',
                  borderRadius: '5px',
                }}
              >
                <Box sx={{ p: '10px' }}>
                  <Box sx={{ mt: '10px' }}>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {newPassword.length >= 8 ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      at least 8 characters in length
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasLowerCase(newPassword) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      lower case letters (ie. a-z)
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasUpperCase(newPassword) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      upper case letters (ie. A-Z)
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasNumber(newPassword) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      numbers (ie. 0-9)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </Stack>
          {/* Button */}
          <Box
            sx={{
              width: '100%',
              mx: 'auto',
              mt: '20px',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems='center'
          >
            <NextArrowButton
              text='Update Password'
              handleClick={handleUpdatePassword}
              disabled={passwordValidate()}
            />
          </Box>
          {/* To Signin */}
          <Box
            sx={{ width: { xs: '100%', md: '100%' }, mt: '20px' }}
            display={'flex'}
            flexDirection='column'
            gap='10px'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant={'body2'} align='center' color={'#0065CC'}>
              <Box
                component={Link}
                to={`/signin`}
                sx={{
                  ':link, :hover, :active, :visited': {
                    color: '#0065CC',
                  },
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Sign In
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
