import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../api/axios';

export const getSchoolOrderDetails = createAsyncThunk(
  'fetch/school-orders',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.member?.activeChild?.schoolId;
      const response = await Axios.post('/school/order-history', {
        schoolId,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
