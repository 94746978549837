import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ImageListItem, TextField, Typography } from '@mui/material';
import { DeleteOutline, SubtitlesOutlined, Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deletePersonalPhotosThunk, savePhotoCaptionThunk } from '../../../../reducers/parentReducer';
import { BootstrapTooltip } from '../../../../components/global/BootstrapTooltip';

const PersonalPhotoItem = ({ photo }) => {
    const dispatch = useDispatch();
    const { personalPhotos } = useSelector((state) => state.parent);

    const [photoCaption, setPhotoCaption] = useState("");
    const [photoCaptionDialog, setPhotoCaptionDialog] = useState({ open: false, photo: {} });
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleTogglePhotoCaptionDialog = (open, photo) => {
        setPhotoCaptionDialog({ open, photo });
    };

    const isCaptionValid = useCallback(() => {
        if (photoCaption.length > 1 && photoCaption.length <= 140) {
            return true;
        }
        return false;
    }, [photoCaption]);

    const handleSaveCaption = async () => {
        if (isCaptionValid()) {
            await dispatch(savePhotoCaptionThunk({ photoId: photo.id, caption: photoCaption, type: 'PERSONAL_PHOTO' }));
            handleTogglePhotoCaptionDialog(false, {});
        }
    }

    useEffect(() => {
        setPhotoCaption(photo?.caption || "");
    }, [photo]);

    return (
        <>
            <ImageListItem>
                <Box>
                    <img
                        srcSet={photo.photoUrl}
                        src={photo.photoUrl}
                        loading='lazy'
                        alt='Personal Photos'
                        onLoad={() => setIsImageLoaded(true)}
                        style={{
                            width: '100%',
                            objectFit: 'cover'
                        }}
                    />
                    {(isImageLoaded && personalPhotos.lastPurchaseDate && new Date() < new Date(personalPhotos.lastPurchaseDate)) && (
                        <Box sx={{
                            position: 'absolute',
                            bottom: 20,
                            right: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            zIndex: 1,
                        }}>
                            <Box
                                onClick={() => handleTogglePhotoCaptionDialog(true, photo)}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: '5px'
                                }}
                            >
                                <BootstrapTooltip title='Add a caption and we will include in your Yearbook'>
                                    <SubtitlesOutlined
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </BootstrapTooltip>
                            </Box>
                            <Box
                                onClick={() => dispatch(deletePersonalPhotosThunk({ photoId: photo.id }))}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: '5px'
                                }}
                            >
                                <BootstrapTooltip title='Delete Photo'>
                                    <DeleteOutline
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </BootstrapTooltip>
                            </Box>
                        </Box>
                    )}
                </Box>
            </ImageListItem>

            <Dialog
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        },
                    },
                }}
                PaperProps={{
                    sx: {
                        width: '80%',
                        maxWidth: '720px',
                        height: '400px',
                        minHeight: '90%',
                        p: 0
                    },
                }}
                open={photoCaptionDialog.open}
                onClose={() => handleTogglePhotoCaptionDialog(false, {})}
                aria-labelledby='caption-dialog-title'
                aria-describedby='caption-dialog-description'
            >
                <DialogTitle id='caption-dialog-title' sx={{ p: 0 }}>
                    <Box>
                        <Typography align='center' fontWeight={700} fontSize={20} sx={{ py: '12px' }}>
                            Add Caption
                        </Typography>
                        <Divider />
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={() => handleTogglePhotoCaptionDialog(false, {})}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <Box sx={{ width: { xs: '100%', sm: '45%' }, margin: 'auto' }}>
                        <Box
                            component={'img'}
                            src={photoCaptionDialog.photo.photoUrl}
                            alt=''
                            sx={{
                                aspectRatio: 1,
                                width: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        width: '100%',
                        position: 'absolute',
                        bottom: 40,
                        height: '180px',
                        display: 'block'
                    }}
                >
                    <Box
                        sx={{
                            margin: 'auto',
                            width: '90%',
                            borderRadius: '9px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            id='caption'
                            placeholder='Enter your caption'
                            multiline
                            rows={3}
                            value={photoCaption}
                            onChange={(e) => setPhotoCaption(e.target.value)}
                            sx={{
                                bgcolor: 'rgba(245,246,247)',
                                width: '100%',
                                p: 1,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                        />
                        <Button
                            variant='contained'
                            onClick={handleSaveCaption}
                            disabled={!isCaptionValid()}
                            sx={{
                                mt: '20px',
                                cursor: 'pointer'
                            }}
                        >
                            Submit caption
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PersonalPhotoItem;