import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Slide,
  Stack,
  Typography,
  Alert,
  Snackbar,
  IconButton,
  Divider,
} from '@mui/material';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { Axios } from '../../../../../api/axios';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from '../../../../../components/Spinner';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UpgradePremiumDialog = ({ open, handleClose }) => {
  const slickSettings = {
    arrows: false,
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };

  const taxRate = 0.09375;
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { orderItems } = useSelector((state) => state.parent);
  const [alertBox, setAlertBox] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [amount, setAmount] = useState({
    subtotal: 9.99,
    shipping: 0,
    taxRate: taxRate,
    total: 9.99 * (1 + taxRate),
    tax: 9.99 * taxRate,
  });
  const [additionalCopies, setAdditionalCopies] = useState({ price: 0.0 });
  const schoolMemories = useSelector((state) => state.parent.schoolMemories);

  // schoolMemories.upgradedToPremium
  const handleAlertClose = () => {
    setAlertBox(false);
  };

  const handleCheckout = async () => {
    setIsLoading(true);
    let res = await Axios.post('/parent/upgrade-premium-checkout', {
      title: 'Upgrade Premium',
      groupId: orderItems.selectedChild.groupId,
      childMemberId: orderItems.selectedChild.childMemberId,
      rosterId: orderItems.selectedChild.rosterId,
      schoolId: orderItems.selectedChild.school.id,
      amount,
      additionalCopies,
    });
    if (res.success) {
      loadStripe(process.env.REACT_APP_API_STRIPE_KEY).then((stripe) => {
        stripe.redirectToCheckout({ sessionId: res.sessionId });
      });
    } else {
      setAlertMessage('Something went wrong!!!');
      setAlertBox(true);
    }
    setIsLoading(false);
  };

  const handleAdditionalCopies = (cover) => {
    console.log('cover is', cover);
    let subtotal, total, price, tax;
    switch (cover) {
      case 0:
        subtotal = 9.99 + 25 * 0.8;
        price = 25 * 0.8;
        break;

      case 1:
        subtotal = 9.99 + 30 * 0.8;
        price = 30 * 0.8;
        break;

      case 2:
        subtotal = 9.99 + 35 * 0.8;
        price = 35 * 0.8;
        break;
    }

    tax = subtotal * taxRate;
    total = subtotal + tax;
    setAmount({ subtotal, shipping: 0, taxRate: taxRate, total, tax });
    setAdditionalCopies({ cover, price });
  };
  console.log(amount);
  console.log(additionalCopies);

  if (schoolMemories.upgradedToPremium) {
    // the flag if  premium is already upgraded
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth={false}
        sx={{
          zIndex: 999999,
        }}
      >
        <Box
          sx={{
            maxWidth: '630px',
            width: '90vw',
            bgcolor: 'white',
            height: '81vh',
            display: 'flex',
          }}
        >
          <Spinner open={isLoading} />

          <Snackbar
            open={alertBox}
            autoHideDuration={2000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert onClose={handleAlertClose} severity={'warning'} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>

          {!showOrderSummary && <PremiumDetails handleContinue={() => setShowOrderSummary(true)} />}
          {showOrderSummary && (
            <PremiumOrderSummary
              handleCheckout={handleCheckout}
              amount={amount}
              handleCover={handleAdditionalCopies}
              additionalCopies={additionalCopies}
            />
          )}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
};

const PremiumOrderSummary = ({ handleCheckout, amount, handleCover, additionalCopies }) => {
  return (
    <Box sx={{ width: '100%', py: 0, px: 5, position: 'sticky', overflow: 'auto' }}>
      <Typography sx={{ fontSize: '32px', fontWeight: 700, mt: '40px' }}>Checkout</Typography>
      <Typography
        sx={{
          fontSize: '16px',
          color: 'GrayText',
          lineHeight: '20px',
          letterSpacing: '-0.36',
        }}
      >
        Every Memory Matters
      </Typography>
      <Box sx={{ mt: '40px', maxWidth: 540 }}>
        <OrderItem
          icon={<SafetyCheckOutlinedIcon sx={{ fontSize: '22px' }} />}
          title='Premium upgrade'
          price='$9.99'
        />{' '}
      </Box>
      <Box sx={{ mt: '30px', maxWidth: 540 }}>
        <OrderItem
          icon={<MenuBookOutlinedIcon sx={{ fontSize: '22px' }} />}
          title='Add additional copy'
          price={`$${additionalCopies.price.toFixed(2)}`}
          options={['Softcover', 'Perfect Bind', 'Hardcover']}
          handleCover={handleCover}
        />
      </Box>
      <Divider sx={{ mt: 6, maxWidth: 540 }} />
      <Stack
        direction={'row'}
        gap={'20px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ mt: 2, maxWidth: 540 }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          Subtotal
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          ${amount.subtotal.toFixed(2)}
        </Typography>
      </Stack>
      <Stack
        direction={'row'}
        gap={'20px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ mt: 1, maxWidth: 540 }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          Tax
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
          }}
        >
          ${amount.tax.toFixed(2)}
        </Typography>
      </Stack>{' '}
      <Divider sx={{ mt: 1, maxWidth: 540 }} />
      <Stack
        direction={'row'}
        gap={'20px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ mt: 1, maxWidth: 540 }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 700,
          }}
        >
          Total
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 700,
          }}
        >
          ${amount.total.toFixed(2)}
        </Typography>
      </Stack>{' '}
      <Divider sx={{ mt: 1, maxWidth: 540 }} />
      <Button
        variant='contained'
        sx={{ mt: 5, width: '180px', height: '40px', mb: 5 }}
        onClick={handleCheckout}
      >
        Checkout
      </Button>
    </Box>
  );
};

const OrderItem = ({ icon, title, price, options, handleCover }) => {
  return (
    <Stack direction={'row'} gap={'20px'} alignItems={'top'} justifyContent={'space-between'}>
      <Stack direction={'row'} gap={'20px'} alignItems={'top'}>
        {icon}
        <Box>
          <Typography
            sx={{
              fontSize: '18px',
              lineHeight: '20px',
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>

          {options && (
            <Stack direction={'row'} gap={'12px'} alignItems={'center'} sx={{ mt: '12px' }}>
              {options.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    py: '4px',
                    px: '8px',
                    fontSize: 12,
                    border: '1px solid GrayText',
                    textAlign: 'center',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCover(index)}
                >
                  {option}
                </Box>
              ))}
            </Stack>
          )}
        </Box>
      </Stack>
      <Typography
        sx={{
          fontSize: '18px',
          lineHeight: '20px',
          fontWeight: 500,
        }}
      >
        {price}
      </Typography>
    </Stack>
  );
};

const PremiumDetails = ({ handleContinue }) => {
  return (
    <Box sx={{ width: '100%', py: 0, px: 5, position: 'sticky', overflow: 'auto' }}>
      <Typography sx={{ fontSize: '32px', fontWeight: 700, mt: '40px' }}>
        Upgrade to Premium
      </Typography>
      <Typography
        sx={{
          fontSize: '20px',
          mt: 0.25,
          letterSpacing: '-0.36',
          color: 'black',
          fontWeight: 500,
        }}
      >
        $9.99
      </Typography>
      <Stack sx={{ mt: '40px' }} gap={3}>
        <Stack direction={'row'} gap={'20px'} alignItems={'top'}>
          <SafetyCheckOutlinedIcon sx={{ fontSize: '22px' }} />
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'rgb(45,45,45)',
                lineHeight: '20px',
                letterSpacing: '-0.27',
                fontWeight: 700,
              }}
            >
              Get 25 additional memories
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                color: 'rgb(45,45,45)',
                lineHeight: '20px',
                letterSpacing: '-0.36',
                mt: '4px',
              }}
            >
              By upgrading to our premium plan, you get the option to include 25 additional
              memories. If you upgrade, you are not required to select all 25 memories. You can
              decide to select none or only your top favorites and and we will select the rest for
              you.
            </Typography>
          </Box>
        </Stack>
      </Stack>{' '}
      <Stack sx={{ mt: '30px' }} gap={3}>
        <Stack direction={'row'} gap={'20px'} alignItems={'top'}>
          <MenuBookOutlinedIcon sx={{ fontSize: '22px' }} />
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'rgb(45,45,45)',
                lineHeight: '20px',
                letterSpacing: '-0.27',
                fontWeight: 700,
              }}
            >
              Get 20% off on additional copy of your child's Yearbook
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                color: 'rgb(45,45,45)',
                lineHeight: '20px',
                letterSpacing: '-0.36',
                mt: '4px',
              }}
            >
              Gift these precious compilations to grandparents, aunts, uncles, and anyone who
              cherishes your child’s growth and milestones.
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Button
        variant='contained'
        sx={{ mt: '90px', width: '180px', height: '40px', position: 'fixed', bottom: 100 }}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Box>
  );
};

export default UpgradePremiumDialog;
