import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import clsx from 'clsx';
import styles from '../../../../parent/account/photos/OrderQuantity.module.css';

const AddtionalPhotos = ({ quantity, updateQuantity }) => {
    let additionalPhoto1QtyPrice = 1.99;
    let photosCount = 4;

    const [copies, setCopies] = useState(quantity || 1);

    const add = () => {
        if (!copies) {
            setCopies(1);
        } else {
            setCopies((prev) => prev + 1);
        }
    };

    const substract = () => {
        copies > 1 && setCopies((prev) => prev - 1);
    };

    useEffect(() => {
        setCopies(quantity || 1);
    }, [quantity]);

    useEffect(() => {
        updateQuantity(copies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copies]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: { xs: '10px', sm: '20px' },
                alignContent: 'flex-start',
                flexWrap: { xs: 'wrap', sm: 'no-wrap' },
                p: '20px 30px',
            }}
        >
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', sm: '400px' } }}>
                <Box>
                    <Typography
                        variant='body1'
                        fontWeight={500}
                        fontSize={'18px'}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {photosCount} Personal Photos
                    </Typography>
                    <List sx={{ p: 0 }}>
                        <ListItem
                            sx={{
                                p: '0px 0px 3px',
                            }}
                        >
                            <Typography variant='body2' color={'#262626'}>
                                For ${additionalPhoto1QtyPrice}
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '9px',
                        fontSize: '14px',
                    }}
                >
                    <div
                        className={clsx(styles.minus, (isNaN(copies) || copies <= 1) && styles.disabled)}
                        onClick={substract}
                    >
                        -
                    </div>
                    <input
                        className={styles.inputBox}
                        value={copies}
                        type='number'
                        min={1}
                        onChange={(e) => {
                            if (e.target.value === '') setCopies(1);
                            else if (parseInt(e.target.value) < 1) setCopies(1);
                            else setCopies(parseInt(e.target.value));
                        }}
                    />
                    <div className={styles.plus} onClick={add}>
                        +
                    </div>
                </Box>
            </Box>
        </Box>
    );
}

export default AddtionalPhotos;