import { Axios } from '../api/axios';
import { getParentProfileFilePath } from '../utils/functions';

export const updateProfilePhoto = async ({ member, activeChild, profileData }) => {
  const formData = new FormData();
  formData.append('filePath', getParentProfileFilePath({ member, activeChild }));
  formData.append('profile', profileData);
  formData.append('schoolId', activeChild?.schoolId);
  formData.append('rosterId', activeChild?.rosterId);
  formData.append('owner', 'members');

  const res = await Axios.post('/photos/upload-profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res;
};
