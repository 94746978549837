import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMemoriesNotesThunk,
  deleteMemoriesNotesThunk,
  updateMemoriesNotesThunk,
} from '../../../../../reducers/parentReducer';
import Spinner from '../../../../../components/Spinner';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DismissibleBanner from '../../../../../components/global/DismissibleBanner';
import Girl1 from '../../../../../assets/girl1.jpg';
import Grandpa from '../../../../../assets/grandPa.jpg';
import Grandma from '../../../../../assets/grandma.jpg';
import Mom from '../../../../../assets/mom.jpg';
import Dad from '../../../../../assets/dad.jpg';

let members = [
  { relation: 'Mother', url: Mom },
  { relation: 'Father', url: Dad },
  { relation: 'Grandma', url: Grandma },
  { relation: 'Grandpa', url: Grandpa },
];

const PersonalNotes = ({ handleUpgradePremiumDialog, handleToggleSamplePagesDialog }) => {
  const schoolMemories = useSelector((state) => state.parent.schoolMemories);

  const [note, setNote] = useState({});
  const [isExistingNoteClicked, setIsExistingNoteClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [myNotesMenuAnchorEl, setMyNotesMenuAnchorEl] = useState(null);

  let memberObj = {
    Mother: 0,
    Father: 1,
    Grandma: 2,
    Grandpa: 3,
  };

  const handleOpenMyNotesMenu = (event) => {
    setMyNotesMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMyNotesMenu = () => {
    setMyNotesMenuAnchorEl(null);
  };

  const clickAddNote = () => {
    setIsExistingNoteClicked(false);
    setNote({});
  };

  const onExistingNoteSelection = (note) => {
    console.log(note);
    setIsExistingNoteClicked(true);
    setNote(note);
    console.log(memberObj[note.info.relation]);
    setActiveIndex(memberObj[note.info.relation]);
  };

  return (
    <>
      <Grid container alignItems={'center'} >
        <Grid item xs={12} lg={8}>
          <Box sx={{ mt: '20px' }}>
            <DismissibleBanner
              Content={() => (
                <Box
                  sx={{
                    width: '100%',
                    bgcolor: 'rgb(245,246,247)',
                    p: 2,
                    border: '1px solid rgba(225,225,225)',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 2, md: 4 }
                  }}
                >
                  <Box component={'img'} src={Girl1} alt='' sx={{ width: { xs: '75px', md: '50px' }, height: { xs: '75px', md: '50px' }, ml: { xs: 0, md: '10px' } }} />
                  <Stack alignItems={'flex-start'}>
                    <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
                      Capture the heartwarming journey of growing up. Whether it’s a giggle-worthy
                      anecdote from grandparents or a proud moment shared by parents, chronicle the
                      joys, the milestones, and the everyday wonders of childhood and preserve them
                      forever.
                    </Typography>
                    <Button
                      variant='text'
                      onClick={() => handleToggleSamplePagesDialog(true, 'PERSONAL_NOTES')}
                      sx={{
                        p: 0,
                        fontSize: 14,
                        letterSpacing: -0.18,
                        mt: '4px',
                      }}
                    >
                      View Sample Pages
                    </Button>
                  </Stack>
                </Box>
              )}
              bannerBgColor='rgb(244,245,246)'
              showCloseBtn={false}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Box sx={{ mb: '30px' }}>
            <Box
              sx={{
                mt: '50px',
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                width: '100%',
                overflowX: 'auto',
              }}
            >
              <Stack
                direction='row'
                gap='4px'
                alignItems='center'
                onClick={handleOpenMyNotesMenu}
                sx={{ ml: '10px', cursor: 'pointer' }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: 'rgb(45,45,45)',
                  }}
                >
                  My Notes
                </Typography>
                <KeyboardArrowDownIcon />
              </Stack>
              <Menu
                elevation={0}
                open={Boolean(myNotesMenuAnchorEl)}
                anchorEl={myNotesMenuAnchorEl}
                onClose={handleCloseMyNotesMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '10px',
                    marginTop: '8px',
                    minWidth: 180,
                    color: 'rgb(55, 65, 81)',
                    boxShadow:
                      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                    '& .MuiMenu-list': {
                      padding: '4px 0',
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseMyNotesMenu();
                    if (schoolMemories.totalRemainingMemoriesCount > 0) {
                      clickAddNote();
                    } else {
                      handleUpgradePremiumDialog(true);
                    }
                  }}
                  disableRipple
                >
                  + Add New Note
                </MenuItem>
                {schoolMemories.notes.map((n, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      handleCloseMyNotesMenu();
                      onExistingNoteSelection(n);
                    }}
                    disableRipple
                  >
                    Note from {n?.info?.relation}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box>
              {isExistingNoteClicked ? (
                <UpdateNotesForm
                  loading={schoolMemories.addNoteLoader}
                  noteData={note}
                  activeMember={activeIndex}
                  setIsExistingNoteClicked={setIsExistingNoteClicked}
                />
              ) : schoolMemories.totalRemainingMemoriesCount > 0 ? (
                <AddNotesForm loading={schoolMemories.addNoteLoader} />
              ) : (
                <Box
                  sx={{
                    textAlign: 'left',
                    ml: '10px',
                    mt: '20px',
                    fontSize: '16px',
                    color: 'GrayText',
                  }}
                >
                  You have used all your memories. Please upgrade to add new note.
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

    </>
  );
};

const AddNotesForm = ({ loading }) => {
  const dispatch = useDispatch();
  const { selectedChild } = useSelector((state) => state.parent.orderItems);

  const [activeFamilyMember, setActiveFamilyMember] = useState(-1);

  const [note, setNote] = useState('');
  const saveNote = () => {
    console.log('active family', activeFamilyMember);

    let data = {
      info: {
        relation: members[activeFamilyMember].relation,
      },
      note: note,
    };

    dispatch(createMemoriesNotesThunk({ data, type: 'NOTE' }));
    setNote('');
    setActiveFamilyMember(-1);
  };

  return (
    <>
      <Box>
        {loading && <Spinner open />}
        <Box sx={{ width: '100%' }}>
          <Box sx={{
            width: '100%',
            mt: '20px',
            p: 1,
          }}>
            <TextField
              autoFocus
              id='add-note'
              placeholder={`Write a note for ${selectedChild?.info?.firstName
                ? selectedChild.info.firstName.charAt(0).toUpperCase() +
                selectedChild.info.firstName.slice(1)
                : ''
                }`}
              multiline
              rows={13}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgb(180,180,180)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgb(180,180,180)',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 18,
                  letterSpacing: -0.27,
                },
                '& .MuiInputBase-input': {
                  fontSize: 18,
                },
              }}
            />
          </Box>
          <Box sx={{ mt: '50px', p: 2, bgcolor: 'rgb(245,246,247)', width: '100%' }}>
            <Typography sx={{ fontSize: 18, fontWeight: 500, letterSpacing: -0.27, mt: '20px' }}>
              Who is writing the note?
            </Typography>

            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                overflowX: 'auto',
                py: '16px',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {members.map((member, index) => {
                return (
                  <NotesAvatar
                    key={index}
                    index={index}
                    activeIndex={activeFamilyMember}
                    handleFamilyMemberClick={() => setActiveFamilyMember(index)}
                    member={member}
                    count={members.length}
                  />
                );
              })}
            </Box>
          </Box>

          <Button
            onClick={saveNote}
            variant='contained'
            sx={{
              mt: '40px',
              cursor: 'pointer',
              width: '135px',
              ml: '20px',
              height: 44,
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

const UpdateNotesForm = ({ loading, noteData, activeMember, setIsExistingNoteClicked }) => {
  const dispatch = useDispatch();
  const { selectedChild } = useSelector((state) => state.parent.orderItems);

  const [note, setNote] = useState(noteData.note || '');
  const [activeFamilyMember, setActiveFamilyMember] = useState(activeMember);

  const saveNote = () => {
    let data = {
      info: {
        relation: members[activeFamilyMember].relation,
      },
      note,
    };
    dispatch(updateMemoriesNotesThunk({ memoryId: noteData.id, data, type: 'NOTE' }));
  };
  const deleteNote = () => {
    dispatch(deleteMemoriesNotesThunk({ memoryId: noteData.id, type: 'NOTE' })).then(() => {
      setIsExistingNoteClicked(false);
    });
  };
  useEffect(() => {
    setNote(noteData.note);
  }, [noteData]);

  useEffect(() => {
    setActiveFamilyMember(activeMember);
  }, [activeMember]);

  return (
    <>
      <Box>
        {loading && <Spinner open />}
        <Box>
          <Box sx={{
            width: '100%',
            mt: '20px',
            p: 1,
          }}>
            <TextField
              autoFocus
              id='add-note'
              placeholder={`Write a note for ${selectedChild?.info?.firstName
                ? selectedChild.info.firstName.charAt(0).toUpperCase() +
                selectedChild.info.firstName.slice(1)
                : ''
                }`}
              multiline
              rows={13}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'grey',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgb(180,180,180)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgb(180,180,180)',
                  },
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: 18,
                  letterSpacing: -0.27,
                },
                '& .MuiInputBase-input': {
                  fontSize: 18,
                },
              }}
            />
          </Box>

          <Box sx={{ mt: '50px', p: 2, bgcolor: 'rgb(245,246,247)', width: '100%' }}>
            <Typography sx={{ fontSize: 18, fontWeight: 500, letterSpacing: -0.27, mt: '20px' }}>
              Who is writing the note?
            </Typography>

            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                overflowX: 'auto',
                py: '16px',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {members.map((member, index) => {
                return (
                  <NotesAvatar
                    key={index}
                    title={member.relation}
                    index={index}
                    activeIndex={activeFamilyMember}
                    handleFamilyMemberClick={() => setActiveFamilyMember(index)}
                    member={member}
                    count={members.length}
                  />
                );
              })}
            </Box>
          </Box>
          <Stack direction={'row'} gap={2} sx={{ mt: '40px', ml: '10px', pb: '40px' }}>
            <Button
              onClick={deleteNote}
              variant='outlined'
              sx={{
                cursor: 'pointer',
                width: '135px',
                height: 44,
              }}
            >
              Delete
            </Button>
            <Button
              onClick={saveNote}
              variant='contained'
              sx={{
                cursor: 'pointer',
                width: '135px',
                height: 44,
              }}
            >
              Update
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

const NotesAvatar = ({ index, activeIndex, handleFamilyMemberClick, member, count }) => {
  return (
    <Box
      sx={{
        ml: index === 0 ? { xs: 2 } : 'inherit',
        mr: index === count - 1 ? { xs: 2 } : 'inherit',
        cursor: 'pointer',
      }}
      onClick={() => handleFamilyMemberClick(index)}
    >
      <Box
        sx={{
          maxWidth: 100,
          mx: '8px',
          cursor: 'pointer',
        }}
      >
        <Box
          component={'img'}
          src={member.url}
          sx={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            border: activeIndex === index ? '2px solid red' : 'none',
            p: '2px',
          }}
          alt='icon'
        />
        <Typography
          sx={{
            textAlign: 'center',
            mt: 0.5,
            color: 'darkred',
            fontWeight: 500,
            overflow: 'hidden',
            lineHeight: 1.26,
            maxWidth: '100%',
          }}
        >
          {member.relation}
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonalNotes;
