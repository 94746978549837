import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { pluralHelper } from '../../../../../../utils/functions';
import PersonalMemoryItem from './PersonalMemoryItem';

const MemoriesSection = ({
  handleAddEditMyOwnMemoryDialogToggle,
  handlePersonalizeMemoryDialogToggle,
}) => {
  const { personalMemories } = useSelector((state) => state.parent.schoolMemories);

  return (
    <>
      <Box>
        {/* <Box sx={{
                    mt: '30px',
                    fontWeight: 700,
                    textAlign: 'left',
                    color: 'rgb(51, 51, 51)',
                    fontSize: '16px',
                    lineHeight: 1
                }}>My Memories</Box> */}
        <Box
          sx={{
            mt: 3,
            fontSize: '12px',
            textAlign: 'left',
            color: 'rgb(51, 51, 51)',
            lineHeight: 1,
            fontWeight: 500,
          }}
        >
          {personalMemories.count === 0 ? 'No memories added' : ''}
          {/* : `${personalMemories.count} ${pluralHelper(
                personalMemories.count,
                'memory',
                'memoies',
              )} added`} */}
        </Box>
      </Box>
      <Box
        sx={{
          mt: '16px',
          pb: '120px',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoColumns: '1fr',
          gridTemplateRows: 'auto auto',
          gap: '16px',
        }}
      >
        {personalMemories.memories.map((memory, i) => (
          <PersonalMemoryItem
            key={i}
            memory={memory}
            handleAddEditMyOwnMemoryDialogToggle={handleAddEditMyOwnMemoryDialogToggle}
            handlePersonalizeMemoryDialogToggle={handlePersonalizeMemoryDialogToggle}
          />
        ))}
      </Box>
    </>
  );
};

export default MemoriesSection;
