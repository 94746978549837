import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';

import { callPostAPI } from '../../../helpers/apiCall';

export const getCartThunk = createAsyncThunk('get-cart/fetch', async (data) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let cart = await callPostAPI({ token, memberId: data.id, schoolId: data.schoolId }, '/get-cart');
  return cart;
});

export const deleteCartItemThunk = createAsyncThunk('delete-cart/fetch', async (data) => {
  const { product } = data;
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  const cart = await callPostAPI(
    {
      token,
      productId: product.id,
      rosterId: product.purchasedFor.rosterId,
      schoolId: product.purchasedFor.school.id,
      orderId: product.orderId,
    },
    '/cart/delete-product',
  );
  return cart;
});
