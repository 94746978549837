import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { callPostAPI } from '../../../helpers/apiCall';

export const checkoutThunk = createAsyncThunk('createCheckout/fetch', async (data) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let type = data?.type ? data?.type : 'parent-buys';
  let reqObj = {
    amount: {
      subtotal: data.totals,
    },
    products: data.products.filter((p) => p.quantity > 0),
    purchasedBy: { id: data.member.id },
  };
  let result = callPostAPI({ ...reqObj, token, type }, '/createStripeCheckout');
  return result;
});
