import { firebaseConfig } from '../config/firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const logout = async (cb) => {
  return signOut(auth);
};
