import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Restricted from '../../assets/restricted.jpg';

import AuthNavBar from '../../components/global/AuthNavBar';

import RoleTypes from '../../constants/Roles';
import { fetchParentPages, fetchSchoolPages } from '../../routes/lazyComponents';
import CartIcon from '../../components/Icons/CartIcon';
import UploadCLoud from '../../components/Icons/UploadCloud';
import { parentAccountV2Schools } from '../../utils/constants';

const ChooseParentActivity = () => {
    const navigate = useNavigate();
    const { member } = useSelector((state) => state.member);
    const [isRestricted] = useState(false);

    // Preload required pages for school and parents
    useEffect(() => {
        fetchSchoolPages();
        fetchParentPages();
    }, []);

    const handleBuyBook = () => {
        navigate('/parent/memory-book');
    };

    const handleUploadPhotos = () => {
        if (member.associations === undefined) {
            navigate('/parent/memory-book');
            return;
        }
        const isAnyParentAssociation = Object.keys(member.associations).some((key) => {
            return member.associations[key].mRole === RoleTypes.PARENT;
        });
        console.log('member.associations', member);
        let isForceRedirect = [
            'geifeittucrameu-9892@yopmail.com',
            'ghmsc451@gmail.com',
            'safia@goldenharvestmontessori.com'
        ].includes(member?.emails[0]);

        if (isAnyParentAssociation || isForceRedirect) {
            const firstAssociation = Object.values(member.associations)[0];
            if (parentAccountV2Schools.includes(firstAssociation.school.id) || isForceRedirect) {
                navigate('/parent-account');
            } else {
                navigate('/parent/photos');
            }
        } else {
            navigate('/parent/memory-book');
        }
    };

    return (
        <>
            <AuthNavBar showSignup={false} showLogin={false} />
            {isRestricted ? (
                <Box
                    sx={{
                        textAlign: 'center',
                        margin: 'auto',
                        width: '80%',
                    }}
                >
                    <img src={Restricted} alt='Forbidden' style={{ height: '360px' }} />
                    <Typography
                        align='center'
                        variant='h5'
                        fontWeight={400}
                        sx={{ color: 'primary.lightText', mt: '0px' }}
                    >
                        You are not authorized to access school account.
                    </Typography>
                    <Typography
                        align='center'
                        variant='subtitle1'
                        fontWeight={400}
                        sx={{ color: 'primary.lightText', mt: '10px' }}
                    >
                        Please contact your school director
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{ mt: { xs: '10px', sm: '80px' } }}
                    display={'flex'}
                    justifyContent='center'
                    gap='30px'
                    flexWrap={'wrap'}
                >
                    <Box
                        boxShadow={1}
                        sx={{
                            width: '300px',
                            padding: "2rem",
                            cursor: 'pointer',
                            border: 'solid 1px rgba(0,0,0,0.09)',
                            textAlign: 'center',
                        }}
                        onClick={handleBuyBook}
                    >
                        {/* <img style={{ width: '200px', aspectRatio: '1/1' }} src={Parent} alt='parent' /> */}
                        <CartIcon />
                        <Typography align='center' fontWeight={700} sx={{ mt: '20px', fontSize: '24px' }}>
                            Purchase Personalized Memory Book
                        </Typography>

                        {/* <Typography
                            variant='body2'
                            component={'div'}
                            align='center'
                            fontWeight={400}
                            sx={{ color: 'primary.lightText', mt: '8px' }}
                        >
                            Lorem ipsum dolor sit amet.
                        </Typography>
                        <Typography
                            variant='body2'
                            align='center'
                            fontWeight={400}
                            sx={{ color: 'primary.lightText' }}
                        >
                            Lorem ipsum dolor sit amet.

                        </Typography> */}
                    </Box>
                    <Box
                        boxShadow={1}
                        sx={{
                            width: '300px',
                            padding: "2rem",
                            cursor: 'pointer',
                            border: 'solid 1px rgba(0,0,0,0.09)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={handleUploadPhotos}
                    >

                        <UploadCLoud />
                        <Typography align='center' fontWeight={700} sx={{ mt: '20px', fontSize: '24px' }}>
                            Upload Personal <br /> Photos
                        </Typography>

                        {/* <Typography
                            variant='body2'
                            component={'div'}
                            align='center'
                            fontWeight={400}
                            sx={{ color: 'primary.lightText', mt: '8px' }}
                        >
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis iure possimus consectetur.

                        </Typography>
                        <Typography
                            variant='body2'
                            align='center'
                            fontWeight={400}
                            sx={{ color: 'primary.lightText' }}
                        >
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis iure possimus consectetur.
                        </Typography> */}
                    </Box>
                </Box>
            )}
            {/* </Box> */}
        </>
    );
};

export default ChooseParentActivity;
