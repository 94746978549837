import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getMemoriesNotesThunk,
  getPersonalPhotosThunk,
  getSchoolPhotos,
} from '../../reducers/parentReducer';
import { Box, Button, Stack, Typography } from '@mui/material';
import SchoolPhotos from './components/school-memories/school-photos/SchoolPhotos';
import Spinner from '../../components/Spinner';
import PersonalNotes from './components/school-memories/personal-notes/PersonalNotes';
import PersonalMemories from './components/school-memories/personal-memories/PersonalMemories';
import UpgradePremiumDialog from './components/school-memories/upgrade-premium/UpgradePremiumDialog';
import UpgradePremiumBox from './components/school-memories/upgrade-premium/UpgradePremiumBox';
import { pluralHelper } from '../../utils/functions';

const drawerWidth = 240;
const schoolMemoriesTypes = [
  { label: 'Select School Photos', value: 'SCHOOL_PHOTOS' },
  { label: 'Add Personal Note', value: 'PERSONAL_NOTES' },
  { label: 'Add Growing up Memories', value: 'PERSONAL_MEMORIES' },
];

const ParentAccountSchoolMemories = ({ handleToggleSamplePagesDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderItems, schoolMemories } = useSelector((state) => state.parent);

  const [selectedSchoolMemoryType, setSelectedSchoolMemoryType] = useState('SCHOOL_PHOTOS');
  const [showUpgradePremiumDialog, setShowUpgradePremiumDialog] = useState(false);

  const handleUpgradePremiumDialog = (isOpen) => {
    setShowUpgradePremiumDialog(isOpen);
  };

  useEffect(() => {
    if (!orderItems?.selectedChild?.id) {
      navigate('/parent-account', { replace: true });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderItems?.selectedChild?.id) {
      dispatch(getSchoolPhotos());
      dispatch(getPersonalPhotosThunk({ isSchoolPersonal: true }));
      dispatch(getMemoriesNotesThunk({ type: 'NOTE' }));
      dispatch(getMemoriesNotesThunk({ type: 'MEMORY' }));
    }
    //eslint-disable-next-line
  }, [orderItems.selectedChild]);

  return (
    <>
      {schoolMemories.isLoading && <Spinner open />}
      <Box
        sx={{
          width: `calc(100%-${drawerWidth})`,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '96%' },
            position: 'fixed',
            height: '100px',
            top: { xs: 10, sm: 0 },
            left: { drawerWidth },
            right: 0,
            pointerEvents: 'none',
            backgroundImage:
              'linear-gradient(180deg,#fcfcfd 0,rgba(252,252,253,.987) 14%,rgba(252,252,253,.951) 26.2%,rgba(252,252,253,.896) 36.8%,rgba(252,252,253,.825) 45.9%,rgba(252,252,253,.741) 53.7%,rgba(252,252,253,.648) 60.4%,rgba(252,252,253,.55) 66.2%,rgba(252,252,253,.45) 71.2%,rgba(252,252,253,.352) 75.6%,rgba(252,252,253,.259) 79.6%,rgba(252,252,253,.175) 83.4%,rgba(252,252,253,.104) 87.2%,rgba(252,252,253,.049) 91.1%,rgba(252,252,253,.013) 95.3%,rgba(252,252,253,0))',
            zIndex: 2,
          }}
        />

        <Box
          sx={{
            position: 'fixed',
            pl: { xs: '16px', sm: '8px' },
            pr: { xs: '16px', sm: '30px' },
            zIndex: 3,
            width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
            left: { drawerWidth },
            top: { xs: 55, sm: 20 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '8px',
              px: '10px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#fff',
                border: '1px solid rgb(225,225,225)',
                p: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                flexGrow: 1,
                borderRadius: '13px',
                overflowX: 'auto',
              }}
            >
              {schoolMemoriesTypes.map((type, index) => (
                <Stack key={index}>
                  <Box
                    onClick={() => setSelectedSchoolMemoryType(type.value)}
                    sx={{
                      p: '8px 22px',
                      fontSize: '14px',
                      fontWeight: selectedSchoolMemoryType === type.value ? 700 : 500,
                      color: selectedSchoolMemoryType === type.value ? '#2d63c6' : '#5e5e5e',
                      borderRadius: '9px',
                      cursor: 'pointer',
                      minWidth: 'max-content',
                      border: selectedSchoolMemoryType === type.value ? '2px solid #2d63c6' : '2px solid rgb(225,225,225)',
                      transition: '0.2s all'
                    }}
                  >
                    {type.label}
                    {type.value === 'SCHOOL_PHOTOS' && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          mt: '-2px',
                          color: selectedSchoolMemoryType === type.value ? '#2d63c6' : '#5e5e5e',
                          fontWeight: selectedSchoolMemoryType === type.value ? 500 : 400,
                        }}
                      >
                        {schoolMemories.photos.filter((p) => p.isLiked).length +
                          schoolMemories.schoolPersonal.length}{' '}
                        {pluralHelper(
                          schoolMemories.photos.filter((p) => p.isLiked).length,
                          'photo',
                          'photos',
                        )}{' '}
                        selected
                      </Typography>
                    )}{' '}
                    {type.value === 'PERSONAL_NOTES' && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          mt: '-2px',
                          color: selectedSchoolMemoryType === type.value ? '#2d63c6' : '#5e5e5e',
                          fontWeight: selectedSchoolMemoryType === type.value ? 500 : 400,
                        }}
                      >
                        {schoolMemories.notes.length}{' '}
                        {pluralHelper(schoolMemories.notes.length, 'note', 'notes')} added
                      </Typography>
                    )}
                    {type.value === 'PERSONAL_MEMORIES' && (
                      <Typography
                        sx={{
                          fontSize: 10,
                          mt: '-2px',
                          color: selectedSchoolMemoryType === type.value ? '#2d63c6' : '#5e5e5e',
                          fontWeight: selectedSchoolMemoryType === type.value ? 500 : 400,
                        }}
                      >
                        {schoolMemories.personalMemories.count}{' '}
                        {pluralHelper(schoolMemories.personalMemories.count, 'memory', 'memories')}{' '}
                        added
                      </Typography>
                    )}
                  </Box>
                </Stack>
              ))}
              <Button
                onClick={() => handleToggleSamplePagesDialog(true)}
                sx={{
                  minWidth: 'max-content'
                }}
              >
                View Sample Pages
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            pt: { xs: '130px', sm: '100px' },
            pl: { xs: '16px', sm: '8px' },
            pr: { xs: '16px', sm: '30px' },
            width: { xs: '100vw', sm: 'auto' },
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {selectedSchoolMemoryType === 'SCHOOL_PHOTOS' && (
            <SchoolPhotos
              handleUpgradePremiumDialog={handleUpgradePremiumDialog}
              handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
            />
          )}
          {selectedSchoolMemoryType === 'PERSONAL_NOTES' && (
            <PersonalNotes
              handleUpgradePremiumDialog={handleUpgradePremiumDialog}
              handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
            />
          )}
          {selectedSchoolMemoryType === 'PERSONAL_MEMORIES' && (
            <PersonalMemories
              handleUpgradePremiumDialog={handleUpgradePremiumDialog}
              handleToggleSamplePagesDialog={handleToggleSamplePagesDialog}
            />
          )}
        </Box>

        {(schoolMemories.lastPurchaseDate && new Date() < new Date(schoolMemories.lastPurchaseDate)) && (
          <UpgradePremiumBox handleUpgradePremiumDialog={handleUpgradePremiumDialog} />
        )}

        {showUpgradePremiumDialog && (
          <UpgradePremiumDialog
            open={showUpgradePremiumDialog}
            handleClose={() => handleUpgradePremiumDialog(false)}
          />
        )}
      </Box>
    </>
  );
};

export default ParentAccountSchoolMemories;
