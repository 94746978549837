import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Parent from '../../assets/parent.webp';
import SchoolStaff from '../../assets/schoolStaff.webp';
import Restricted from '../../assets/restricted.jpg';

import AuthNavBar from '../../components/global/AuthNavBar';

import RoleTypes from '../../constants/Roles';
import { fetchParentPages, fetchSchoolPages } from '../../routes/lazyComponents';

const ChooseAccount = () => {
  const navigate = useNavigate();
  const { member } = useSelector((state) => state.member);
  const [isRestricted, setIsRestricted] = useState(false);

  // Preload required pages for school and parents
  useEffect(() => {
    fetchSchoolPages();
    fetchParentPages();
  }, []);

  const handleParentRedirect = () => {
    navigate('/parent/choose-activity');

    // if (member.associations === undefined) {
    //   navigate('/parent/memory-book');
    //   return;
    // }
    // const isAnyParentAssociation = Object.keys(member.associations).some((key, i) => {
    //   return member.associations[key].mRole === RoleTypes.PARENT;
    // });
    // if (isAnyParentAssociation) {
    //   navigate('/parent/photos');
    // } else {
    //   navigate('/parent/memory-book');
    // }
  };

  const handleSchoolRedirect = () => {
    // setShowSchoolMaintanence(true);
    const isAnyAdminAssociation = Object.keys(member?.associations || {}).some((key, i) => {
      return [RoleTypes.OWNER, RoleTypes.DIRECTOR, RoleTypes.ADMIN].includes(
        member.associations[key].mRole,
      );
    });

    const isAnyTeacherAssociation = Object.keys(member?.associations || {}).some((key, i) => {
      return [RoleTypes.TEACHER].includes(member.associations[key].mRole);
    });

    if (isAnyAdminAssociation) {
      navigate('/school/home');
    } else if (isAnyTeacherAssociation) {
      setIsRestricted(true);
    } else {
      navigate('/register-school');
    }
  };

  return (
    <>
      <AuthNavBar showSignup={false} showLogin={false} />
      {isRestricted ? (
        <Box
          sx={{
            textAlign: 'center',
            margin: 'auto',
            width: '80%',
          }}
        >
          <img src={Restricted} alt='Forbidden' style={{ height: '360px' }} />
          <Typography
            align='center'
            variant='h5'
            fontWeight={400}
            sx={{ color: 'primary.lightText', mt: '0px' }}
          >
            You are not authorized to access school account.
          </Typography>
          <Typography
            align='center'
            variant='subtitle1'
            fontWeight={400}
            sx={{ color: 'primary.lightText', mt: '10px' }}
          >
            Please contact your school director
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ mt: { xs: '10px', sm: '80px' } }}
          display={'flex'}
          justifyContent='center'
          gap='30px'
          flexWrap={'wrap'}
        >
          <Box
            boxShadow={1}
            sx={{
              width: '300px',
              px: '20px',
              py: '12px',
              cursor: 'pointer',
              border: 'solid 1px rgba(0,0,0,0.09)',
              textAlign: 'center',
            }}
            onClick={handleParentRedirect}
          >
            <img style={{ width: '200px', aspectRatio: '1/1' }} src={Parent} alt='parent' />
            <Typography align='center' fontWeight={700} sx={{ mt: '20px', fontSize: '24px' }}>
              Parent
            </Typography>

            <Typography
              variant='body2'
              component={'div'}
              align='center'
              fontWeight={400}
              sx={{ color: 'primary.lightText', mt: '8px' }}
            >
              Buy Personalized Memory book.
            </Typography>
            <Typography
              variant='body2'
              align='center'
              fontWeight={400}
              sx={{ color: 'primary.lightText' }}
            >
              Add personal memories. Track your order.
            </Typography>
          </Box>
          <Box
            boxShadow={1}
            sx={{
              width: '300px',
              px: '20px',
              py: '12px',
              cursor: 'pointer',
              border: 'solid 1px rgba(0,0,0,0.09)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleSchoolRedirect}
          >
            <img
              style={{ width: '200px', aspectRatio: '1/1' }}
              src={SchoolStaff}
              alt='school-staff'
            />
            <Typography align='center' fontWeight={700} sx={{ mt: '20px', fontSize: '24px' }}>
              School Staff or Volunteer
            </Typography>

            <Typography
              variant='body2'
              component={'div'}
              align='center'
              fontWeight={400}
              sx={{ color: 'primary.lightText', mt: '8px' }}
            >
              Sign up your school.
            </Typography>
            <Typography
              variant='body2'
              align='center'
              fontWeight={400}
              sx={{ color: 'primary.lightText' }}
            >
              Manage Roster. Track your order
            </Typography>
          </Box>
        </Box>
      )}
      {/* </Box> */}
    </>
  );
};

export default ChooseAccount;
