export const drawerWidth = 220;
export const smallDrawerWidth = 180;

export const constants = Object.freeze({
  ADDITIONAL_PHOTO_PRODUCTID: "05ed6858-1a13-4433-9cb0-0c464d703fff",
  UPGARDE_PREMIUM_PRODUCTID: "f75d0dc9-76ab-4972-8f8d-41e885c8db89"
});

export const accountContainerMargin = {
  mx: { xs: '20px', sm: 4 },
};

export const PhotoEditorPanelTypes = Object.freeze({
  crop: 1,
  adjust: 2,
  brush: 3,
  frames: 4,
  overlays: 5,
});

export const ErrorMessages = Object.freeze({
  invalidImageType: 'Unsupported file type!! (supported types: png, jpeg, jpg)',
  invalidImageRes: (length = 1) => `Above uploaded ${length > 1 ? 'images are' : 'image is'
    } not of right resolution.
  Image must have width or height more than 768px`,
});

export const getGender = Object.freeze({
  0: 'Girl',
  1: 'Boy',
});

export const parentAccountV2Schools = Object.freeze(['1LQS', '1KWH', '1lNj', '0Bfs', 'DrsK', '0lf9', '1nj7', '0jjb', '0DWM', '0Utv', '0K9q', '0J6x', '0Iv6', '1cGf', '0Lfr', '0KXC', '1MUM', '1Qet', '09cP', '1Xqq', '0tVY', '0AfJ', '11II', '0Mtq', '0zXO']);

