import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const getNestedValue = (obj, keys) => {
    return keys.reduce((acc, key) => acc?.[key], obj) ?? undefined;
}

const getListWithImageUrls = async (dataArray, urlKey, bucketName, imageUrl) => {
    let arrayPromise = dataArray.map((data) => {
        const urlValue = getNestedValue(data, urlKey);
        if (urlValue) {
            const firebaseApp = getApp();
            const storage = getStorage(
                firebaseApp,
                `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-${bucketName}`
            );
            return getDownloadURL(ref(storage, urlValue)).catch((error) => {
                console.log(`Error retrieving photo URL: ${error}`);
                return null;
            });
        } else {
            return null;
        }
    });
    let list = [];
    let data = await Promise.all(arrayPromise);
    data.forEach((url, index) => {
        list.push({ ...dataArray[index], ...(url && { [imageUrl]: url }) });
    });

    return list;
}

export { getNestedValue, getListWithImageUrls };