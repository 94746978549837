import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: {},
    type: '',
  },
  reducers: {
    updateAuthState: (state, action) => {
      state.auth = action.payload;
    },
    updateType: (state, action) => {
      state.type = action.payload;
    },
  },
});
export const auth = (state) => state.auth;
export const { updateAuthState, updateType } = authSlice.actions;

export default authSlice.reducer;
