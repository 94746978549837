import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../reducers/authReducer';
import verifyEmailReducer from '../reducers/verifyEmailReducer';
import memberReducer from '../reducers/memberReducer';
import schoolReducer from '../reducers/schoolReducer';
import schoolCodeReducers from '../reducers/schoolCodeReducers';
import ProductReducer from '../reducers/ProductReducer';
import cartReducer from '../reducers/cartReducer';
import myAccountReducer from '../reducers/myAccountReducer';
import schoolDataReducer from '../reducers/schoolDataReducer';
import memoryBookStepReducer from '../reducers/memoryBookSteps.slice';
import snackbarReducer from '../reducers/snackbarReducer';
import parentReducer from '../reducers/parentReducer';

const appReducer = combineReducers({
  auth: authReducer,
  verifyemail: verifyEmailReducer,
  member: memberReducer,
  schoolCode: schoolCodeReducers,
  products: ProductReducer,
  cart: cartReducer,
  myAccount: myAccountReducer,
  school: schoolReducer,
  memoryBookSteps: memoryBookStepReducer,
  schoolData: schoolDataReducer,
  snackbar: snackbarReducer,
  parent: parentReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'myAccountSlice/resetApisState') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
