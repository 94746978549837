import React from 'react';
import { Box } from '@mui/material';
import { DeleteOutline, ModeEdit } from '@mui/icons-material';
import { MemoriesData } from '../../../../../../data/text';
import { deleteMemoriesNotesThunk } from '../../../../../../reducers/parentReducer';
import { useDispatch } from 'react-redux';

const PersonalMemoryItem = ({ memory, handleAddEditMyOwnMemoryDialogToggle, handlePersonalizeMemoryDialogToggle }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Box sx={{
                gridArea: 'span 1 / span 1 / span 1 / span 1',
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '90px',
                p: '20px 12px',
                gap: '20px',
                aspectRatio: '1 / 1',
                backgroundColor: 'rgb(245, 245, 247)'
            }}>
                <Box
                    component={'img'}
                    src={memory.icon}
                    alt='memory'
                    sx={{
                        width: '40px',
                        height: '40px',
                        maxWidth: '100%',
                        display: 'inline-block'
                    }}
                />
                <Box sx={{ flex: '1 1 0%' }}>
                    <Box sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        textAlign: 'left',
                        color: 'rgb(51, 51, 51)',
                        lineHeight: 1
                    }}>
                        {memory.title}
                    </Box>
                    <Box sx={{
                        mt: '6px',
                        fontSize: '12px',
                        textAlign: 'left',
                        color: 'rgb(51, 51, 51)',
                        lineHeight: 1,
                        fontWeight: 500
                    }}>
                        {memory.memories.map(m => m.title).join(', ')}
                    </Box>
                </Box>
                <ModeEdit
                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => {
                        if (memory.memoryType === 'CUSTOM') {
                            handleAddEditMyOwnMemoryDialogToggle(true, 'EDIT', memory);
                        } else {
                            const systemMemory = MemoriesData.find((p) => p.age === 1 && p.pKey === memory.memoryKey);
                            handlePersonalizeMemoryDialogToggle(true, 'EDIT', { ...memory, options: systemMemory.options });
                        }
                    }}
                />
                <DeleteOutline onClick={() => dispatch(deleteMemoriesNotesThunk({ memoryId: memory.id, type: 'MEMORY', isIncluded: memory?.isIncluded || false }))} sx={{ fontSize: '20px', cursor: 'pointer' }} />
            </Box>
        </>
    )
}

export default PersonalMemoryItem;