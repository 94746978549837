import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { Axios } from '../../../api/axios';

export const getAlbumsList = createAsyncThunk('fetch/school-album-list', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const schoolId = state.schoolData?.schoolHome?.schoolId;
    const response = await Axios.get(`/albums/school?schoolId=${schoolId}`);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAlbumPhotosList = createAsyncThunk(
  'fetch/school-album-photos-list',
  async (data, thunkAPI) => {
    const { albumId, startDate, endDate } = data;
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const response = await Axios.post('/photos/get-by-album', {
        schoolId,
        albumId,
        startDate,
        endDate,
      });

      // Load url for all photos
      let photoPromises = response.photos.map((photo) => {
        if (photo.url) {
          const firebaseApp = getApp();
          const storage = getStorage(
            firebaseApp,
            `gs://${process.env.REACT_APP_FIREBASE_PROJECT_ID}-deziser-sotohp`,
          );
          return getDownloadURL(ref(storage, photo.url)).catch((error) => {
            console.log(`Error retrieving photo URL: ${error}`);
            return null;
          });
        } else {
          return null;
        }
      });

      let photoUrlResult = await Promise.all(photoPromises);
      photoUrlResult.forEach((url, index) => {
        response.photos[index] = { ...response.photos[index], url: url };
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const likeAlbumsSchoolPhoto = createAsyncThunk(
  'post/like-school-album-photo',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const likedPhotos = data.photos;
      const response = await Axios.post('/photos-like/school', { schoolId, data: likedPhotos });
      return { ...response, likedPhotos };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteAlbumsSchoolPhoto = createAsyncThunk(
  'post/delete-school-album-photo',
  async (data, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const deletedPhotos = data.photos;
      const response = await Axios.delete('/photos/school', {
        data: { schoolId, data: deletedPhotos },
      });
      return { ...response, deletedPhotos };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
