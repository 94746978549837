import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { v4 } from 'uuid';
import { callPostAPI, callPutAPI } from '../../../helpers/apiCall';
import { uploadNotesProfile } from './utils';

export const getNotesThunk = createAsyncThunk('getNotes/fetch', async (d, { getState }) => {
  let state = getState();
  console.log(state);
  let childMemberId = state.member?.activeChild?.memberId;
  let memberId = state.member?.member?.id;
  console.log(childMemberId);
  console.log(memberId);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let reqObj = {
    memberId: childMemberId,
    token: token,
  };
  let result = await callPostAPI(reqObj, '/get-notes');
  console.log('result', result);
  let list = [];
  for (const notes of result.data) {
    list.push(notes);
  }
  console.log('list', list);
  return { data: list };
});

export const deleteNotesThunk = createAsyncThunk('deleteNotes/fetch', async (d, { getState }) => {
  let state = getState();
  let memberId = state.member?.activeChild?.memberId;
  let notesId = d.notesId;
  let reqObj = {
    memberId: memberId,
    notesId: notesId,
  };
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPostAPI({ ...reqObj, token }, '/delete-notes');
  console.log(result);
  return { notesId };
});

export const saveNotesThunk = createAsyncThunk('saveNotes/fetch', async (d, { getState }) => {
  let state = getState();
  let childMemberId = state.member?.activeChild?.memberId;
  let photoId = v4();

  let reqObj = {
    data: { ...d, info: { ...d.info, profile: `notes/${photoId}.jpg` } },
    memberId: childMemberId,
  };
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPostAPI({ ...reqObj, token }, '/notes');
  await uploadNotesProfile(d.info.profile, photoId);
  console.log(result);
  // return { photoId };
  return result;
});

export const editNotesThunk = createAsyncThunk('editNotes/fetch', async (d, { getState }) => {
  let state = getState();
  let childMemberId = state.member?.activeChild?.memberId;
  let photoId = v4();

  let reqObj = {
    toUpdate: {
      ...d,
      info: {
        ...d.info,
        profile: d.info.profile.isUploaded ? `notes/${photoId}.jpg` : d.info.profile.url,
      },
    },
    notesId: d.info.id,
    memberId: childMemberId,
  };
  console.log('reqObj', reqObj);
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let result = await callPutAPI({ ...reqObj, token }, '/notes');
  if (d.info.profile.isUploaded) {
    await uploadNotesProfile(d.info.profile, photoId);
  }
  console.log(result);
  // return { photoId };
  return result;
});

export const getMyInfoThunk = createAsyncThunk('get-myinfo/fetch', async (d, { getState }) => {
  let state = getState();
  let childRosterId = state.member?.activeChild?.rosterId;
  let schoolId = state.member?.activeChild?.schoolId;
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  let reqObj = {
    rosterId: childRosterId,
    // "rosterId": childRosterId,
    schoolId: schoolId,
    // schoolId,
    token,
  };
  let result = await callPostAPI(reqObj, '/get-roster-info');
  return { data: result.data };
});

export const updateMyInfoThunk = createAsyncThunk(
  'update-myinfo/fetch',
  async (data, { getState, dispatch }) => {
    let state = getState();
    let childRosterId = state.member?.activeChild?.rosterId;
    let schoolId = state.member?.activeChild?.schoolId;
    let auth = getAuth();
    let token = await auth.currentUser.getIdToken();
    let reqObj = {
      toUpdate: {
        info: {
          firstName: data.firstname,
          gender: data.gender,
          lastName: data.lastname,
          middleName: data.middlename,
          dob: Timestamp.fromDate(new Date(data.dob)),
        },
      },
      schoolId: schoolId,
      // schoolId,
      // rosterId: childRosterId,
      rosterId: childRosterId,
      token,
    };
    let result = await callPutAPI(reqObj, '/roster');
    if (result.message === 'success') {
      dispatch(getMyInfoThunk());
    }
    return result;
  },
);
