import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  InputBase,
  Typography,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  Collapse,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { validateEmail, hasLowerCase, hasUpperCase, hasNumber } from '../../utils/functions';

// icons
import Spinner from '../../components/Spinner';
import NextArrowButton from '../../components/global/NextArrowButton';

import { signUpThunk } from '../../reducers/memberReducer';

export default function SignUp({ setIsSignIn }) {
  const dispatch = useDispatch();
  const params = useParams();
  let navigate = useNavigate();
  const memberState = useSelector((state) => state.member);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    checkBox: false,
  });
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  useEffect(() => {
    if (memberState.error.status) {
      setOpen(true);
      setMessage(memberState.error.message);
      return;
    }
    if (memberState.member.id) {
      navigate(memberState.navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberState]);

  const isCompleted = () => {
    let status = true;
    if (state.firstName.length < 2) {
      status = false;
    }
    if (!validateEmail(state.email)) {
      status = false;
    }
    if (
      !(
        hasUpperCase(state.password) &&
        hasLowerCase(state.password) &&
        hasNumber(state.password) &&
        state.password.length >= 8
      )
    ) {
      status = false;
    }

    return status;
  };

  const handleSubmit = () => {
    dispatch(signUpThunk({ ...state, type: params.type }));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleInput = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <>
      <Spinner open={memberState.isLoading} />

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Box display={'flex'} justifyContent='center' flexDirection={'column'} sx={{ mt: '20px' }}>
        <Box
          component={'form'}
          sx={{ width: { xs: '100%', md: '100%' }, mt: '20px' }}
          display={'flex'}
          justifyContent='center'
          flexDirection={'column'}
        >
          <Typography textAlign={'center'} variant='h4' mb={4} fontWeight={400}>
            Create your account
          </Typography>
          {/* Inputs */}
          <Box sx={{ width: { xs: '100%', md: '100%' } }} display={'flex'} justifyContent='center'>
            <InputBase
              variant='leftborder'
              placeholder='First name'
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
              autoFocus
              field='firstName'
              value={state.firstName}
              onChange={(e) => {
                handleInput('firstName', e.target.value);
              }}
            ></InputBase>
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: '100%' }, mt: '10px' }}
            display={'flex'}
            justifyContent='center'
          >
            <InputBase
              variant='leftborder'
              placeholder='Last name'
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
              field='lastName'
              value={state.lastName}
              onChange={(e) => {
                handleInput('lastName', e.target.value);
              }}
            ></InputBase>
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: '100%' }, mt: '10px' }}
            display={'flex'}
            justifyContent='center'
          >
            <InputBase
              variant='leftborder'
              placeholder='Email'
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
              field='email'
              value={state.email}
              onChange={(e) => {
                handleInput('email', e.target.value);
              }}
            ></InputBase>
          </Box>
          <Box
            sx={{
              width: '100%',
              mt: '10px',
            }}
            display={'flex'}
            flexDirection='column'
            alignItems='center'
            gap='10px'
            justifyContent='center'
          >
            <InputBase
              variant='leftborder'
              placeholder='Password'
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
              autoComplete='off'
              type={isVisible ? 'text' : 'password'}
              field='password'
              value={state.password}
              onChange={(e) => handleInput('password', e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => setIsVisible(!isVisible)}
                  >
                    {isVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Collapse
              in={isPasswordFocused}
              sx={{
                width: '100%',
                maxWidth: '360px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.white',
                  borderRadius: '5px',
                }}
              >
                <Box sx={{ p: '10px' }}>
                  <Box sx={{ mt: '10px' }}>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {state.password.length >= 8 ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      at least 8 characters in length
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasLowerCase(state.password) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      lower case letters (ie. a-z)
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasUpperCase(state.password) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      upper case letters (ie. A-Z)
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {hasNumber(state.password) ? (
                        <CheckCircleIcon fontSize='16px' color='success' />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize='16px' />
                      )}{' '}
                      numbers (ie. 0-9)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </Box>
          {/* Button, Notes */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '360px',
              mt: '20px',
              mx: 'auto',
            }}
            display={'flex'}
            justifyContent='center'
            flexDirection={'column'}
            alignItems='center'
          >
            <NextArrowButton
              text={'Create Account'}
              disabled={!isCompleted()}
              handleClick={handleSubmit}
            />
            <Box
              sx={{
                width: { xs: '100%', md: '100%' },
                my: '30px',
                maxWidth: '300px',
              }}
              display={'flex'}
              justifyContent='center'
              flexDirection={'column'}
              alignItems='center'
              gap={2}
            >
              <Typography variant={'caption'} align='center' color={'#636363'}>
                By creating an account, you agree to MyVity's{' '}
                <Box
                  component='a'
                  href='/privacy-policy.pdf'
                  target={'_blank'}
                  sx={{
                    ':link, :hover, :active, :visited': {
                      color: '#0065CC',
                    },
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Privacy Policy
                </Box>{' '}
                <span>and </span>
                <Box
                  component='a'
                  href='/terms.pdf'
                  target={'_blank'}
                  sx={{
                    ':link, :hover, :active, :visited': {
                      color: '#0065CC',
                    },
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  Terms of use
                </Box>{' '}
              </Typography>
              <Typography variant={'caption'} align='center' color={'#636363'}>
                Already have an account?{' '}
                <Box
                  component={'span'}
                  onClick={() => setIsSignIn(true)}
                  sx={{
                    color: '#0065CC',
                    cursor: 'pointer',
                  }}
                >
                  Sign In
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
