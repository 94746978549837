import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import PrivateRoute from './PrivateRoute';
import LazyLoadPage from './components/LazyLoadPage';
import SignIn from './pages/auth';
import UpdatePassword from './pages/forgotPassword/UpdatePassword';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ChooseAccount from './pages/auth/ChooseAccount';
import AccountActivation from './pages/auth/AccountActivation';
import Maintanence from './pages/maintanence/Maintanence';
import ParentAccount from './pages/parent-account/ParentAccount';
import { useSnackbar } from './context/SnackbarContext';
import { resetSnackbar } from './reducers/snackbarReducer';

// Lazy loads
import { OrderSummary, RegisterSchool, ParentPages, SchoolPages } from './routes/lazyComponents';
import ChooseParentActivity from './pages/auth/ChooseParentAction';

const RouteProvider = () => {
  const { message, variant, snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (snackbar.show) {
      handleSnackbarOpen({ open: true, message: snackbar.message, variant: snackbar.variant });
      dispatch(resetSnackbar());
    }
  }, [snackbar, dispatch, handleSnackbarOpen]);

  return (
    <>
      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={variant} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}

      <Routes>
        <Route path='/' element={<SignIn signin={false} />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/update-password' element={<UpdatePassword />} />
        <Route path='/signup/:type' element={<SignIn signin={false} />} />
        <Route path='/signin' element={<SignIn signin={true} />} />
        <Route path='/account-activation' element={<AccountActivation />} />

        {/* Private routes */}
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/choose-account' element={<ChooseAccount />} />
          <Route path='/parent/choose-activity' element={<ChooseParentActivity />} />
          <Route path='/order-summary' element={<LazyLoadPage component={<OrderSummary />} />} />
          {/* school routes */}
          <Route
            path='/register-school'
            element={<LazyLoadPage component={<RegisterSchool />} />}
          />
          <Route path='/school/*' element={<LazyLoadPage component={<SchoolPages />} />} />
          {/* parent routes */}
          <Route path='/parent/*' element={<LazyLoadPage component={<ParentPages />} />} />
          <Route path='/parent-account/*' element={<ParentAccount />} />
        </Route>

        {/* Not found route */}
        <Route path='*' element={<Maintanence />} />
      </Routes>
    </>
  );
};

export default RouteProvider;
