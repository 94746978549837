import { green, grey, lightBlue, red, yellow } from '@mui/material/colors';
import { createBreakpoints } from '@mui/system';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  breakpoints: {
    values: {
      ...breakpoints.values,
      sm: 500,
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
    h3: {
      fontSize: 42,
      lineHeight: 1.26,
      fontWeight: 700,
      color: '#0C172A',
      [breakpoints.up('sm')]: {
        fontSize: 48,
      },
      [breakpoints.down('sm')]: {
        fontSize: 28,
      },
    },
    h4: {
      fontWeight: 700,
      color: '#0C172A',
    },
    h5: {
      fontWeight: 500,
      color: '#0C172A',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // For main html element
        html: {
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '6px',
            height: '3px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(25, 118, 210, 0.6)',
            borderRadius: '14px',
          },
        },
        // For all MUI elements
        '&::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          width: '6px',
          height: '3px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(25, 118, 210, 0.6)',
          borderRadius: '14px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'primary.main',
          '&.Mui-checked': {
            color: 'primary.main',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'primary.main',
          '&.Mui-checked': {
            color: 'primary.main',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'black' },
          style: {
            '& .MuiSvgIcon-root': {
              color: 'black',
            },
            '& .MuiTouchRipple-child': {
              backgroundColor: 'black',
            },
          },
        },
      ],
    },
    MuiSelect: {
      '& .MuiInputBase-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'primary.black',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '10px 20px',
          '&.Mui-disabled': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            cursor: 'not-allowed',
          },
        },
        outlined: {
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          fontSize: '16px',
          borderColor: '#0065CC',
          background: 'white',
          color: '#0065CC',
          borderRadius: '10px',
          width: '100%',
          maxWidth: '360px',
          ':hover': {
            backgroundColor: 'rgba(0, 101, 204, 0.08)',
          },
        },
        contained: {
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          fontSize: '16px',
          border: '1px solid #0065CC',
          borderRadius: '10px',
          width: '100%',
          maxWidth: '360px',
          background: '#0065CC',
          color: 'white',
          '&:hover': {
            border: '1px solid #0065CC',
            transition: '0.2s',
            background: '#014991',
            borderColor: '#014991',
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'contained-black',
          },
          style: {
            border: '1px solid black',
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              border: '1px solid black',
              transition: '0.2s',
              color: '#ffffffdb',
              backgroundColor: '#000000db',
              borderColor: '#00000011',
            },
            ':disabled': {
              backgroundColor: grey[400],
              borderColor: grey[400],
              color: grey[700],
            },
          },
        },
        {
          props: {
            variant: 'outlined-black',
          },
          style: {
            border: '1px solid black',
            backgroundColor: 'transparent',
            color: 'black',
            '&:hover': {
              border: '1px solid black',
              transition: '0.2s',
              backgroundColor: '#00000011',
              borderColor: 'black',
            },
            ':disabled': {
              backgroundColor: grey[400],
              borderColor: grey[400],
              color: grey[700],
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: '#000',
            opacity: 0.6,
          },
        },
      },
      variants: [
        {
          // left border on focus
          props: { variant: 'leftborder' },
          style: {
            borderRadius: '4px',
            border: '1px solid #DDD',
            padding: '10px 20px 10px 25px',
            '&.Mui-focused': {
              borderColor: '#ADC6DF',
              borderLeft: '5px solid #0065CC',
              padding: '10px 20px 10px 21px',
            },
          },
        },
      ],
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: `1px solid primary.main`,
          backgroundColor: 'rgba(247, 205, 195, 0.2)',
          color: 'primary.main',
        },
        '& .MuiSvgIcon-root': {
          color: 'primary.main',
        },
      },
    },
  },
  palette: {
    background: {
      // default: "rgba(237,241,247,0.72)",
      sidebar: '#F2F7FC',
      content: '#FCFCFC;',
    },
    primary: {
      main: '#0065CC',
      // dark: "#313b7d",
      light: '#F2F7FC',
      // black: "rgba(29,27,44,1)",
      black: 'rgba(19,19,21,1)',
      lightText: '#333333',
      white: 'rgba(246,249,254,1)',
      background: 'rgba(246,249,254,1)',
      red: 'rgba(252,45,45,1)',
      blue: '#1976d2',
    },
    custom: {
      seashell: '#FEF6ED',
      coral: '#fd8550',
      aliceBlue: '#F2F6FF',
      blueberry: '#5089FD',
      white: '#FFFFFF',
      cultured: '#F4F4F4',
      lotion: '#FCFCFC',
      cornsilk: '#FEF7E0',
      darkLiver: '#4e4e4e',
      sonicSilver: '#7a7a7a',
      eerieBlack: '#1e1e1e',
      charlestonGreen: '#2A2929',
      paleRobinEggBlue: '#97E0C7',
    },
    secondary: { main: 'rgba(192,85,81,1)' },
    error: {
      main: red[500],
      light: red[400],
    },
    success: {
      main: green[500],
      light: green[400],
    },
    warning: {
      main: yellow[500],
      light: yellow[400],
    },
    info: {
      main: lightBlue[500],
      light: '#F2FBFB',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    tonalOffset: 0.2,
  },
});

export default responsiveFontSizes(theme);
