import React, { useState } from 'react';
import { Box, ImageListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { likePhoto } from '../../../../../reducers/parentReducer';
import { CheckCircle } from '@mui/icons-material';

const SchoolPhotoItem = ({ photo, handleUpgradePremiumDialog }) => {
  const dispatch = useDispatch();
  const schoolMemories = useSelector((state) => state.parent.schoolMemories);

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <>
      <ImageListItem>
        <Box
          onClick={() => {
            if (schoolMemories.totalRemainingMemoriesCount > 0 || photo.isLiked) {
              dispatch(likePhoto(photo.id));
            } else {
              handleUpgradePremiumDialog(true);
            }
          }}
          sx={{ cursor: 'pointer' }}
        >
          <img
            srcSet={photo.photoUrl}
            src={photo.photoUrl}
            loading='lazy'
            alt='School Photos'
            onLoad={() => setIsImageLoaded(true)}
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
          {isImageLoaded && (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 20,
                  right: 12,
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  backgroundColor: photo.isLiked ? '#fff' : 'rgba(0,0,0,0.27)',
                  zIndex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {photo.isLiked && (
                  <CheckCircle
                    sx={{
                      width: '34px',
                      height: '34px',
                      color: '#3274b5',
                    }}
                  />
                )}
              </Box>
              {photo.isLiked && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,0.27)',
                  }}
                ></Box>
              )}
            </>
          )}
        </Box>
      </ImageListItem>
    </>
  );
};

export default SchoolPhotoItem;
