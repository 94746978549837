import { createSlice } from '@reduxjs/toolkit';

export const schoolRegisterSlice = createSlice({
  name: 'school',
  initialState: {
    name: '',
    role: '',
    gender: '',
    address: {
      number: '',
      street: '',
      city: 'CA',
      country: 'USA',
      state: '',
      zipCode: '',
    },
    deliveryDate: '',
    studentRoster: [],
    staffRoster: [],
    plan: {},
  },
  reducers: {
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updateAddress: (state, action) => {
      let { type, value } = action.payload;
      if (type === 'suite') {
        state.address.number = value;
      }
      if (type === 'street') {
        state.address.street = value;
      }
      if (type === 'city') {
        state.address.city = value;
      }
      if (type === 'country') {
        state.address.country = value;
      }
      if (type === 'state') {
        state.address.state = value;
      }
      if (type === 'zipCode') {
        state.address.zipCode = value;
      }
    },
    updateDate: (state, action) => {
      state.deliveryDate = action.payload;
    },
    updateStaffRoster: (state, action) => {
      state.staffRoster = action.payload.staff;
    },
    updateStudentRoster: (state, action) => {
      state.studentRoster = action.payload.student;
    },
    updatePlan: (state, action) => {
      state.plan = action.payload;
    },
  },
});
export const school = (state) => state.school;
export const {
  updateName,
  updateRole,
  updateGender,
  updateAddress,
  updateDate,
  updateStudentRoster,
  updateStaffRoster,
  updatePlan,
} = schoolRegisterSlice.actions;

export default schoolRegisterSlice.reducer;
