import { createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../api/axios';

export const getSchoolList = createAsyncThunk('fetch/school-list', async (_, thunkAPI) => {
  try {
    const response = await Axios.get('/school/list-by-associations');
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
