import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../api/axios';
import { getAuth } from 'firebase/auth';
import RoleTypes from '../constants/Roles';

export const getSchoolFromSchoolCodeThunk = createAsyncThunk(
  'school/code',
  async ({ schoolCode }) => {
    let auth = getAuth();
    let token = await auth.currentUser.getIdToken();
    try {
      let getSchoolInfo = await Axios.post(
        '/school-code-info',
        {
          schoolCode: schoolCode,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      return getSchoolInfo;
    } catch (error) {
      return { error: true, message: error.message || '' };
    }
  },
);
export const getRosterListThunk = createAsyncThunk('roster/list', async (d) => {
  let auth = getAuth();
  let token = await auth.currentUser.getIdToken();
  try {
    let getRosterList = await Axios.post(
      '/roster-name',
      {
        schoolId: d.schoolId,
        rosterName: d.rosterName,
        role: RoleTypes.STUDENT,
      },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    console.log(getRosterList);
    return getRosterList;
  } catch (error) {
    return { error: true, message: error.message || '' };
  }
});

export const schoolCodeSlice = createSlice({
  name: 'schoolCode',
  initialState: {
    schoolCode: '',
    rosterList: [],
    products: [],
    school: {},
    freePhotos: null,
    navigate: '',
    isLoading: false,
    error: {
      status: false,
      message: '',
    },
    isSuccess: false,
  },
  reducers: {
    updateSchoolCode: (state, action) => {
      state.schoolCode = action.payload;
    },
    updateProducts: (state, action) => {
      state.products = action.payload;
    },
    resetSuccess: (state, action) => {
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolFromSchoolCodeThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
        state.isSuccess = false;
      })
      .addCase(getSchoolFromSchoolCodeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.school?.id) {
          state.error.status = false;
          state.isSuccess = true;
          state.navigate = '/select-roster';
          state.school = action.payload.school;
          state.freePhotos = action.payload.school.freePhotos;
          state.rosterList = action.payload.rosterList;
          state.products = action.payload.products;
        } else {
          state.error.status = true;
          state.error.message =
            'Please verify your school code. No school Found for this school code.';
        }
      })
      .addCase(getSchoolFromSchoolCodeThunk.rejected, (state, action) => {
        console.log('error', action);
      })
      .addCase(getRosterListThunk.pending, (state, action) => {
        state.isLoading = true;
        state.error.status = false;
      })
      .addCase(getRosterListThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('action.payload', action.payload);
        if (action.payload.data.length > 0) {
          state.rosterList = action.payload.data;
        }
      })
      .addCase(getRosterListThunk.rejected, (state, action) => {
        console.log('error', action);
      });
  },
});
export const schoolCode = (state) => state.schoolCode.schoolCode;
export const { updateSchoolCode, resetSuccess, updateProducts } = schoolCodeSlice.actions;

export default schoolCodeSlice.reducer;
