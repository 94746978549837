import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Avatar, Box, CssBaseline, Divider, IconButton, Menu, MenuItem, Portal, Snackbar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Edit, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { getProfileUrl } from '../../../helpers/getProfileUrl';
import { useDispatch, useSelector } from 'react-redux';
import { loadModels } from '../../../utils/faceDetection';
import Spinner from '../../../components/Spinner';
import * as faceapi from 'face-api.js';
import { updateProfilePhoto } from '../../../layout/utils';
import { isValidImageFileType } from '../../../utils/image';
import { ErrorMessages } from '../../../utils/constants';
import { setActiveChild } from '../../../reducers/memberReducer';
import { setSelectedChild } from '../../../reducers/parentReducer';

const ParentChildren = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const member = useSelector((state) => state.member);
    const { orderItems } = useSelector((state) => state.parent);

    const [rosters, setRosters] = useState([]);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [faceModelLoaded, setFaceModelLoaded] = useState(false);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSelection = async (index) => {
        handleCloseUserMenu();
        dispatch(setActiveChild(rosters[index]));
        dispatch(setSelectedChild(rosters[index]));
        localStorage.setItem('activeChild', rosters[index].rosterId);
    };

    useEffect(() => {
        loadModels()
            .then((res) => {
                setFaceModelLoaded(true);
            })
            .catch((err) => {
                setFaceModelLoaded(false);
            });
    }, []);

    useEffect(() => {
        let list = [];
        let children = orderItems?.children || [];

        if (children.length > 0) {
            children.forEach((child) => {
                list.push({
                    rosterId: child.rosterId,
                    memberId: child.childMemberId,
                    schoolId: child.school.id,
                    groupId: child.groupId,
                    info: child.info || {
                        firstName: '',
                        lastName: '',
                    },
                    ...child
                });
            });

            list.sort((a, b) => (a.info.firstName > b.info.firstName) - (a.info.firstName < b.info.firstName));
            setRosters(list);

            let activeChildId = localStorage.getItem('activeChild');

            if (!activeChildId) {
                if (list.length > 0) {
                    localStorage.setItem('activeChild', list[0].rosterId);
                    dispatch(setActiveChild(list[0]));
                    dispatch(setSelectedChild(list[0]));
                }
            } else if (orderItems.selectedChild?.rosterId !== activeChildId) {
                let activeChildData = list.find((childData) => childData.rosterId === activeChildId);

                if (activeChildData) {
                    dispatch(setActiveChild(activeChildData));
                    dispatch(setSelectedChild(activeChildData));
                } else {
                    localStorage.setItem('activeChild', list[0]?.rosterId);
                    dispatch(setActiveChild(list[0] || {}));
                    dispatch(setSelectedChild(list[0] || {}));
                }
            }
        }
        // eslint-disable-next-line
    }, [orderItems.children]);

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    px: { xs: '0', sm: '25px' },
                    bgcolor: 'transparent',
                    boxSizing: 'border-box',
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'column' },
                    alignItems: 'center',
                    gap: { xs: 1, sm: 0 }
                }}
            >
                <ProfileImageBox
                    key={orderItems.selectedChild}
                    member={member.member}
                    activeChild={orderItems.selectedChild}
                    faceModelLoaded={faceModelLoaded}
                    width={isSmallScreen ? '42px' : '100px'}
                    height={isSmallScreen ? '42px' : '100px'}
                    profileUrl={getProfileUrl(orderItems.selectedChild?.rosterId)}
                />
                <Stack
                    direction={'row'}
                    onClick={handleOpenUserMenu}
                    sx={{
                        pt: { sm: '8px' },
                        ml: { xs: '4px', sm: 0 },
                        cursor: 'pointer',
                        alignItems: 'center',
                        width: '100%',
                        height: '35px',
                        gap: 1
                    }}
                >
                    {!orderItems.isLoading && (
                        <>
                            <Typography
                                variant='body1'
                                noWrap
                                sx={{
                                    width: '100%',
                                    maxWidth: { xs: '40%', sm: '100%' },
                                    overflow: 'hidden',
                                    textAlign: 'center',
                                    textOverflow: 'ellipsis',
                                    color: 'primary.black',
                                    fontSize: 18,
                                    fontWeight: 500
                                }}
                            >
                                {orderItems.selectedChild?.info?.firstName} {orderItems.selectedChild?.info?.lastName}
                            </Typography>
                            {Boolean(anchorElUser) ? (
                                <ExpandLessOutlined sx={{ fontSize: '18px', color: 'black' }} />
                            ) : (
                                <ExpandMoreOutlined sx={{ fontSize: '18px', color: 'black' }} />
                            )}
                        </>
                    )}
                </Stack>
            </Box>
            <Menu
                sx={{
                    mt: { xs: '35px', sm: '40px' },
                    pb: 0,
                    height: '350px',
                    '& .MuiMenu-paper': {
                        overflow: 'hiden',
                        boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.2)',
                        borderRadius: '10px',
                    },
                }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {/* Active */}
                {rosters.map((roster, index) => roster.rosterId === orderItems.selectedChild.rosterId && (
                    <MenuItem key={index} selected={roster.rosterId === orderItems.selectedChild.rosterId}>
                        <Stack direction={'row'} alignItems='center' gap='12px'>
                            <Avatar
                                sx={{
                                    width: '42px',
                                    height: '42px',
                                    borderRadius: '42px',
                                }}
                                src={getProfileUrl(roster.rosterId)}
                            />
                            <Typography variant='body2' sx={{ textAlign: 'left' }}>
                                {`${roster.info.firstName} ${roster.info.lastName}`}
                            </Typography>
                        </Stack>
                    </MenuItem>
                ))}
                <Divider sx={{ mx: 'auto', width: '80%' }} />
                {/* Other */}
                {rosters.map((roster, index) => roster.rosterId !== orderItems.selectedChild.rosterId && (
                    <MenuItem
                        key={index}
                        selected={roster.rosterId === orderItems.selectedChild.rosterId}
                        onClick={() => handleSelection(index)}
                    >
                        <Stack direction={'row'} sx={{ alignItems: 'center', gap: '12px' }}>
                            <Avatar
                                key={roster.rosterId}
                                src={getProfileUrl(roster.rosterId)}
                                sx={{
                                    width: '42px',
                                    height: '42px',
                                    borderRadius: '42px',
                                }}
                            />
                            <Typography variant='body2' sx={{ textAlign: 'left' }}>
                                {`${roster.info.firstName} ${roster.info.lastName}`}
                            </Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

const ProfileImageBox = ({ member, activeChild, faceModelLoaded, profileUrl, width, height }) => {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: 'info' });
    const [isImageValid, setIsImageValid] = useState(false);
    const [isImageValidating, setIsImageValidating] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const inputRef = useRef();
    const imgRef = useRef();

    // Detect face
    const handleImage = useCallback(async () => {
        setIsImageValid(false);

        if (faceModelLoaded) {
            const detections = await faceapi.detectAllFaces(
                imgRef.current,
                new faceapi.TinyFaceDetectorOptions(),
            );
            const detectionsForSize = faceapi.resizeResults(detections, {
                width: 100,
                height: 100,
            });
            const face = detectionsForSize.map((d) => Object.values(d.box));

            if (face.length > 1) {
                setAlert({ message: 'Upload valid profile image with a one person!!', type: 'error' });
                setIsImageValid(false);
                setImage(null);
                setFile(null);
                return;
            }
            if (face.length === 0) {
                setAlert({ message: 'No face detected!!', type: 'error' });
                setIsImageValid(false);
                setImage(null);
                setFile(null);
                return;
            }
        }

        setIsImageValid(true);
    }, [faceModelLoaded, imgRef]);

    // Set in redux
    const handleSetPhoto = async (event) => {
        event.preventDefault();
        if (inputRef && inputRef.current && event.target.files[0]) {
            const file = event.target.files[0];
            const isValid = await isValidImageFileType(file);
            if (!isValid) {
                setAlert({
                    type: 'error',
                    message: ErrorMessages.invalidImageType,
                });
            } else {
                setFile(file);
            }
        }
        event.target.value = '';
    };

    // Set error
    const handleCloseErrorPopup = () => {
        setAlert({ message: '', type: 'info' });
    };

    // Set image state to validate using face-api
    useEffect(() => {
        setIsImageValidating(true);
        const getImage = () => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                setImage({
                    url: img.src,
                    width: img.width,
                    height: img.height,
                });
            };
        };

        file && getImage();
    }, [file]);

    // Starting detect face if image state changed
    useEffect(() => {
        if (image) {
            handleImage();
            setTimeout(() => {
                setIsImageValidating(false);
            }, 500);
        }
    }, [handleImage, image]);

    // Call api after image is valid
    useEffect(() => {
        if (isImageValid && file) {
            setIsImageUploading(true);
            updateProfilePhoto({
                member,
                activeChild,
                profileData: file,
            })
                .then((res) => {
                    setAlert({ message: 'Photo updated', type: 'success' });
                    setIsImageUploading(false);
                })
                .catch((error) => {
                    setAlert({ message: error.message, type: 'error' });
                    setIsImageUploading(false);
                });
        }
        // eslint-disable-next-line
    }, [isImageValid]);

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                <Portal>
                    <Snackbar
                        open={!!alert.message}
                        autoHideDuration={6000}
                        onClose={() => {
                            handleCloseErrorPopup();
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseErrorPopup} severity={alert.type} sx={{ width: '100%' }}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                </Portal>

                {/* Image */}
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    sx={{
                        position: 'relative',
                        // TODO: uncomment below for update profile
                        // cursor: 'pointer'
                    }}
                >
                    {/* Image upload loader */}
                    {(file && isImageValidating) || (isImageUploading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                top: 0,
                                width: width,
                                height: height,
                            }}
                        >
                            <Spinner open={true} isBackdrop={false} sx={{ position: 'absolute', zIndex: 1 }} />
                        </Box>
                    ))}

                    {/* Show uploaded image */}
                    {image ? (
                        <Box
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '50%',
                                width: width,
                                height: height,
                            }}
                        // TODO: uncomment below for update profile
                        // onClick={(e) => inputRef.current.click()}
                        >
                            <img
                                draggable='false'
                                alt='Profile'
                                ref={imgRef}
                                src={image.url}
                                style={{
                                    width: width,
                                    height: height,
                                    objectFit: 'cover',
                                }}
                            />
                        </Box>
                    ) : (
                        <Avatar
                            sx={{
                                width,
                                height,
                            }}
                            imgProps={{
                                draggable: 'false',
                            }}
                            src={profileUrl}
                            onClick={(e) => {
                                e.stopPropagation();
                                // TODO: uncomment below for update profile
                                // inputRef.current.click();
                            }}
                        />
                    )}

                    <input
                        style={{ display: 'none' }}
                        id='profile-photo'
                        type='file'
                        ref={inputRef}
                        onChange={(e) => handleSetPhoto(e)}
                        accept='image/*'
                    />
                </Box>

                {/* Edit icon */}
                <IconButton
                    sx={{
                        // TODO: remove below for update profile
                        display: 'none',
                        position: 'absolute',
                        bottom: { xs: 0, sm: 0 },
                        right: { xs: -10, sm: 0 },
                        p: '3px',
                        color: 'black',
                        backgroundColor: '#EFEFEF',
                        border: '4px solid white',
                        ':hover': {
                            backgroundColor: '#EFEFEF',
                        },
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        inputRef.current.click();
                    }}
                >
                    <Edit
                        sx={{
                            height: { xs: '10px', sm: '18px' },
                            width: { xs: '10px', sm: '18px' },
                        }}
                    />
                </IconButton>
            </Box>
        </>
    )
}

export default ParentChildren;