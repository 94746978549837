import { createAsyncThunk } from '@reduxjs/toolkit';

import { Axios } from '../../../api/axios';

export const createGDriveFolder = createAsyncThunk(
  'post/create-gdrive-folder',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const schoolId = state.schoolData?.schoolHome?.schoolId;
      const createdBy = state.schoolData?.schoolHome?.school?.createdBy;
      const infoObj = state.schoolData?.schoolHome?.school?.info;
      const schoolCode = state.schoolData?.schoolHome?.schoolCode;
      const schoolEmail = state.schoolData?.schoolHome?.school?.schoolEmail;

      const reqObj = {
        school: {
          id: schoolId,
          createdBy,
          info: infoObj,
          schoolCode,
          schoolEmail,
        },
      };
      const response = await Axios.post('/school/create-drive-folder', reqObj);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
