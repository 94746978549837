import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputBase, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import CustomMemory from '../../../../../../assets/memoryIcons/custom-memory-icon.webp';
import { useDispatch } from 'react-redux';
import { createMemoriesNotesThunk, updateMemoriesNotesThunk } from '../../../../../../reducers/parentReducer';
import { v4 as uuidv4 } from 'uuid';

const AddEditMyOwnMemoryDialog = ({
    open,
    handleClose,
    memory,
    type = 'ADD',
}) => {
    const dispatch = useDispatch();
    const [memoryInput, setMemoryInput] = useState({ title: '', memory: '' });

    const handleMemoryInput = (key, value) => {
        setMemoryInput((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSaveClick = async () => {
        if (type === 'ADD') {
            const data = {
                memories: [
                    {
                        id: uuidv4(),
                        title: memoryInput.memory,
                        memoryType: 'CUSTOM'
                    }
                ],
                icon: CustomMemory,
                memoryType: 'CUSTOM',
                memoryKey: memoryInput.title,
                title: memoryInput.title
            };
            await dispatch(createMemoriesNotesThunk({ data, type: 'MEMORY' }));
        } else {
            const data = {
                memories: [
                    {
                        id: memory.memories[0].id,
                        title: memoryInput.memory,
                        type: 'CUSTOM'
                    }
                ],
                memoryKey: memoryInput.title,
                title: memoryInput.title
            }
            await dispatch(updateMemoriesNotesThunk({ memoryId: memory.id, data, type: 'MEMORY' }));
        }
        handleClose();
    };

    useEffect(() => {
        if (type === 'EDIT') {
            setMemoryInput({
                title: memory.title,
                memory: memory.memories[0].title,
            });
        } else {
            setMemoryInput({ title: '', memory: '' });
        }
    }, [type, memory]);

    return (
        <>
            <Dialog
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby='responsive-dialog-title'
                sx={{
                    '& .MuiPaper-root': {
                        m: { xs: '12px', sm: '32px' },
                    },
                }}
            >
                <DialogTitle component={'div'} sx={{ px: '30px' }}>
                    <Typography variant='h5' fontWeight={500}>
                        Add Memory
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close sx={{ fontSize: '18px' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Box
                        sx={{
                            maxWidth: '100vw',
                            bgcolor: 'white',
                            px: '30px',
                        }}
                    >
                        <Box sx={{ mt: '10px' }}>
                            <Typography sx={{ mb: '5px' }}>Enter a short title for your memory</Typography>
                            <InputBase
                                placeholder='eg: Favorite Superhero'
                                autoFocus
                                margin='dense'
                                variant='leftborder'
                                value={memoryInput.title}
                                onChange={(e) => handleMemoryInput('title', e.target.value)}
                                sx={{
                                    width: '100%',
                                    maxWidth: '450px',
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: '20px' }}>
                            <Typography sx={{ mb: '5px' }}>Enter your memory</Typography>
                            <InputBase
                                placeholder='eg: Spiderman'
                                margin='dense'
                                variant='leftborder'
                                value={memoryInput.memory}
                                onChange={(e) => handleMemoryInput('memory', e.target.value)}
                                sx={{
                                    width: '100%',
                                    maxWidth: '450px',
                                }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        px: '30px',
                        justifyContent: 'space-between',
                        py: '20px',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Button
                        variant='contained'
                        disabled={!memoryInput.title || !memoryInput.memory}
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddEditMyOwnMemoryDialog;